/*==============  Calendar  ==============*/

.calendar
    background-size: cover
    background-repeat: no-repeat
    background-position: 50% 50%
    padding-bottom: 80px
    @include media-lg
        padding-bottom: 148px

.calendar-main
    @include media-lg
        margin-top: 36px

.calendar-season
    // margin-top: 17px
    font-size: 48px
    line-height: 1.2
    font-weight: 700
    color: $color-base
    @include media-lg
        // margin-top: 0
        font-size: 68px
    &+p
        margin-top: 14px
        @include media-lg
            margin-top: 10px

.calendar-month
    margin-top: 17px
    font-size: 24px
    font-weight: 700
    line-height: 1.25
    color: $color-link-dark
    &+p
        margin-top: 5px
    &+span
        margin-top: 5px

.calendar-share
    margin-top: 16px
    font-size: 14px
    @include media-lg
        margin-top: 12px

.calendar-month-list
    margin-left: -20px
    margin-right: -20px
    padding-top: 15px
    padding-bottom: 15px
    @include media-md
        overflow: visible !important
        margin-left: -8px
        margin-right: -8px
        // padding-top: -8px
        // padding-bottom: -8px
    .scroll-content
        padding-left: 0
        padding-right: 0
        @include media-md
            flex-wrap: wrap
            &::before,
            &::after
                content: none

.calendar-month-item
    padding-left: 0 important
    padding-right: 0 important
    @include media-md
        margin-top: 15px
        padding-left: 8px !important
        padding-right: 8px !important
    &+.calendar-month-item
        margin-left: 10px
        @include media-md
            margin-left: 0
    a
        display: flex
        align-items: center
        height: 32px
        padding: 0 16px
        background-color: #fff
        border-radius: 20px
        box-shadow: 0px 1px 15px rgba(43, 148, 148, 0.2)
        line-height: 1.25
        border: 1px solid transparent
        transition: background-color .3s, border-color .3s
        @include media-md
            display: block
            height: 100%
            padding: 10px 20px 20px
            box-shadow: 0px 1px 24px rgba(43, 148, 148, 0.2)
            border-radius: 20px
        &:hover
            text-decoration: none
            background-color: #ECF5FF
            border: 1px solid $color-base
        &._current
            background-color: $color-base
            span
                color: #fff
        .calendar-month
            font-size: 14px
            margin-top: 0
            @include media-md
                font-size: 24px
        span
            display: block
            &+span
                color: #000

.calendar-products
    .complex-products
        @include media-md
            margin-top: -58px
        @include media-lg
            margin-top: -98px
    .catalog-sorting
        margin-top: 17px
        @include media-md
            margin-top: 24px
    .catalog-content
        padding-bottom: 10px
        @include media-sm
            padding-bottom: 60px
