/*=============  Footer  =============*/
.site-footer
    position: relative
    padding-top: 30px
    padding-bottom: 48px
    font-size: 12px
    color: $color-dark-gray
    &::before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 20px
        right: 20px
        height: 1px
        background-color: #DDE0E2
    @include media-xl
        &::before
            content: none
    &._full
        background-color: $color-light-gray

.site-footer-soc
    @include media-xl
        padding-left: 34px

.site-footer-soc-list
    margin-top: 14px
    display: flex
    align-items: center
    a
        display: block
        opacity: .4
        transition: opacity .3s
        height: auto
        width: 26px
        &+a
            margin-left: 28px
            @include media-lg
                margin-left: 23px
        svg
            display: block
        &:hover
            opacity: 1

.site-footer-info
    margin-top: 35px
    @include media-xl
        margin-top: 0

.site-footer-privacy
    margin-top: 10px
    max-width: 645px

.site-footer-dev
    padding-top: 24px
    a
        color: #929292
        text-decoration: none
        svg
            *
                opacity: .5
                transition: opacity .3s
        &:hover
            text-decoration: none
            span
                text-decoration: underline
            svg
                *
                    opacity: 1

.mob-bottom-menu
    position: fixed
    height: 60px // 60px из-за нижнего меню Safari
    left: 0
    right: 0
    bottom: 0
    background-color: $color-light-gray
    text-align: center
    padding: 3px 0 10px // 10px из-за нижнего меню Safari
    z-index: 2
    .container
        padding-left: 13px
        padding-right: 13px
    a
        font-size: 10px
        font-weight: 600
        color:  rgba(2, 100, 127, .8)
        display: inline-block
        text-align: center
        letter-spacing: .02em
        @include media-sm
            font-size: 11px
        span
            display: block
            margin-left: auto
            margin-right: auto
        svg
            *
                transition: fill .3s
        &:hover
            text-decoration: none
        &._active
            svg
                *
                    fill: $color-base

.mob-bottom-menu-wrapper
    @include media-sm
        flex: 0 0 auto
        width: 82%
    @include media-md
        width: 78%

.mob-bottom-menu__ico
    display: flex !important
    align-items: center
    justify-content: center
    width: 30px
    height: 30px

// .mob-bottom-menu__txt
//     margin-top: 6px
