/*=============  News  =============*/

.news-top
    margin-top: 20px
    background-color: #EEF1F4
    @include media-md
        background-color: transparent
    @include media-lg
        margin-top: 32px

.news-top-carousel
    margin-left: -20px
    margin-right: -20px
    @include media-md
        margin-left: 0
        margin-right: 0

.news-top-slide
    background-color: #EEF1F4
    @include media-md
        background-color: #fff

.news-top-img
    position: relative
    display: flex
    align-items: center
    justify-content: center
    picture
        display: block
        overflow: hidden
        width: 100%
        @include media-md
            border-radius: 20px
        @include media-lg
            width: auto
        img
            width: 100%
            height: 100%
            object-fit: cover

.news-top-event
    position: absolute
    top: 10px
    right: 10px
    height: 24px
    display: flex
    align-items: center
    padding: 0 12px
    border-radius: 12px
    font-size: 12px
    font-weight: 700
    background-color: #fff
    color: $color-base
    @include media-lg
        top: 20px
        right: 20px
        height: 42px
        padding: 0 20px
        border-radius: 21px
        font-size: 20px

.news-top-content
    position: relative

.news-top-main
    position: relative
    padding-top: 22px
    padding-left: 20px
    padding-right: 20px
    padding-bottom: 24px
    @include media-md
        padding-left: 0
        padding-right: 0
        padding-bottom: 0
    @include media-lg
        padding-top: 0
        min-height: 186px
    @include media-xl
        min-height: 212px
    time
        display: block
        font-size: 14px
        line-height: 1
        color: #929292

.news-top-heading
    font-weight: 600
    line-height: 1.5
    margin-top: 5px
    @include media-lg
        font-size: 20px
        max-width: 310px

.news-top-nav
    display: flex
    justify-content: space-between
    position: absolute
    top: 0
    margin-top: -19px
    width: 100%
    padding-left: 5px
    padding-right: 5px
    @include media-md
        padding-left: 20px
        padding-right: 20px
    @include media-lg
        padding-left: 0
        padding-right: 0
        position: relative
        width: 146px

.news-tags-list
    padding-top: 27px
    border-top: 1px dashed #81B2BF
    @include media-md
        border-top: none
    @include media-lg
        padding-top: 30px
    a
        display: flex
        align-items: center
        height: 27px
        padding-left: 10px
        padding-right: 10px
        border-radius: 13px
        white-space: nowrap
        font-size: 14px
        text-decoration: none
        transition: background-color .3s, color .3s
        &:hover,
        &._current
            text-decoration: none
            background-color: $color-base
            color: #fff
        &+a
            margin-left: 10px
        // &:first-child
        // 	margin-left: -10px

.news-item
    margin-top: 24px
    padding: 0 20px

.post-card
    &._news
        .post-card-header
            time
                font-size: 14px
                margin-top: 17px
        .post-card-body
            margin-top: 5px
            line-height: 1.5
            a
                color: #000
                text-decoration: none
                &:hover
                    color: $color-link
                    text-decoration: underline

.post
    &._news
        padding-bottom: 36px
        .post-main
            margin-top: 24px

// .news-top-img
//     margin-left: -20px
//     margin-right: -20px
//     @include media-md
//         margin-left: 0
//         margin-right: 0

.post-tags-list
    margin-top: 34px
    a
        display: flex
        align-items: center
        height: 27px
        padding-left: 10px
        padding-right: 10px
        border-radius: 13px
        white-space: nowrap
        font-size: 14px
        text-decoration: none
        transition: background-color .3s, color .3s
        &:hover,
        &._current
            text-decoration: none
            background-color: $color-base
            color: #fff
        &+a
            margin-left: 10px

.section-news-carousel
    margin-top: 20px
    margin-left: -20px
    margin-right: -20px
    @include media-md
        margin-left: auto
        margin-right: auto
    @include media-lg
        margin-top: 40px
 