/*==============  Programs  ==============*/

.programs
    position: relative
    height: 100%
    overflow: hidden
    @include media-xl
        height: calc(100vh - 70px)
    h1
        &+p
            margin-top: 15px
            @include media-lg
                margin-top: 20px
    @include media-xxl
        background-position: calc( 50% + 220px ) 0
    &::before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background: linear-gradient(0deg, #EFFAFC 0%, rgba(239, 250, 252, 0) 100%)
    >.container
        position: relative
        height: 100%
        >.row
            height: 100%

.programs-left-col
    position: relative
    display: flex
    flex-direction: column
    z-index: 1
    @include media-lg
        padding-bottom: 60px

.programs-left-col-content
    @include media-lg
        height: 100%

.programs-share
    margin-top: 18px
    @include media-lg
        margin-top: 28px

.programs-quiz
    padding-top: 24px
    
        padding-top: 34px

.programs-right-col
    margin-top: 10px
    padding-bottom: 20px
    position: relative
    @include media-lg
        margin-top: 0
        padding-bottom: 0
    &::before
        content: ""
        display: block
        position: absolute
        top: -100px
        right: 0
        bottom: 0
        width: 690px
        margin-right: -320px
        background-image: url(../images/programs-bg.png)
        mix-blend-mode: multiply
        background-repeat: no-repeat
        background-position: calc(50% - 25px) 0
        background-size: 100%
        @include media-sm
            top: -60px
            left: 50%
            right: auto
            width: 986px
            margin-left: -493px
        @include media-lg
            top: 0
            background-size: contain

.programs-right-col
    &._bg-fixed
        &::before
            position: fixed
            top: -46px

.programs-list
    position: relative
    height: 100%
    // max-height: calc(100vh - 133px)
    // overflow: auto
    // margin-left: -20px
    // margin-right: -20px
    // padding-left: 20px
    // padding-right: 20px
    // @include media-sm
    //     max-height: none
    //     overflow: auto
    >div
        position: relative
        @include media-sm
            height: 100%

.programs-list-container
    @include media-lg
        position: absolute !important
        top: 0
        right: 0
        bottom: 0
    .scrollbar-track
        display: none !important

.programs-list-scroll
    @include media-sm
        padding-top: 19px
        padding-bottom: 20px
    @include media-md
        padding-top: 34px
    @include media-lg
        padding-top: 110px

// .programs-list-left
//     margin-left: -77px
//     padding-left: 77px

.programs-list-item
    a
        position: relative
        display: inline-flex
        align-items: center
        font-size: 14px
        line-height: 1.2
        height: 32px
        color: $color-link-dark
        background-color: #fff
        border-radius: 16px
        padding-left: 16px
        padding-right: 16px
        overflow: hidden
        margin-top: 8px
        span
            position: relative
        svg
            display: none
        @include media-md
            background-color: transparent
            margin-top: 0
            overflow: visible
            font-size: 18px
            height: 60px
            &::before
                content: ""
                display: block
                position: absolute
                top: -3px
                bottom: -3px
                border: 1px solid $color-base
                border-radius: 33px
                opacity: 0
                visibility: hidden
                background-color: #fff
                // transition: opacity .3s, visibility .3s
            svg
                display: block
                width: 47px
                height: auto
                position: absolute
                top: 7px
                opacity: 0
                visibility: hidden
                // transition: opacity .3s, visibility .3s
        &:hover
            color: $color-base
            &::before
                opacity: 1
                visibility: visible
            svg
                opacity: 1
                visibility: visible

.programs-list-left
    .programs-list-scroll
        @include media-md
            padding-left: 107px
        @include media-lg
            padding-left: 77px
    .programs-list-container
        @include media-md
            left: -40px
    .programs-list-item
        a
            @include media-md
                padding-right: 36px
                &::before
                    padding-right: 36px
                    left: -61px
                    right: 0
                svg
                    left: -51px

.programs-list-right
    .programs-list-scroll
        @include media-md
            padding-right: 107px
        @include media-xl
            padding-right: 77px
    .programs-list-container
        @include media-md
            right: -40px
    .programs-list-item
        @include media-sm
            text-align: right
        a
            @include media-md
                padding-left: 36px
                &::before
                    right: -61px
                    left: 0
                svg
                    right: -51px

.programs-single
    margin-top: 12px
    padding-bottom: 20px
    .h1+p
        margin-top: 4px
        @include media-lg
            margin-top: 12px
    @include media-lg
        margin-top: 24px
        //padding-bottom: 60px

.programs-single-right
    margin-left: -10px
    margin-right: -10px
    >div
        padding-left: 10px
        padding-right: 10px

.programs-single-img
    width: 100%
    margin-top: 12px
    @include media-md
        margin-top: 0
    picture
        display: block
        height: 100%
        overflow: hidden
        border-radius: 20px
        img
            width: 100%
            height: auto
            @include media-lg
                width: 100%
                height: 100%
                object-fit: cover

.programs-single-nav-container
    display: block !important
    width: 100%
    padding: 28px 23px 35px 30px
    background-color: #fff
    @include media-md
        width: 560px
    @include media-lg
        width: 232px
        border-radius: 20px
        background-color: #EEF1F4
    .h3
        font-size: 20px
        font-weight: 600
        color: #28A0A0
        line-height: 1
    ul
        font-size: 14px
        padding-left: 12px
        margin-top: 17px
        li
            line-height: 1.2
            &::before
                width: 4px
                height: 4px
                border-radius: 50%
                top: .5em
                left: -12px
            &+li
                margin-top: 12px
        a
            color: $color-link-dark

.programs-single-down
    background-color: #fff
    .product-faq
        padding-top: 0
        @include media-md
            padding-top: 67px
    .product-client
        padding-bottom: 34px
        @include media-lg
            padding-bottom: 60px

.programs-single-products
    padding-bottom: 10px
    @include media-lg
        padding-bottom: 70px

.programs-scroll-help
    opacity: 0
    visibility: hidden
    transition: opacity .3s, visibility .3s
    &._visible
        opacity: 1
        visibility: visible
