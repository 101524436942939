/*==============  Layout  ==============*/
.container,
.container-fluid
    padding-left: 20px
    padding-right: 20px
    @include media-xl
        padding-left: 50px
        padding-right: 50px

.site-primary
    flex: 1 0 auto
    display: flex
    position: relative
    &._green
        background-color: #F4FBFD
        &::before
            content: ""
            display: block
            position: absolute
            top: 0
            left: 0
            right: 0
            height: 694px
            //background: linear-gradient(0deg, #F4FBFD 0%, #fff 100%)
            background: #F4FBFD
            background: linear-gradient(0deg, #F4FBFD 0%, #fff 100%)
    &._gray
        background-color: #EEF1F4

.site-content
    width: 100%
    position: relative

._bold
    font-weight: 700
    &-xl
        @include media-xl
            font-weight: 700
    &-lg
        @include media-lg
            font-weight: 700
    &-md
        @include media-md
            font-weight: 700
    &-sm
        @include media-sm
            font-weight: 700

.text-center
    text-align: center
    &-xl
        @include media-xl
            text-align: center
    &-lg
        @include media-lg
            text-align: center
    &-md
        @include media-md
            text-align: center
    &-sm
        @include media-sm
            text-align: center

.text-left
    text-align: left
    &-xl
        @include media-xl
            text-align: left
    &-lg
        @include media-lg
            text-align: left
    &-md
        @include media-md
            text-align: left
    &-sm
        @include media-sm
            text-align: left

.text-right
    text-align: right
    &-xl
        @include media-xl
            text-align: right
    &-lg
        @include media-lg
            text-align: right
    &-md
        @include media-md
            text-align: right
    &-sm
        @include media-sm
            text-align: right

._color-danger
    color: $color-danger

._color-success
    color: $color-danger

.flex-1
    flex: 1

.flex-2
    flex: 2

.alert
    position: relative
    border-radius: 20px
    padding: 15px
    font-size: 14px
    margin-left: -10px !important
    margin-right: -10px !important
    @include media-md
        margin-left: 0 !important
        margin-right: 0 !important
    @include media-lg
        font-size: 18px
        line-height: 1.4
        padding: 15px 20px
    >a
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
    .row
        margin-left: -8px
        margin-right: -8px
        >div
            padding-left: 8px
            padding-right: 8px
    &._success
        background-color: #EFFAFC
        .alert-content
            position: relative
            &._with-ico
                padding-left: 28px
                // >svg
                //     width: 24px
                //     height: auto
                //     display: block
                //     position: absolute
                //     top: 0
                //     left: 0
                @include media-lg
                    padding-left: 40px
                &::before
                    content: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00362 18C4.03697 18.0067 0 13.9718 0 9C0 4.02881 4.03636 -0.00667097 9.00362 8.27933e-06C13.9751 0.00668753 17.9866 4.01606 18 8.9921C18.0133 13.9548 13.98 17.9933 9.00362 18ZM16.4327 8.95689C16.4242 4.85643 13.1189 1.5581 9.02791 1.5672C4.94601 1.57631 1.5673 4.95784 1.5673 9.03522C1.5673 13.1308 4.86828 16.4322 8.96476 16.4328C13.0467 16.4328 16.4412 13.0349 16.4327 8.95689Z' fill='%2338B1B1'/%3E%3Cpath d='M7.9637 10.8035C7.9637 10.1216 7.95641 9.43972 7.96552 8.75843C7.97402 8.12815 8.49079 7.67579 9.11686 7.73347C9.59476 7.77719 10.0253 8.21255 10.0277 8.69225C10.0356 10.1113 10.0368 11.5303 10.0277 12.9494C10.0241 13.4825 9.548 13.9148 9.00148 13.916C8.45435 13.9173 7.97766 13.4861 7.96916 12.9554C7.95762 12.2383 7.96613 11.5212 7.96613 10.8041C7.96552 10.8035 7.9643 10.8035 7.9637 10.8035Z' fill='%2338B1B1'/%3E%3Cpath d='M10.2473 5.34969C10.2454 6.0413 9.67888 6.60235 8.99087 6.59385C8.30225 6.58535 7.75391 6.03219 7.75391 5.34483C7.75391 4.6514 8.31197 4.09156 9.00301 4.09278C9.69345 4.09399 10.2497 4.65505 10.2473 5.34969Z' fill='%2338B1B1'/%3E%3C/svg%3E")
                    display: block
                    position: absolute
                    top: 2px
                    left: 0
                    @include media-lg
                        top: 0
                        content: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.0048 24C5.38262 24.0089 0 18.6291 0 12C0 5.37175 5.38181 -0.00889463 12.0048 1.10391e-05C18.6335 0.00891671 23.9821 5.35475 23.9999 11.9895C24.0178 18.6064 18.64 23.9911 12.0048 24ZM21.9102 11.9425C21.8989 6.47524 17.4919 2.07746 12.0372 2.08961C6.59468 2.10175 2.08973 6.61045 2.08973 12.047C2.08973 17.5078 6.49104 21.9096 11.953 21.9104C17.3955 21.9104 21.9215 17.3798 21.9102 11.9425Z' fill='%2338B1B1'/%3E%3Cpath d='M10.6209 14.4047C10.6209 13.4955 10.6112 12.5863 10.6233 11.6779C10.6346 10.8375 11.3237 10.2344 12.1584 10.3113C12.7956 10.3696 13.3697 10.9501 13.3729 11.5897C13.3834 13.4817 13.3851 15.3738 13.3729 17.2658C13.3681 17.9767 12.7333 18.5531 12.0046 18.5547C11.2751 18.5563 10.6395 17.9815 10.6282 17.2739C10.6128 16.3178 10.6241 15.3616 10.6241 14.4055C10.6233 14.4047 10.6217 14.4047 10.6209 14.4047Z' fill='%2338B1B1'/%3E%3Cpath d='M13.6643 7.13292C13.6619 8.05506 12.9065 8.80314 11.9891 8.7918C11.071 8.78047 10.3398 8.04292 10.3398 7.12644C10.3398 6.20187 11.0839 5.45541 12.0053 5.45703C12.9259 5.45865 13.6676 6.20673 13.6643 7.13292Z' fill='%2338B1B1'/%3E%3C/svg%3E%0A") 
    &._danger
        background-color: $color-danger-bg
    &._message
        line-height: 1.33333
        font-size: 12px
        @include media-lg
            font-size: 16px
        .row
            @include media-lg
                margin-left: -12px
                margin-right: -12px
            >div
                @include media-lg
                    padding-left: 12px
                    padding-right: 12px
        .h3
            font-size: 14px
            font-weight: 600
            @include media-lg
                font-size: 18px
        p
            font-size: 12px
            @include media-lg
                font-size: 14px
        &._success
            .h3
                color: $color-base
        &._danger
            .h3
                color: $color-danger
    &._unpaid-order
        .alert-btns
            @include media-xl
                max-width: 306px
            .btn
                min-width: 147px
                @include media-xl
                    min-width: 160px

.alert-col
    margin-top: 5px
    @include media-md
        margin-top: 0
        &:last-child
            max-width: 306px

.help
    position: relative
    width: 28px
    height: 28px
    border-radius: 50%
    background-color: $color-base
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='13' viewBox='0 0 9 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.552 8.288C3.552 7.712 3.68 7.23733 3.936 6.864C4.20267 6.48 4.58667 6.064 5.088 5.616C5.472 5.27467 5.75467 4.98133 5.936 4.736C6.128 4.48 6.224 4.192 6.224 3.872C6.224 3.424 6.04267 3.06133 5.68 2.784C5.31733 2.50667 4.832 2.368 4.224 2.368C3.072 2.368 2.20267 2.77867 1.616 3.6L0.064 2.592C0.522667 1.96267 1.11467 1.48267 1.84 1.152C2.56533 0.810666 3.41867 0.639999 4.4 0.639999C5.59467 0.639999 6.544 0.901333 7.248 1.424C7.96267 1.936 8.32 2.64533 8.32 3.552C8.32 3.98933 8.24533 4.37333 8.096 4.704C7.95733 5.03467 7.78667 5.31733 7.584 5.552C7.38133 5.776 7.11467 6.03733 6.784 6.336C6.37867 6.69867 6.07467 7.01867 5.872 7.296C5.68 7.57333 5.584 7.904 5.584 8.288H3.552ZM4.576 12.112C4.20267 12.112 3.89333 11.9947 3.648 11.76C3.41333 11.5147 3.296 11.216 3.296 10.864C3.296 10.512 3.41333 10.2187 3.648 9.984C3.89333 9.74933 4.20267 9.632 4.576 9.632C4.93867 9.632 5.23733 9.74933 5.472 9.984C5.71733 10.2187 5.84 10.512 5.84 10.864C5.84 11.216 5.71733 11.5147 5.472 11.76C5.23733 11.9947 4.93867 12.112 4.576 12.112Z' fill='white'/%3E%3C/svg%3E ")
    background-repeat: no-repeat
    background-position: 50% 50%
    cursor: pointer
    transition: background-color .3s
    &:hover
        background-color: #28A0A0
    &:active
        background-color: #1A848B

.btn
    display: inline-flex
    justify-content: center
    align-items: center
    height: 40px
    text-decoration: none
    background-color: $color-base
    color: #fff
    border-radius: 20px
    padding: 0 24px
    transition: color .3s, background-color .3s, border-color .3s
    letter-spacing: .02
    border: 1px solid transparent
    svg
        path
            transition: fill .3s
    &:hover
        background-color: #28A0A0
        color: #fff
        text-decoration: none
    &:active
        background-color: #1A848B
    &._accent
        background-color: $color-accent
        &:hover
            background-color: #F46028
        &:active
            background-color: #E84B0F
    &._invert
        background-color: transparent
        border-color: $color-base
        color: $color-base
        &:hover
            background-color: #28A0A0
            color: #fff
            svg
                path
                    fill: #fff
        &:active
            background-color: #1A848B
            svg
                path
                    fill: #fff
    &._dotted
        border-style: dashed
    &._danger
        background-color: transparent
        border-color: $color-danger
        color: $color-danger
        &:hover
            background-color: $color-danger
            color: #fff
        &:active
            background-color: $color-danger
            color: #fff
    &._large
        height: 50px
        border-radius: 25px
        font-weight: 600
        letter-spacing: .01em
    &._full
        width: 100% !important
    &[disabled], &_.disabled
        background-color: #9EAFC0
        &:hover
            background-color: #9EAFC0
        &:active
            background-color: #9EAFC0
        &._accent
            background-color: #DDE0E2
            color: #929292
            &:hover
                background-color: #DDE0E2
                color: #929292
            &:active
                background-color: #DDE0E2
                color: #929292
    &._extra-large
        height: 74px
        border-radius: 37px
        letter-spacing: 0.01em
        font-size: 20px
        font-weight: 600

.h1
   font-size: 28px
   color: $color-link-dark
   text-transform: uppercase
   line-height: 1.2
   font-weight: 400
   @include media-lg
        letter-spacing: .05em
        font-size: 36px

.h2
    font-size: 18px
    letter-spacing: 0.01em
    font-weight: 700
    line-height: 1.2
    @include media-lg
        font-size: 24px
    &._large
        font-size: 24px

.sticker
    display: inline-flex
    justify-content: center
    align-items: center
    height: 24px
    padding: 0 10px
    border-radius: 12px
    background-color: $color-base
    color: #fff
    font-size: 12px
    font-weight: 700
    width: auto
    text-decoration: none !important
    &._orange
        background-color: $color-accent
    &._blue
        background-color: #0FB0DD
    &._purple
        background-color: #B147B3
    &._orange-light
        background-color: #FFF1EC
        color: $color-accent
    &._small
        height: 16px
        padding: 0 5px
        border-radius: 8px
        font-weight: 600

a.link-ico-before
    display: inline-flex
    align-items: center
    text-decoration: underline
    svg
        position: relative
        display: block
        margin-right: 10px
        height: auto
    // &:hover
    //     svg
    //         *
    //             fill: $color-link-dark
    &._share
        font-size: 14px

body
    .scrollbar-track-y
        width: 3px
        background-color: #EFFAFC

body
    .scrollbar-thumb-y
        width: 3px
        background-color: $color-base

.breadcrumbs
    font-size: 10px
    letter-spacing: .01em
    color: $color-dark-gray
    padding-top: 20px
    span
        &+span
            &::before
                content: " — "
    a
        color: var(--color-base)

.page-title
    margin-top: 12px
    @include media-lg
        margin-top: 24px

.page-subtitle
    font-size: 13px
    color: #929292

.page-description
    margin-top: 16px

// [data-sticky]
//     width: 100%
//     &._sticky
//         position: fixed
//         top: 44px
//         @include media-xl
//             top: 70px

.d-xxl-flex
    @include media-xxl
        display: flex !important

.to-gallery
    cursor: pointer

.empty-page
    height: 100%
    background-color: #83D4D4
    display: flex
    align-items: center
    justify-content: center
    color: #fff
    letter-spacing: .05em
    a
        color: #fff
        text-decoration: underline

.empty-page-ico
    @include media-sm
        width: 190px
    svg
        position: relative
        max-width: 100%
        height: auto
    &._403
        svg
            left: 40px
            @include media-sm
                left: 0

.empty-page-name
    font-size: 94px
    font-weight: 800
    line-height: 1
    text-align: center
    margin-top: 28px
    @include media-sm
        margin-top: 0
    @include media-lg
        font-size: 136px

.empty-page-description
    font-size: 16px
    font-weight: 800
    line-height: 1
    text-align: center
    @include media-lg
        font-size: 23px

.empty-page-link
    text-align: center
    font-size: 16px
    @include media-lg
        font-size: 24px

.accordion-activator
    cursor: pointer

.accordion-content
    overflow: hidden

/*===============  Video  ===============*/

// .video-content
//     iframe
//         display: none

/*===============  Info Button  ===============*/

a.info-btn
    display: flex
    align-items: center
    padding: 10px
    border-radius: 56px
    background-color: #fff
    border: 1px solid $color-base
    color: $color-base
    text-decoration: none
    transition: background-color .3s
    &:hover
        background-color: #ECF5FF
    &:active
        background-color: $color-base
        color: #fff
    picture
        display: block
        min-width: 90px
        height: 90px
        overflow: hidden
        border-radius: 50%
    span
        display: block
        font-size: 25px
        line-height: 1.44
        padding-left: 24px

/*===============  Complex Product  ===============*/

.complex-products
    padding: 8px 10px 10px
    margin-left: -20px
    margin-right: -20px
    background-color: $color-base
    overflow: hidden
    @include media-md
        padding: 20px
        margin-left: -10px
        margin-right: -10px
        border-radius: 20px
        background-image: url(../images/complex-bg.png)
        background-position: 50% 100%
        background-repeat: no-repeat
        //background-size: cover
    @include media-lg
        margin-left: 0
        margin-right: 0
    @include media-xl
        background-position: 50% 50%
        background-repeat: no-repeat
        background-size: cover
    .product-card
        .product-card-btn
            margin-top: 0
            @include media-md
                margin-top: 16px
            @include media-lg
                margin-top: 0
            .btn
                 width: 100%

.complex-products-carousel
    overflow: visible

.complex-products-footer
    padding-left: 10px
    padding-right: 10px
    margin-top: 14px
    @include media-md
        padding-left: 0
        padding-right: 0
    @include media-lg
        margin-top: 30px

.complex-products-name
    font-weight: 700
    color: #fff
    @include media-lg
        font-size: 24px

.complex-products-buy
    min-width: 212px
    margin-top: 14px
    @include media-lg
        margin-top: 0

.complex-products-buy-price
    .product-card-price-money
        color: #fff
    .product-card-price-bb
        color: #fff

.complex-products-buy-btn
    .btn
        background-color: #fff
        color: $color-base
        svg
            margin-left: 7px
            *
                fill: $color-base
        &:hover
            background-color: #28A0A0
            border-color: #28A0A0
            color: #fff
            svg
                *
                    fill: #fff

/*===============  Float Help  ===============*/

.float-help
    position: relative
    cursor: pointer

.float-help-popover
    display: none
    // visability: hidden
    opacity: 0
    position: absolute
    width: 314px
    max-width: calc(100vw - 40px)
    transition: opacity .3s, visability .3s
    z-index: 1
    // &._bottom
    //     top: 100%
    // &._bottom-sm
    //     @include media-sm
    //         top: 100%
    // &._bottom-md
    //     @include media-md
    //         top: 100%
    // &._bottom-lg
    //     @include media-lg
    //         top: 100%
    // &._bottom-xl
    //     @include media-xl
    //         top: 100%
    // &._left
    //     left: 0
    // &.left-sm
    //     @include media-sm
    //         left: 0
    // &.left-md
    //     @include media-md
    //         left: 0
    // &.left-lg
    //     @include media-lg
    //         left: 0
    // &.left-xl
    //     @include media-xl
    //         left: 0
    // &._top
    //     bottom: 100%
    // &._top-sm
    //     @include media-sm
    //         bottom: 100%
    // &._top-md
    //     @include media-md
    //         bottom: 100%
    // &._top-lg
    //     @include media-lg
    //         bottom: 100%
    // &._top-xl
    //     @include media-xl
    //         bottom: 100%
    // &._right
    //     right: 0
    // &._right-sm
    //     @include media-sm
    //         right: 0
    // &._right-md
    //     @include media-md
    //         right: 0
    // &._right-lg
    //     @include media-lg
    //         right: 0
    // &._right-xl
    //     @include media-xl
    //         right: 0

.float-help-content
    padding: 20px
    border: 1px solid var(--color-base)
    border-radius: 20px
    background-color: var(--color-bg)
    color: #000 !important
    line-height: 1.5
    .h3
        font-size: 18px
        font-weight: 700
        &+p
            margin-top: 15px

.float-help-content-ico
    svg
        width: 24px
        height: auto
        *
            fill: var(--color-base)

.float-help-content-main
    margin-top: 18px

/*===============  Banners  ===============*/

.section-banner
    &._white
        background-color: #fff

.banner
    overflow: hidden
    border-radius: 20px
    max-width: 344px
    margin-left: auto
    margin-right: auto
    transition: box-shadow .3s
    @include media-md
        max-width: none
    &:hover
        box-shadow: 0px 1px 24px rgb(43 148 148 / 20%)
    img
        width: 100%

/*===============  Custom Select  ===============*/

.select-cstm
    display: block
    position: relative
    min-width: 174px
    cursor: pointer
    border-radius: 20px
    background-color: #ECF5FF
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.26799 6.0791C4.86821 6.55848 4.13179 6.55848 3.73201 6.0791L0.238901 1.89046C-0.304218 1.2392 0.158881 0.25 1.00689 0.25L7.99311 0.250001C8.84112 0.250001 9.30422 1.2392 8.7611 1.89047L5.26799 6.0791Z' fill='%2338B1B1'/%3E%3C/svg%3E ")
    background-repeat: no-repeat
    background-position: calc( 100% - 12px ) 50%
    box-shadow: inset 0px 1px 4px #b8c5d9
    font-size: 16px
    line-height: 1.33333
    @include media-lg
        font-size: 18px

.select-cstm
    &._active
        background-image: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.73201 0.920904C4.13179 0.441523 4.86821 0.441522 5.26799 0.920904L8.7611 5.10953C9.30422 5.76079 8.84112 6.75 7.99311 6.75H1.00689C0.15888 6.75 -0.304218 5.76079 0.238901 5.10953L3.73201 0.920904Z' fill='%2338B1B1'/%3E%3C/svg%3E ")
        border-radius: 20px 20px 0 0
        box-shadow: none
        &::before
            content: ""
            display: block
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: -40px
            border-radius: 20px 20px 0 0
            box-shadow: inset 0px 1px 4px #b8c5d9
        .select-cstm-options
            display: block
        &._top
            border-radius: 0 0 20px 20px
            &::before
                top: -40px
                bottom: 0
                border-radius: 0 0 20px 20px

.select-cstm-field-container
    position: relative
    min-height: 40px
    width: 100%
    
.select-cstm-field
    position: absolute
    top: 9px
    left: 16px
    right: 34px
    bottom: 0
    max-width: 100%
    overflow: hidden
    letter-spacing: .01em
    white-space: nowrap
    text-overflow: ellipsis
    @include media-lg
       top: 8px 
    span
        display: inline-block
        padding-left: 6px
        padding-right: 6px
        border-radius: 11px
        &+span
            margin-left: 4px

.select-cstm-options
    padding-left: 5px
    padding-right: 4px
    padding-bottom: 5px
    background-color: #ECF5FF
    border-radius: 0 0 20px 20px
    overflow: hidden
    z-index: 9999
    &._multiple
        .select-cstm-item
            &._selected
                background-color: transparent
                background-image: url("data:image/svg+xml,%3Csvg width='14' height='10' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.51494e-05 4.96707C-0.00533779 4.29494 0.605983 3.63956 1.34387 3.57085C1.84773 3.52407 2.26921 3.68171 2.62263 4.02355C3.10022 4.48491 3.57662 4.94801 4.05541 5.40822C4.59808 5.93079 5.27626 6.02895 5.94609 5.67037C6.08638 5.59531 6.20996 5.48271 6.32458 5.37184C7.99795 3.751 9.66953 2.12843 11.3387 0.504128C11.7226 0.130532 12.1715 -0.0663706 12.7208 0.0202436C13.2718 0.107435 13.6754 0.407697 13.8807 0.906017C14.0915 1.41704 14.0252 1.91363 13.664 2.35536C13.5876 2.4489 13.504 2.53898 13.4169 2.62386C11.048 4.91625 8.67793 7.20748 6.30906 9.49929C5.83923 9.95373 5.28581 10.1241 4.653 9.90523C4.42793 9.82727 4.20943 9.68118 4.0381 9.51719C2.84769 8.38024 1.67042 7.23 0.487779 6.08554C0.182715 5.79048 0.01496 5.4394 3.51494e-05 4.96707Z' fill='%2338B1B1'/%3E%3C/svg%3E")
                background-position: calc( 100% - 10px ) 15px
                background-repeat: no-repeat
            &:hover
                background-color: #EBF7F7
    .select-cstm-header
        display: none
        position: relative
        padding: 20px 20px 16px
        font-size: 18px
        font-weight: 700
        line-height: 1.2
        &._small
            font-size: 16px
            font-weight: 400
        &._large
            font-size: 24px
        .close
            position: absolute
            top: 10px
            right: -4px
            display: flex
            align-items: center
            justify-content: center
            width: 28px
            height: 28px
            svg
                display: block
    .select-cstm-footer
        display: none
    &._top
        padding-top: 5px
        padding-bottom: 0
        border-radius: 20px 20px 0 0
        &::before
            top: 0
            bottom: -40px
            border-radius: 20px 20px 0 0
    &._popup
        @media ( max-width: 767px )
            display: flex
            flex-direction: column
            position: fixed !important
            top: 0 !important
            left: 0 !important
            right: 0 !important
            bottom: 0 !important
            width: 100% !important
            border-radius: 0
            background-color: #fff
            padding: 0 10px 20px
            &::before
                content: none
                background-color: #ECF5FF
            .select-cstm-header
                display: block
            .select-cstm-footer
                display: block
            .select-cstm-list
                flex: 1 0 0
                max-height: none
                padding: 10px //
                background-color: #ECF5FF
                box-shadow: inset 0px 1px 4px #B8C5D9
                border-radius: 20px
            .select-cstm-footer
                padding: 10px 24px
                .btn
                    width: 100%
            &._light
                border: none
                padding: 0
                .select-cstm-header
                    padding: 20px
                    .close
                        right: 10px
                .select-cstm-list
                    flex: 1 0 0
                    max-height: none
                    padding: 0
                    background-color: transparent
                    box-shadow: none
                    border-radius: 0
                .select-cstm-item
                    min-height: 50px
                    padding-left: 34px
                    padding-right: 34px
                    color: #1A1A1A
                    border-radius: 0
                    &:hover
                        background-color: transparent
                    &._selected
                        background-color: #EEF1F4
                        background-position: calc(100% - 40px) 20px
    &::before
        content: ""
        display: block
        position: absolute
        top: -40px
        left: 0
        right: 0
        bottom: 0
        border-radius: 0 0 20px 20px
        box-shadow: inset 0px 1px 4px #b8c5d9
    @include media-lg
        padding-right: 14px

body
    &.select-popup-on
        @media ( max-width: 767px )
            max-height: 100%
            overflow: hidden

.select-cstm-list
    padding-right: 7px
    overflow-x: hidden
    @include media-lg
        padding-right: 17px
    .scrollbar-track-y
        background-color: transparent
    .scrollbar-thumb-y
        background-color: rgba(2, 100, 127, .1)
    .scrollbar-track-x
        height: 3px
        background-color: transparent
    .scrollbar-thumb-x
        height: 3px
        background-color: rgba(2, 100, 127, .1)

.select-cstm-list
    max-height: 265px

.select-cstm-item
    position: relative
    min-height: 40px
    padding: 4px 34px 4px 11px
    display: flex
    flex-wrap: wrap
    align-items: center
    border-radius: 20px
    &:hover
        background-color: #fff
    &._selected
        background-color: #fff
    &+.select-cstm-item
        margin-top: 5px

.select-cstm
    &._light
        border: 1px solid $color-base
        background-color: #fff
        box-shadow: none
        font-size: 14px
        color: $color-base

        &::before
            content: none
        .select-cstm-field
            top: 10px
            span
                display: inline-block
                background-color: #EBF7F7
                padding-left: 6px
                padding-right: 6px
                border-radius: 11px
                &+span
                    margin-left: 4px

.select-cstm-options
    &._light
        padding: 0
        left: -1px
        right: -1px
        background-color: #fff
        border: 1px solid $color-base
        border-top: 1px solid transparent
        &::before
            content: none
        .select-cstm-list
            padding: 0
        .select-cstm-item
            padding-left: 16px
            color: $color-link-dark
            border-radius: 0
            &:hover
                background-color: #EBF7F7
            &._selected
                background-color: #EEF1F4
            &+.select-cstm-item
                margin-top: 0

// .select-cstm
//     display: block
//     position: relative
//     min-width: 174px
//     cursor: pointer
//     height: 40px
//     border-radius: 20px
//     border: 1px solid $color-base
//     background-color: #fff
//     background-image: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.26799 6.0791C4.86821 6.55848 4.13179 6.55848 3.73201 6.0791L0.238901 1.89046C-0.304218 1.2392 0.158881 0.25 1.00689 0.25L7.99311 0.250001C8.84112 0.250001 9.30422 1.2392 8.7611 1.89047L5.26799 6.0791Z' fill='%2338B1B1'/%3E%3C/svg%3E ")
//     background-repeat: no-repeat
//     background-position: calc( 100% - 12px ) 50%

// .select-cstm-field
//     position: absolute
//     top: 7px
//     left: 16px
//     right: 34px
//     bottom: 0
//     max-width: 100%
//     overflow: hidden
//     color: $color-base
//     font-size: 14px
//     letter-spacing: .01em
//     white-space: nowrap
//     text-overflow: ellipsis
//     span
//         display: inline-block
//         background-color: #EBF7F7
//         padding-left: 6px
//         padding-right: 6px
//         border-radius: 11px
//         &+span
//             margin-left: 4px


// .select-cstm
//     &._active
//         background-image: url("data:image/svg+xml,%3Csvg width='9' height='7' viewBox='0 0 9 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.73201 0.920904C4.13179 0.441523 4.86821 0.441522 5.26799 0.920904L8.7611 5.10953C9.30422 5.76079 8.84112 6.75 7.99311 6.75H1.00689C0.15888 6.75 -0.304218 5.76079 0.238901 5.10953L3.73201 0.920904Z' fill='%2338B1B1'/%3E%3C/svg%3E ")
//         border-radius: 20px 20px 0 0
//         border-bottom: 1px solid transparent  
//         .select-cstm-options
//             display: block

// .select-cstm-options
//     display: none
//     position: absolute
//     top: 100%
//     left: -1px
//     right: -1px
//     background-color: #fff
//     border: 1px solid $color-base
//     border-top: 1px solid transparent
//     border-radius: 0 0 20px 20px
//     overflow: hidden
//     z-index: 1

// .select-cstm-item
//     height: 40px
//     padding: 4px 34px 4px 16px
//     display: flex
//     flex-wrap: wrap
//     align-items: center
//     color: $color-link-dark
//     &:hover
//         background-color: #EBF7F7
//     &._selected
//         background-color: #EEF1F4

/*===============  Video  ===============*/

.section-video
    padding-top: 32px
    padding-bottom: 56px
    background-color: #ECF5FF
    overflow: hidden
    a
        text-decoration: none
    &._light
        background-color: #fff
    .section-footer
        margin-top: 40px
    @include media-lg
        padding-top: 40px
        padding-bottom: 50px

.section-video-carousel
    margin-top: 34px
    // margin-left: -20px
    // margin-right: -20px
    overflow: visible
    @include media-sm
        margin-left: 0
        margin-right: 0
    @include media-lg
        margin-top: 40px

.video-card-img
    display: block
    position: relative
    width: 100%
    padding-bottom: 61%
    background-position: 50% 50%
    background-size: cover
    overflow: hidden
    border-radius: 20px
    span
        display: flex
        align-items: center
        justify-content: center
        position: absolute
        width: 40px
        height: 40px
        top: 50%
        left: 50%
        margin-top: -20px
        margin-left: -20px
        background-color: #fff
        border-radius: 50%
        svg
            display: block
            position: relative
            left: 2px
            *
                transition: fill .3s
    &::before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: #000
        opacity: 0
        transition: opacity .3s
        border-radius: 20px

.video-card-img-bg
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    img
        width: 100%
        height: 100%
        object-fit: center

.video-card
    position: relative
    overflow: hidden
    border-radius: 20px
    a
        display: block
        &:hover
            .video-card-img
                span
                    svg
                        *
                            fill: $color-link-dark
                &::before
                    opacity: .2
            .video-card-text
                text-decoration: underline

.video-card-text
    display: block
    margin-top: 10px
    color: #000
    line-height: 1.5

.horizontal-scroll
    position: relative
    margin-left: -20px
    margin-right: -20px
    .scroll-content
        // width: auto
        // min-width: 100%
        display: flex
        &::after,
        &::before
            content: ""
            display: block
            height: 1px
            min-width: 20px
        // >*:first-child
        //     margin-left: 20px
        // >*:last-child
        //     margin-right: 20px
    .scrollbar-track
        position: absolute
        bottom: 0
        // display: none !important
        height: 3px
        border-radius: 2px
        background-color: #EFFAFC
        .scrollbar-thumb
            height: 3px
            border-radius: 2px
            background-color: #FF6432
            opacity: .4
    &._hide-track
        .scrollbar-track
            display: none !important

.video-content
    iframe
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        width: 100%
        height: 100%

/*===============  Audio Player  ===============*/
    
.audio-player
    .audio-btn
        cursor: pointer
        width: 76px
        height: 76px
        border-radius: 50%
        background-color: $color-base
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='28' viewBox='0 0 24 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.3087 12.2936C23.5845 13.0735 23.5845 14.9265 22.3087 15.7064L3.91824 26.9487C2.58554 27.7634 0.875095 26.8043 0.875095 25.2423L0.875095 2.75772C0.875095 1.19573 2.58555 0.23661 3.91825 1.0513L22.3087 12.2936Z' fill='white'/%3E%3C/svg%3E")
        background-position: calc(50% + 4px) 50%
        background-repeat: no-repeat
        transition: background-color .3s
        &:hover
            background-color: #28A0A0
        &:active
            background-color: #1A848B
        canvas
            width: 76px
            height: 76px
            border-radius: 50%
            background-color: transparent
    &._play
        .audio-btn
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='22' viewBox='0 0 17 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 0C0.447715 0 0 0.447716 0 1V21C0 21.5523 0.447715 22 1 22H5C5.55228 22 6 21.5523 6 21V1C6 0.447715 5.55228 0 5 0H1ZM12 0C11.4477 0 11 0.447716 11 1V21C11 21.5523 11.4477 22 12 22H16C16.5523 22 17 21.5523 17 21V1C17 0.447715 16.5523 0 16 0H12Z' fill='white'/%3E%3C/svg%3E")
            background-position: 50% 50%

.audio-activator
    margin-right: 24px

.audio-info-name
    font-weight: 600
    line-height: 1.25
    color: $color-base
    @include media-lg
        font-size: 20px
    span
        display: none
    

.audio-info-autor
    font-size: 12px
    color: #929292
    @include media-lg
        font-size: 18px
        color: #000

/*===============  Carousels  ===============*/
.carousel-prev,
.carousel-next
    display: flex
    align-items: center
    justify-content: center
    width: 38px
    height: 38px
    border-radius: 50%
    background-color: #fff
    transition: background-color .3s
    z-index: 1
    opacity: .7
    transition: opacity .3s
    @include media-lg
        width: 52px
        height: 52px
    svg
        display: block
        position: relative
        width: 9px
        height: auto
        @include media-lg
            width: 15px
        *
            transition: fill .3s
            
    &._dark
        background-color: #E6EFF2
    &:hover
        opacity: 1
    &:active
        opacity: 1
    &.swiper-button-disabled
        &:hover
            opacity: .7
        &:active
            opacity: .7
        svg
            *
                fill: #DDE0E2

.category-carousel-prev
    left: -10px
    @include media-xl
        left: -26px

.category-carousel-next
    right: -10px
    @include media-xl
        right: -26px

/*===============  Popular Products  ===============*/

.section-popular
    background-color: #EFFAFC
    padding-top: 24px
    @include media-lg
        padding-top: 44px

.section-popular-carousel
    position: relative
    margin-left: -20px
    margin-right: -20px
    .carousel-prev,
    .carousel-next
        position: absolute
        top: 50%
        margin-top: -19px
    .carousel-prev
        left: 5px
        @include media-lg
            left: 0
    .carousel-next
        right: 5px
        @include media-lg
            right: 0

.section-popular-item
    padding-top: 16px
    padding-left: 10px
    padding-right: 10px
    padding-bottom: 20px
    @include media-md
        padding-left: 20px
        padding-right: 20px
    @include media-lg
        padding-top: 44px
        padding-bottom: 37px

/*===============  Section Seo  ===============*/

.section-seo
    padding-top: 40px
    padding-bottom: 57px
    background-color: #fff
    @include media-lg
        padding-bottom: 120px

.section-seo-text
    max-width: 994px

.seo-text-content
    overflow: hidden
    @include media-md
        height: auto !important
        overflow: auto

.section-seo-footer
    padding-top: 12px

a.seo-text-activator
    display: inline-block
    text-decoration: underline

// Fancybox
.fancybox__container
    &:focus
        outline: none !important

.fancybox__backdrop
    background: rgba(158, 175, 192, 0.5) !important

.carousel__button
    width: 38px
    height: 38px
    @include media-lg
        width: 52px
        height: 52px
    svg
        stroke-width: 0
        filter: none !important
    &.is-next,
    &.is-prev
        box-shadow: 0px 20px 20px 0px rgba(2, 100, 127, 0.08)

.fancybox__content > .carousel__button.is-close
    top: -46px
    right: -8px
    @include media-lg
        top: -3px
        right: -3px

.fancybox__slide
    padding-left: 20px
    padding-right: 20px

.fancybox__carousel
    .fancybox__slide.has-video
        .fancybox__content
            @include media-lg
                padding: 40px
                background-color: #fff
                border-radius: 20px

