/*****************************************************
 ** Header *******************************************
 *****************************************************/

.site-header
    background-color: $color-light-gray
    position: sticky
    top: 0
    // left: 0
    // width: 100%
    z-index: 100

.site-header-content
    height: 44px
    @include media-xl
        height: 70px

.header-logo
    svg
        width: 100%
        max-width: 88px
        height: auto
        @include media-xl
            max-width: 100px
        @include media-xxl
            max-width: 140px

.header-burger
    @include media-xl
        padding: 0 20px
    @include media-xxl
        padding: 0 40px

.header-burger__btn
    position: relative
    width: 26px
    height: 26px
    cursor: pointer
    >span
        display: block
        position: absolute
        top: 50%
        left: 0
        width: 100%
        height: 2px
        margin-top: -1px
        >span
            display: block
            margin: 0 auto
            width: 100%
            height: 2px
            background-color: $color-base
            transition: all .2s .1s
        &::before,
        &::after
            content: ""
            display: block
            position: absolute
            left: 0
            width: 100%
            height: 2px
            background-color: $color-base
            transition: all .2s
        &::before
            top: -8px
        &::after
            top: 8px
    &._active
        span
            >span
                transition: all .2s
                width: 0
            &::before
                top: 0
                transform: rotate( 45deg )
                transition: all .2s .1s
            &::after
                top: 0
                transform: rotate( -45deg )
                transition: all .2s .1s

.header-nav-item
    a
        font-size: 14px
        letter-spacing: 0.01em
        font-weight: 600
    &+.header-nav-item
        margin-left: 12px
        @include media-xxl
            margin-left: 40px

.header-catalog__btn
    height: 40px
    display: inline-flex
    justify-content: flex
    align-items: center
    padding: 0 24px
    border-radius: 20px
    background-color: $color-link
    color: #fff
    transition: background-color .3s
    @include media-xl
        padding: 0 14px
    @include media-xxl
        padding: 0 24px
    &:hover,
    &._active
        text-decoration: none
        color: #fff
        background-color: $color-link-dark

.header-ui
    position: relative
    a
        display: flex
        align-items: center
        justify-content: center
        width: auto
        height: 26px
        border: 1px solid #DDE0E2
        border-radius: 13px
        color: #1A1A1A
        font-size: 11px
        transition: background-color .3s
        margin-left: 10px
        @include media-xl
            border-radius: 20px
            height: 40px
            font-size: 14px
        &:hover
            background-color: #fff
            border-color: transparent
            svg
                g, path
                    transition: opacity .3s, fill .3s
                    opacity: 1
        &:active
            svg
                *
                    fill: #1A848B
        &.header-favorites
            &:hover
                background: rgba(56, 177, 177, 0.1)
            &:active
                background-color: #fff
                svg
                    *
                        fill: #1A848B

.search-form
    .btn
        font-size: 14px
        padding: 0 20px
    .search-form-ico
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.7846 15.8211C9.6659 18.7072 4.34135 17.5092 1.67061 13.8815C-0.880741 10.4163 -0.47344 5.44248 2.64356 2.44886C5.80289 -0.585391 10.7676 -0.863079 14.2368 1.96968C17.7315 4.82361 18.4978 9.91428 15.8355 13.7587C15.8889 13.8146 15.9431 13.8756 16.0015 13.9331C17.9406 15.8685 17.526 15.4518 19.4676 17.3846C19.8927 17.8079 20.0959 18.3023 19.9562 18.8966C19.6937 20.0107 18.3346 20.3706 17.4777 19.5595C17.2889 19.38 17.1085 19.1921 16.9239 19.0075C15.1406 17.2246 15.7102 17.7954 13.9286 16.0107C13.8736 15.9565 13.8346 15.888 13.7846 15.8211ZM14.4866 8.67738C14.4875 5.44671 11.7998 2.91027 8.65569 2.93397C5.50144 2.95768 2.88828 5.5754 2.92045 8.78067C2.95263 11.9495 5.62507 14.6976 9.07739 14.4936C12.0369 14.3192 14.5264 11.8327 14.4866 8.67738Z' fill='%2338B1B1'/%3E%3C/svg%3E")
        background-position: 15px 50%
        background-repeat: no-repeat

#header-search-form
    overflow: hidden
    position: absolute
    top: 0
    left: 10px
    right: 0
    bottom: 0
    width: auto
    border-radius: 20px
    visibility: hidden
    opacity: 0
    transition: opacity .05s .2s, visibility .05s .2s
    @media ( min-width: 1440px )
        left: auto
        min-width: 406px
    input[type="text"]
        background-color: #fff
        border-color: #EEF1F4
        &:focus
            border-color: $color-base
    .complex-input
        transition: transform .3s
        transform: translateX( calc( 100% - 50px ) )
    .search-form-ico
        cursor: pointer
    .complex-input-clear
        svg
            *
                fill: #DDE0E2
    &._active
        visibility: visible
        opacity: 1
        transition: opacity .1s, visibility .1s
        .complex-input
            transform: translateX( 0 )

.header-phone
    padding: 0 14px
    span
        display: inline-block
        margin-left: 7px
        margin-right: 9px

a.header-lng
    padding: 0 5px
    margin-left: 14px
    @include media-md
        margin-left: 30px
    @include media-xl
        padding: 0 10px
        margin-left: 10px

.header-lng_flag
    position: relative
    width: 16px
    height: 16px
    overflow: hidden
    border-radius: 50%
    margin-right: 5px
    @include media-xl
        margin-right: 8px
        width: 20px
        height: 20px
    img
        display: block
        height: 100%
        width: auto

.header-search,
.header-favorites
    min-width: 50px

.header-user
    display: flex
    align-items: flex-end
    height: 60px
    border-radius: 4px
    padding: 0 10px 6px
    transition: background-color .3s

a.header-user
    &:hover
        background-color: rgba( 56, 177, 177, 0.1 )
    &:active
        svg
            *
                fill: #1A848B

// .header-login
//     &:hover
//         background-color: $color-success-bg

.header-user-content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%

.header-user-ico
    position: relative

.header-user-badge
    position: absolute
    display: flex
    justify-content: center
    align-items: center
    top: -4px
    right: -14px
    width: 18px
    height: 18px
    border-radius: 50%
    background-color: $color-accent
    font-size: 10px
    font-weight: 700
    color: #fff

.header-user-text
    font-size: 10px
    color: #1A1A1A

.header-login,
.header-cart
    margin-left: 10px

.header-cart
    width: 78px

.header-order
    margin-left: 10px
    position: relative
    &::before
        content: ""
        display: block
        position: absolute
        top: 10px
        left: 0
        width: 1px
        height: 50px
        background-color: #DDE0E2

.header-cart-info
    margin-left: 5px
    span
        display: block

.header-cart-seller
    font-size: 10px
    color: rgba( 0, 0, 0, .4 )

.header-cart-sum
    font-size: 14px
    font-weight: 500
    line-height: 1.1

.header-cart-bb
    font-size: 10px
    color: $color-base
    font-weight: 600

.header-order-toggle__btn
    display: flex
    justify-content: center
    align-items: center
    width: 24px
    height: 50px
    border: 1px solid #DDE0E2
    border-radius: 3px
    transition: background-color .3s, border-color .3s
    svg
        transition: transform .3s
        path
            transition: fill .3s
    &:hover
        background-color: #ECF5FF
        border-color: $color-base
        svg
            path
               fill: $color-base
    &._active
        svg
            transform: rotate3d( 1, 0, 0, 180deg )

.header-cart-section
    position: relative

.header-order-list
    position: absolute
    top: calc( 100% + 5px)
    left: 10px
    right: -24px

.header-order-item
    background-color: $color-light-gray
    position: relative
    padding: 0 10px
    // opacity: 0
    display: none
    .header-cart
        margin-left: 0
    >div
        padding: 5px 0
        // transition: background-color .3s
        // .header-user
        //     &:hover
        //         background-color: transparent
        &:hover
            background-color: #fff
    &::before
        content: ""
        display: block
        position: absolute
        top: -1px
        left: 10px
        right: 10px
        height: 1px
        background-color: #DDE0E2
    &:last-child
        border-radius: 0 0 10px 10px

.header-order-toggle
    width: 0

.top-stock-panel
    position: relative
    padding-top: 17px
    padding-bottom: 17px
    background-color: var(--color-dark)
    color: #fff
    font-size: 14px
    line-height: 1.4
    text-transform: uppercase
    text-align: center
    a
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
