/*=============  Contacts  =============*/

.contacts-nav
    padding-bottom: 16px
    padding-top: 8px
    border-bottom: 1px dashed rgba( 2, 100, 127, .5)
    @include media-lg
        padding-bottom: 30px
        padding-top: 22px

.contacts-nav-list
    margin-top: 20px
    @include media-sm
        display: flex
        align-items: center
    @include media-lg
        margin-top: 34px

.contacts-nav-item
    white-space: nowrap
    line-height: 20px
    a
        color: #000
        &:hover
            text-decoration: none
            color: $color-link
    &+.contacts-nav-item
        margin-top: 30px
        @include media-sm
            margin-top: 0
            margin-left: 30px
        @include media-lg
            margin-left: 50px

.contacts-connect
    padding-top: 24px
    @include media-lg
        padding-top: 40px

.contacts-connect-col
    &+.contacts-connect-col
        margin-top: 34px
        @include media-lg
            margin-top: 0

.contacts-connect-land
    font-size: 20px
    line-height: 1.2
    @include media-lg
        font-size: 24px

.contacts-connect-phone
    margin-top: 10px
    font-size: 20px
    font-weight: 700
    line-height: 1.2
    white-space: nowrap
    a
        &:hover
            text-decoration: none
    @include media-lg
        font-size: 32px

.contacts-connect-mail
    margin-top: 10px
    line-height: 1.25
    a
        text-decoration: underline
    @include media-lg
        font-size: 18px

.contacts-connect-text
    margin-top: 12px
    font-size: 14px
    line-height: 1.4
    overflow: visible
    height: 20px

.contacts-connect-soc
    a
        display: flex
        align-items: center
        justify-content: center
        width: 80px
        height: 80px
        border-radius: 30px
        box-shadow: 0px 1px 10px rgba(2, 100, 127, 0.12)
        transition: box-shadow .3s, background-color .3s
        &:hover
            box-shadow: 0px 1px 24px 0px rgba(43, 148, 148, 0.2)
        &:active
            background-color: #F6F8F9
            box-shadow: 0px 1px 10px rgba(2, 100, 127, 0.12)
        &+a
            margin-left: 20px

.contacts-about
    padding-top: 40px
    @include media-lg
        padding-top: 72px
    .post-main
        .alert
            max-width: 1110px

.contacts-gallery
    margin-top: 40px

.contacts-gallery-row
    margin-left: -20px
    margin-right: -20px
    @include media-md
        margin-left: -16px
        margin-right: -16px

.contacts-gallery-col
    padding-left: 10px
    padding-right: 10px
    @include media-md
        padding-left: 16px
        padding-right: 16px

.contacts-gallery-item
    picture
        display: block
        overflow: hidden
        border-radius: 27px
        img
            width: 100%
            height: 100%
            object-fit: cover
    &+.contacts-gallery-item
        margin-top: 32px

.contacts-offices
    margin-top: 45px

.contacts-office-table
    width: 100%

.contacts-office-table-row
    min-width: 1100px
    display: grid
    column-gap: 32px
    border-bottom: 1px solid #D8D8D8
    line-height: 1.5
    padding: 14px 0 12px
    @include media-lg
        padding: 24px 0 22px
    @include media-xl
        width: 100%
    &:first-child
        border-top: 1px solid #D8D8D8

.contacts-office-type
    .contacts-office-table-row
        grid-template-columns: 400px 208px 208px 208px 208px
        @include media-xl
            grid-template-columns: 2fr 1fr 1fr 1fr 1fr
        &:first-child
            padding-bottom: 14px
            border-top: none
        &:not(:first-child)
            >div
                align-self: center
                &:first-child
                    padding-left: 33px
        &:nth-child(2)
            >div
                &:first-child
                    background-image: url("data:image/svg+xml,%3Csvg width='13' height='18' viewBox='0 0 13 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99931 18C6.2633 17.2458 5.63244 16.4208 5.0256 15.5653C4.34212 14.5994 3.71364 13.5928 3.1434 12.5505C2.56667 11.5148 2.0676 10.4345 1.65062 9.31911C1.3938 8.64338 1.20205 7.94286 1.07817 7.22782C0.936236 6.36257 0.978774 5.4755 1.20278 4.62921C1.50256 3.45973 2.12477 2.40686 2.99282 1.6002C3.80597 0.835797 4.80624 0.319367 5.88356 0.107743C6.96088 -0.103881 8.07348 -0.00249482 9.09893 0.400743C10.1075 0.792642 10.9958 1.45985 11.6704 2.33216C12.2745 3.10345 12.689 4.01589 12.8783 4.9908C13.073 5.96157 13.0198 6.92829 12.7816 7.88826C12.5367 8.8403 12.2064 9.76633 11.795 10.6542C10.9872 12.4251 10.0121 14.1082 8.8841 15.6787C8.31254 16.5003 7.68251 17.2763 6.99931 18Z' fill='%2338B1B1'/%3E%3Ccircle cx='7' cy='6' r='3' fill='white'/%3E%3C/svg%3E")
                    background-repeat: no-repeat
                    background-position: 0 0
        &:nth-child(3)
            >div
                &:first-child
                    background-image: url("data:image/svg+xml,%3Csvg width='13' height='18' viewBox='0 0 13 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99931 18C6.2633 17.2458 5.63244 16.4208 5.0256 15.5653C4.34212 14.5994 3.71364 13.5928 3.1434 12.5505C2.56667 11.5148 2.0676 10.4345 1.65062 9.31911C1.3938 8.64338 1.20205 7.94286 1.07817 7.22782C0.936236 6.36257 0.978774 5.4755 1.20278 4.62921C1.50256 3.45973 2.12477 2.40686 2.99282 1.6002C3.80597 0.835797 4.80624 0.319367 5.88356 0.107743C6.96088 -0.103881 8.07348 -0.00249482 9.09893 0.400743C10.1075 0.792642 10.9958 1.45985 11.6704 2.33216C12.2745 3.10345 12.689 4.01589 12.8783 4.9908C13.073 5.96157 13.0198 6.92829 12.7816 7.88826C12.5367 8.8403 12.2064 9.76633 11.795 10.6542C10.9872 12.4251 10.0121 14.1082 8.8841 15.6787C8.31254 16.5003 7.68251 17.2763 6.99931 18Z' fill='%230FB0DD'/%3E%3Ccircle cx='7' cy='6' r='3' fill='white'/%3E%3C/svg%3E")
                    background-repeat: no-repeat
                    background-position: 0 0
        &:nth-child(4)
            >div
                &:first-child
                    background-image: url("data:image/svg+xml,%3Csvg width='13' height='18' viewBox='0 0 13 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99931 18C6.2633 17.2458 5.63244 16.4208 5.0256 15.5653C4.34212 14.5994 3.71364 13.5928 3.1434 12.5505C2.56667 11.5148 2.0676 10.4345 1.65062 9.31911C1.3938 8.64338 1.20205 7.94286 1.07817 7.22782C0.936236 6.36257 0.978774 5.4755 1.20278 4.62921C1.50256 3.45973 2.12477 2.40686 2.99282 1.6002C3.80597 0.835797 4.80624 0.319367 5.88356 0.107743C6.96088 -0.103881 8.07348 -0.00249482 9.09893 0.400743C10.1075 0.792642 10.9958 1.45985 11.6704 2.33216C12.2745 3.10345 12.689 4.01589 12.8783 4.9908C13.073 5.96157 13.0198 6.92829 12.7816 7.88826C12.5367 8.8403 12.2064 9.76633 11.795 10.6542C10.9872 12.4251 10.0121 14.1082 8.8841 15.6787C8.31254 16.5003 7.68251 17.2763 6.99931 18Z' fill='%23B147B3'/%3E%3Ccircle cx='7' cy='6' r='3' fill='white'/%3E%3C/svg%3E")
                    background-repeat: no-repeat
                    background-position: 0 0
        &:nth-child(5)
            >div
                &:first-child
                    background-image: url("data:image/svg+xml,%3Csvg width='13' height='18' viewBox='0 0 13 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.99931 18C6.2633 17.2458 5.63244 16.4208 5.0256 15.5653C4.34212 14.5994 3.71364 13.5928 3.1434 12.5505C2.56667 11.5148 2.0676 10.4345 1.65062 9.31911C1.3938 8.64338 1.20205 7.94286 1.07817 7.22782C0.936236 6.36257 0.978774 5.4755 1.20278 4.62921C1.50256 3.45973 2.12477 2.40686 2.99282 1.6002C3.80597 0.835797 4.80624 0.319367 5.88356 0.107743C6.96088 -0.103881 8.07348 -0.00249482 9.09893 0.400743C10.1075 0.792642 10.9958 1.45985 11.6704 2.33216C12.2745 3.10345 12.689 4.01589 12.8783 4.9908C13.073 5.96157 13.0198 6.92829 12.7816 7.88826C12.5367 8.8403 12.2064 9.76633 11.795 10.6542C10.9872 12.4251 10.0121 14.1082 8.8841 15.6787C8.31254 16.5003 7.68251 17.2763 6.99931 18Z' fill='%23FF7744'/%3E%3Ccircle cx='7' cy='6' r='3' fill='white'/%3E%3C/svg%3E")
                    background-repeat: no-repeat
                    background-position: 0 0

.contacts-office-indicator
    width: 12px
    height: 12px
    border-radius: 50%
    background-color: rgba(196, 196, 196, 0.3)
    &._green
        background-color: #FF6432
    &._blue
        background-color: #0FB0DD
    &._purple
        background-color: #B147B3
    &._orange
        background-color: #FF7744

.contacts-search
    padding-top: 42px
    @include media-lg
        padding-top: 72px

.contacts-search-map
    height: 410px
    margin-top: 24px
    margin-left: -10px
    margin-right: -10px
    overflow: hidden
    border-radius: 20px
    background-image: url(../images/map.png)
    background-position: 50% 50%
    background-repeat: no-repeat
    @include media-md
        margin-left: 0
        margin-right: 0
    @include media-lg
        margin-top: 30px
        height: 450px

.contacts-search-form
    margin-top: 30px
    .h2
        font-size: 24px
    @include media-lg
        margin-top: 40px

.contacts-search-form-row
    margin-top: -4px
    margin-left: -10px
    margin-right: -10px

.contacts-search-form-col
    padding-left: 10px
    padding-right: 10px
    &:last-child
        margin-top: 14px
    @include media-sm
        &:last-child
            margin-top: 0
    @include media-lg
        &:first-child
            max-width: 310px
            min-width: 272px
        &:nth-child(2),
        &:nth-child(3)
            max-width: 430px
        &:last-child
            max-width: 240px
    .btn
        width: 100%
        height: 40px
        border-radius: 20px
        font-size: 16px
        font-weight: 600
        @include media-lg
            height: 50px
            border-radius: 25px
            font-size: 18px

.contacts-result
    margin-top: 32px
    .contacts-office-table-row
        grid-template-columns: 200px 340px 140px 310px auto
        // grid-template-columns: 200px 1.75fr 1fr 310px auto
        @include media-xl
            grid-template-columns: 175px 1.75fr 1fr 1.5fr auto
        @include media-xxl
            grid-template-columns: 1fr 1.75fr 1fr 1.5fr auto
        >div
            align-self: center
            a
                white-space: nowrap
                text-decoration: none
                svg
                    position: relative
                    top: -2px
                    display: inline-block
                    margin-right: 10px
                span
                    text-decoration: underline

.contacts-requisites
    padding-top: 44px
    padding-bottom: 76px
    @include media-lg
        padding-top: 72px
        padding-bottom: 132px

.contacts-requisites-main
    margin-top: 24px
    line-height: 1.5
    max-width: 590px
    @include media-lg
        margin-top: 30px
        font-size: 18px
