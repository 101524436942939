/*===============  Base  ===============*/

*
    -webkit-box-sizing: border-box
    -moz-box-sizing: border-box
    box-sizing: border-box
    &:before, &:after
        -webkit-box-sizing: border-box
        -moz-box-sizing: border-box
        box-sizing: border-box

*::-webkit-input-placeholder
    color: $color-placeholder
    opacity: 1

*:-moz-placeholder
    color: $color-placeholder
    opacity: 1

*::-moz-placeholder
    color: $color-placeholder
    opacity: 1

*:-ms-input-placeholder
    color: $color-placeholder
    opacity: 1

@media (prefers-reduced-motion: no-preference)
    :root
        scroll-behavior: auto

html
    height: 100%
    font-size: $fz-base + px
    line-height: $lh-base
    &.no-scroll
        touch-action: none

body input:focus:required:invalid,
body textarea:focus:required:invalid
    color: #000
body input:required:valid,
body textarea:required:valid
    color: #000

body
    height: 100%
    min-width: 320px
    position: relative
    font-family: $font-default
    overflow-x: hidden
    color: $color-text
    font-size: $fz-base + px
    line-height: $lh-base
    opacity: 0
    transition: opacity .3s .3s

h1, h2, h3, h4, h5, h6, p, ul, ol, table, figure, blockquote
    margin-bottom: 0

h1, h2, h3, h4, h5, h6, p, ul, ol, table, figure, blockquote, .alert
    &+h2, &+h3, &+h4, &+h5, &+h6
        margin: 30px 0 0 0
        @include media-lg
            margin: 36px 0 0 0


h1, h2, h3, h4, h5, h6, p, ul, ol, table, figure, blockquote, .alert
    &+p, &+ul, &+ol, &+table, &+table
        margin: 24px 0 0 0

h1, h2, h3, h4, h5, h6, p, ul, ol, table, figure, .alert
    &+figure, &+blockquote, &+.alert
        margin: 30px 0 0 0
        @include media-lg
            margin: 44px 0 0 0

figure, blockquote, .alert
    &+h2, &+h3, &+h4, &+h5, &+h6, &+p, &+ul, &+ol, &+table, &+table
        margin: 30px 0 0 0
        @include media-lg
            margin: 44px 0 0 0

h1, h2, h3, h4, h5, h6
    font-weight: 700
    margin-bottom: 0 

h1
    +fz(36)

h2
    +fz(24)

h3
    +fz(18)
    @include media-lg
       +fz(20)

h4
    +fz(16)

h5
    +fz(14)

h6
    +fz(12)

ul, ol
    padding-left: 33px
    list-style: none
    li
        position: relative
        &+li
            margin-top: 20px

ul
    li
        &::before
            content: ''
            display: inline-block
            position: absolute
            width: 19px
            height: 2px
            background-color: $color-base
            left: -33px
            top: .7em
            font-weight: 700

ol
    list-style-type: none
    counter-reset: num
    li
        &::before
            content: counter(num)'.'
            counter-increment: num
            display: block
            position: absolute
            width: 19px
            height: 100%
            left: -33px
            top: 0
            font-weight: 700
            color: $color-base

a
    color: $color-link
    text-decoration: none
    transition: color .3s
    svg
        *
            transition: fill .3s
    &:hover
        color: $color-link-dark
        text-decoration: underline

figure
    >div
        border-radius: 20px
        overflow: hidden
        margin: 0 -10px
        @include media-lg
            margin: 0
        img
            width: 100%
    figcaption
        margin-top: 8px
        font-size: 9px
        line-height: 1.33333
        color: #929292
        @include media-lg
            padding-left: 0
            margin-top: 18px
            font-size: 12px

blockquote
    padding: 20px 20px 40px
    background-color: #EFFAFC
    border-radius: 20px
    position: relative
    display: block
    font-size: 18px
    line-height: 1.8
    margin-left: -10px !important
    margin-right: -10px !important
    @include media-md
        margin-left: 0 !important
        margin-right: 0 !important
    @include media-lg
        padding: 25px 30px 44px
        font-size: 20px
    &::after
        content: url("data:image/svg+xml,%3Csvg width='40' height='32' viewBox='0 0 40 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.04429 0C3.91608 0 0 3.57647 0 9.03529C0 12.9882 1.95804 15.8118 4.94173 17.1294L0.932401 32H10.1632L15.5711 18.4471C17.6224 13.2706 17.9953 11.4824 17.9953 9.03529C17.9953 3.67059 14.2657 0 9.04429 0ZM31.049 0C25.9207 0 22.0047 3.57647 22.0047 9.03529C22.0047 12.9882 23.9627 15.8118 26.9464 17.1294L22.9371 32H32.1678L37.5758 18.4471C39.627 13.2706 40 11.4824 40 9.03529C40 3.67059 36.2704 0 31.049 0Z' fill='%2328A0A0'/%3E%3C/svg%3E")
        position: absolute
        top: 100%
        right: 35px
        margin-top: -25px
        display: block
    small
        margin-top: 10px
        display: block
        text-align: right
        font-size: 16px
        // text-decoration: underline

.site
    display: flex
    flex-direction: column
    min-height: 100%
    // overflow: hidden
    // padding-top: 44px
    padding-bottom: 60px
    @include media-xl
        // padding-top: 70px
        padding-bottom: 0

:root 
    --color-base: #FF6432
    --color-accent: #FF7744
    --color-accent-hover: #F46028
    --color-accent-active: #E84B0F
    --color-dark: #1A1A1A
    --color-bg: #F1FBFB
    --color-link-share: #FF6432
    --color-sticker-bg: #FFF1EC
    --color-link: #28A0A0
    --color-link-hover: #1A848B
    --gradient-color: radial-gradient(104.58% 457.49% at 5.16% 0%, #E8FBFF 2.33%, #E2F8FD 23.2%, #F1FAFC 100%)

body
    &._purple
        --color-base: #9375C2
        --color-accent: #9e3fa0
        --color-accent-hover: #913a92
        --color-accent-active: #813483
        --color-dark: #4F229A
        --color-bg: #F8F3FF
        --color-link-share: #9375C2
        --color-sticker-bg: #F8F3FF
        --color-link: #9375C2
        --color-link-hover: #7d61aa
        --gradient-color: radial-gradient(104.58% 457.49% at 5.16% 0%, #F8F3FF 2.33%, #EBE2F9 23.2%, #F9F5FF 100%)
    &._blue
        --color-base: #2B8ACE
        --color-accent: #0FB0DD
        --color-accent-hover: #0f9fc7
        --color-accent-active: #0c92b8
        --color-dark: #0E63A0
        --color-bg: #EEF6FC
        --color-link-share: #2B8ACE
        --color-sticker-bg: #E7FAFF
        --color-link: #2B8ACE
        --color-link-hover: #227ab9
        --gradient-color: radial-gradient(104.58% 457.49% at 5.16% 0%, #EDF7FF 2.33%, #D5EDFE 23.2%, #EDF7FF 100%)
    &._green
        --color-base: #5EA17C
        --color-accent: #FF7744
        --color-accent-hover: #F46028
        --color-accent-active: #E84B0F
        --color-dark: #2D8067
        --color-bg: #F2FFF8
        --color-link-share: #5EA17C
        --color-sticker-bg: #FFF1EC
        --color-link: #5EA17C
        --color-link-hover: #4e8d6a
        --gradient-color: radial-gradient(104.58% 457.49% at 5.16% 0%, #F2FFF8 2.33%, #E0F1E8 23.2%, #F7FFFB 100%)
