/*****************************************************
 ** Catalog ******************************************
 *****************************************************/

.site-left-menu
    flex: 0 0 auto
    width: 21.44%

.left-menu-mob-btn
    margin-top: 16px
    .btn
        height: 32px
        padding: 0 16px
        border-radius: 16px
        font-size: 14px
        svg
            display: inline-block
            margin-right: 8px

.catalog
    padding-bottom: 20px
    @include media-lg
        padding-bottom: 70px

.catalog-categories
    padding-top: 30px
    padding-bottom: 75px

.catalog-categories-main
    max-width: 240px
    margin-left: -20px
    margin-right: -20px

.catalog-category
    border-radius: 20px
    min-height: 40px
    display: flex
    align-items: center
    font-size: 16px
    line-height: 1.5
    color: $color-link-dark
    padding: 5px 20px
    transition: background-color .3s
    &.accordion-activator
        justify-content: space-between
        &::after
            content: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L6 6L11 1' stroke='%2302647F' stroke-linecap='round'/%3E%3C/svg%3E ")
            transition: prtransformop .3s
    &._bold
        font-weight: 700
    &:hover
        background-color: #ECF5FF
        text-decoration: none
        color: $color-link-dark
    &._current
        background-color: #EBF7F7
    .star
        &::before
            content: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z' fill='%23FF7744'/%3E%3C/svg%3E")
            position: relative
            top: 2px
    span
        &+span
            margin-left: 10px
    svg
        margin-left: 4px
        position: relative
        top: 1px

.catalog-categories-list
    padding-left: 20px

.catalog-categories-section
    &.accordion
        &._active
            .accordion-activator
                &::after
                    content: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 6L6 1L1 6' stroke='%2302647F' stroke-linecap='round'/%3E%3C/svg%3E ")

.catalog-categories-footer
    max-width: 240px
    padding-top: 20px
    margin-left: -20px
    margin-right: -20px
    a
        display: flex
        align-items: center
        height: 44px
        background-color: #fff
        text-transform: uppercase
        font-size: 14px
        padding: 0 20px
        border-radius: 22px
        color: $color-base
        border: 1px solid $color-base
        transition: background-color .3s, color .3s
        &:hover
            text-decoration: none
            color: #fff
            background-color: $color-base
        &+a
            margin-top: 10px

.catalog-filter
    position: relative
    z-index: 2
    @include media-lg
        padding-top: 16px

.catalog-filter-container
    margin-left: -12px
    margin-right: -12px
    max-width: 752px
    @include media-lg
        max-width: none

.catalog-filter-item
    padding-top: 20px
    padding-left: 12px
    padding-right: 12px
    .select-cstm
        width: 100%
        min-width: 0

.catalog-filter-size
    input[type="text"]
        background-color: #fff
        width: 100%
        font-size: 16px
        box-shadow: inset 0px 1px 4px #b8c5d9 !important
        @include media-lg
            width: 60px

.catalog-filter-size-label
    padding-right: 12px

.catalog-filter-size-separator
    padding-left: 4px
    padding-right: 4px

.catalog-filter-btn
    .btn
        font-size: 14px
        min-width: 150px
        width: 100%
        @include media-lg
            width: auto

.catalog-filter-reset
    height: 100%
    a
        font-size: 14px
        color: #929292
        transition: color .3s
        svg
            display: inline-block
            position: relative
            top: -1px
            *
                transition: fill .3s
        &:hover
            text-decoration: none
            color: $color-link
            svg *
                fill: $color-link

.catalog-sorting
    position: relative
    margin-top: 24px
    @include media-lg
        margin-top: 34px

.catalog-sorting-container
    position: relative
    height: 24px
    overflow: visible
    z-index: 1

.catalog-sorting-select
    position: relative
    display: inline-flex
    font-size: 14px
    &::before
        content: ""
        display: block
        position: absolute
        top: -7px
        left: -10px
        right: -7px
        bottom: -12px
        background-color: #fff
        box-shadow: 0px 1px 10px rgba(2, 100, 127, 0.12)
        border-radius: 10px
    .catalog-sorting-options,
    &::before
        opacity: 0
        visibility: hidden
        transition: opacity .3s, visibility .3s
    &._active
        .catalog-sorting-options,
        &::before
            opacity: 1
            visibility: visible
    
.catalog-sorting-label
    position: relative
    margin-right: 4px

.catalog-sorting-body
    position: relative
    color: $color-link
    cursor: pointer

.catalog-sorting-field
    display: flex
    align-items: center

.catalog-sorting-quad
    width: 24px
    height: 24px
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='4' viewBox='0 0 10 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5 4L9.33013 0.25H0.669873L5 4Z' fill='%23C4C4C4'/%3E%3C/svg%3E ")
    background-repeat: no-repeat
    background-position: 50% 50%
    border: 1px solid #C4C4C4
    border-radius: 3px
    margin-left: 10px

.catalog-sorting-item
    transition: color .3s
    margin-top: 4px
    padding-right: 13px
    &._current
        display: none
    &:hover
        color: $color-link-dark
        text-decoration: underline

.catalog-content
    margin-left: auto
    margin-right: auto
    max-width: 324px
    &._empty
        max-width: 100%
    .product-list-item
        margin-top: 30px
        padding-left: 10px
        padding-right: 10px
        @include media-sm
            padding-left: 20px
            padding-right: 20px
        &+.product-list-item
            margin-top: 10px
            @include media-sm
                margin-top: 30px
    .product-list-item-wrapper
        height: 100%
    @include media-sm
        max-width: 700px
        &._empty
            max-width: 100%
    @include media-lg
        max-width: 100%

.product-card
    height: 100%
    padding: 20px
    position: relative
    background-color: #fff
    border-radius: 20px
    display: flex
    flex-direction: column
    transition: box-shadow .3s
    @include media-lg
        padding: 32px 20px 20px
    &._horizontal
        @include media-xl
            flex-direction: row
            padding-top: 17px
            .product-card-img
                width: 100%
                max-width: 50%
                padding-right: 34px
            .product-card-stickers-item
                .sticker
                    height: 18px
                    border-radius: 9px
                    line-height: 1
                    font-size: 10px
                &+.product-card-stickers-item
                    padding-top: 0
            .product-card-favorites
                right: 34px
            .product-card-body
                width: 100%
                max-width: 50%
            .product-card-title
                padding-top: 0
            .product-card-btn
                .btn
                    font-size: 0
                    svg
                        margin-left: 0
    &:hover
        box-shadow: 0px 1px 24px rgba(43, 148, 148, 0.2)

.product-card-img
    position: relative
    height: 100%
    display: flex
    flex-direction: column

.product-card-favorites
    position: absolute
    top: 0
    right: 10px
    cursor: pointer
    svg
        display: block
        *
            fill: rgba(2, 100, 127, .15)
            transition: fill .3s
    &:hover
            svg
                @media (hover: hover) and (pointer: fine)
                    *
                        fill: $color-base
    &.remove-from-favorites
        svg
            *
                fill: #1A848B

.product-card-stickers
    position: absolute
    top: 0
    left: 0
    display: block

.product-card-stickers-item
    display: block
    &+.product-card-stickers-item
        padding-top: 8px

.product-card-img-container
    height: 100%
    a
        height: 100%
        display: flex
        align-items: center
        justify-content: center
    img
        width: auto
        height: auto
        display: block
        max-width: 100%
        max-height: 100%

.product-card-title
    padding-top: 24px
    display: block
    line-height: 1.25
    color: inherit
    &:hover
        text-decoration: none
        color: inherit
    @include media-lg
        padding-top: 34px

.product-card-param
    display: block
    font-size: 13px
    color: #929292

.product-card-name
    display: block
    min-height: 60px
    @include media-lg
        margin-top: 10px

.product-card-bottom
    margin-top: 25px

.product-card-price
    position: relative
    letter-spacing: .02em
    white-space: nowrap

.product-card-price-old
    position: absolute
    left: 0
    bottom: 100%
    display: flex
    align-items: center
    white-space: nowrap
    span
        &+span
            margin-left: 4px

.product-card-price-old__val
    font-size: 14px
    color: #929292
    text-decoration: line-through

.product-card-price-money
    font-size: 18px
    line-height: 1
    font-weight: 600

.product-card-price-bb
    font-size: 14px
    color: $color-base

.product-card-btn
    .btn
        svg
            margin-left: 7px

.product-card
    &._mob-small
        @media ( max-width: 767px )
            padding: 13px 10px
            .product-card-img-container
                padding: 10px
                img
                    max-width: 80%
            .product-card-favorites
                right: 3px
                svg
                    width: 13px
                    height: auto
            .product-card-title
                padding-top: 12px
            .product-card-param
                font-size: 10px
            .product-card-name
                margin-top: 5px
                font-size: 12px
                min-height: 40px
            .product-card-price-old__val
                font-size: 11px
            .sticker._small
                font-size: 10px
            .product-card-price-money
                font-size: 14px
            .product-card-price-bb
                font-size: 11px
            .product-card-btn
                .btn
                    height: 24px
                    font-size: 11px
                    padding-left: 10px
                    padding-right: 10px
                    svg
                        width: 7px
                        height: auto
                        margin-left: 3px
            .product-card-bottom
                margin-top: 20px

.popup-products-info-card
    .product-card
        &._mob-small
            @media ( max-width: 576px )
                .product-card-param
                    font-size: 8px
                .product-card-name
                    font-size: 10px
                .product-card-price-old__val
                    font-size: 9px
                .sticker._small
                    font-size: 8px
                .product-card-price-money
                    font-size: 12px
                .product-card-price-bb
                    font-size: 9px
                .product-card-btn
                    .btn
                        font-size: 9px

.product-banner
    overflow: hidden
    border-radius: 20px
    height: 100%
    max-height: 436px
    @include media-sm
        min-height: 0
    img
        width: 100%
        height: 100%
        object-fit: cover

.catalog-footer
    margin-top: 20px
    .link-ico-before
        font-size: 12px
        padding-top: 30px
        svg
            width: 18px
    @include media-md
        .link-ico-before
            padding-top: 0
    @include media-lg
        margin-top: 44px
        .pagination,
        .link-ico-before
            padding-top: 34px
        .link-ico-before
            font-size: 14px
            svg
                margin-right: 14px
                width: auto

.pagination
    a
        display: flex
        align-items: center
        justify-content: center
        width: 38px
        height: 38px
        background-color: #fff
        border-radius: 19px
        border: 1px solid transparent
        color: #0F0F0F
        transition: color .3s, background-color .3s, border-color .3s
        @include media-lg
            width: 48px
            height: 48px
            border-radius: 24px
        &:hover
            color: $color-base
            background-color: #ECF5FF
            border-color: $color-base
            text-decoration: none
        &._current
            color: #fff
            background-color: $color-base
        &+a
            margin-left: 10px
        &.pagination-prev,
        &.pagination-next
            background-color: #ECF5FF
            font-size: 0
            @include media-lg
                font-size: inherit
                width: 142px
                padding: 0 24px
                justify-content: space-between
            &::before,
            &::after
                display: block
                position: relative
                @include media-lg
                    top: 2px
        &.pagination-prev
            &::before
                content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.60705 5.5221C1.64966 5.5221 1.69271 5.5221 1.73533 5.5221C5.31719 5.5221 8.89948 5.52162 12.4813 5.52353C12.6672 5.52353 12.8196 5.4675 12.9237 5.29414C13.1149 4.97567 12.9273 4.55471 12.5776 4.51736C12.5305 4.51209 12.4827 4.51401 12.4352 4.51401C8.87137 4.51401 5.30796 4.51401 1.74412 4.51401C1.70062 4.51401 1.65713 4.51401 1.58376 4.51401C1.62682 4.46324 1.65317 4.4302 1.68129 4.39907C2.7537 3.23007 3.82654 2.06155 4.89895 0.892552C5.11993 0.651665 5.11685 0.324096 4.89455 0.114816C4.73727 -0.0326859 4.48949 -0.0393905 4.32782 0.102364C4.2852 0.139719 4.2461 0.181862 4.207 0.224005C2.86529 1.68561 1.52489 3.14818 0.181419 4.60739C0.0443487 4.75633 -0.0347309 4.91916 0.0149136 5.12988C0.0316076 5.20027 0.0711479 5.26541 0.105855 5.3291C0.123868 5.36262 0.154181 5.38848 0.180102 5.41674C1.52401 6.88122 2.86836 8.34522 4.21095 9.81114C4.31683 9.92704 4.43326 10.0041 4.58614 9.99983C4.76583 9.99456 4.89982 9.90644 4.98154 9.73021C5.06984 9.54008 5.04436 9.35954 4.93673 9.18953C4.90817 9.14403 4.87039 9.10524 4.83392 9.06549C3.78656 7.9257 2.73876 6.7864 1.69052 5.64709C1.65977 5.61357 1.62286 5.58627 1.58859 5.5561C1.59518 5.54508 1.60134 5.53359 1.60705 5.5221Z' fill='%2338B1B1'/%3E%3C/svg%3E")
                @include media-lg
                    content: url("data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.47208 8.28181C2.53764 8.28181 2.60388 8.28181 2.66944 8.28181C8.18009 8.28181 13.6914 8.2811 19.2021 8.28397C19.488 8.28397 19.7225 8.19993 19.8827 7.93989C20.1767 7.46221 19.8881 6.8308 19.3501 6.77477C19.2778 6.76687 19.2041 6.76975 19.1311 6.76975C13.6481 6.76975 8.16589 6.76975 2.68296 6.76975C2.61604 6.76975 2.54913 6.76975 2.43625 6.76975C2.50249 6.6936 2.54305 6.64404 2.5863 6.59735C4.23618 4.84392 5.88674 3.09122 7.53662 1.33779C7.8766 0.976479 7.87187 0.485147 7.52987 0.17124C7.28789 -0.0500033 6.90668 -0.0600598 6.65795 0.152564C6.59239 0.208593 6.53223 0.271805 6.47208 0.335017C4.40786 2.52734 2.34568 4.72109 0.278768 6.90982C0.0678859 7.13322 -0.0537774 7.37745 0.0225998 7.69351C0.0482841 7.7991 0.109116 7.89679 0.162512 7.99233C0.190224 8.04261 0.236862 8.0814 0.27674 8.12378C2.34433 10.3204 4.4126 12.5163 6.47816 14.7151C6.64105 14.8889 6.82017 15.0046 7.05538 14.9981C7.33182 14.9902 7.53798 14.8581 7.66369 14.5937C7.79955 14.3085 7.76035 14.0377 7.59475 13.7827C7.55082 13.7145 7.49269 13.6563 7.43659 13.5967C5.82523 11.8871 4.21321 10.1782 2.6005 8.4693C2.55319 8.41901 2.49641 8.37807 2.44369 8.33281C2.45383 8.31629 2.46329 8.29905 2.47208 8.28181Z' fill='%2338B1B1'/%3E%3C/svg%3E ")
        &.pagination-next
            &::after
                content: url("data:image/svg+xml,%3Csvg width='13' height='10' viewBox='0 0 13 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.393 4.4779C11.3503 4.4779 11.3073 4.4779 11.2647 4.4779C7.68281 4.4779 4.10052 4.47838 0.518658 4.47647C0.332822 4.47647 0.180374 4.5325 0.0762528 4.70586C-0.114856 5.02433 0.0727381 5.44529 0.422445 5.48264C0.469453 5.48791 0.51734 5.48599 0.564788 5.48599C4.12863 5.48599 7.69204 5.48599 11.2559 5.48599C11.2994 5.48599 11.3429 5.48599 11.4162 5.48599C11.3732 5.53676 11.3468 5.5698 11.3187 5.60093C10.2463 6.76993 9.17346 7.93845 8.10105 9.10745C7.88007 9.34834 7.88315 9.6759 8.10545 9.88518C8.26273 10.0327 8.51051 10.0394 8.67218 9.89764C8.7148 9.86028 8.7539 9.81814 8.793 9.776C10.1347 8.31439 11.4751 6.85182 12.8186 5.39261C12.9557 5.24367 13.0347 5.08084 12.9851 4.87012C12.9684 4.79973 12.9289 4.7346 12.8941 4.6709C12.8761 4.63738 12.8458 4.61152 12.8199 4.58326C11.476 3.11878 10.1316 1.65478 8.78905 0.18886C8.68317 0.0729654 8.56675 -0.00413724 8.41386 0.000172397C8.23417 0.00544048 8.10018 0.0935581 8.01846 0.269794C7.93016 0.459919 7.95564 0.640464 8.06327 0.810474C8.09183 0.85597 8.12961 0.894761 8.16608 0.93451C9.21344 2.0743 10.2612 3.2136 11.3095 4.35291C11.3402 4.38643 11.3771 4.41373 11.4114 4.4439C11.4048 4.45492 11.3987 4.46641 11.393 4.4779Z' fill='%2338B1B1'/%3E%3C/svg%3E")
                @include media-lg
                    content: url("data:image/svg+xml,%3Csvg width='20' height='15' viewBox='0 0 20 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.5279 8.28181C17.4624 8.28181 17.3961 8.28181 17.3306 8.28181C11.8199 8.28181 6.30859 8.2811 0.797949 8.28397C0.512041 8.28397 0.277503 8.19993 0.117313 7.93989C-0.176705 7.46221 0.111906 6.8308 0.649926 6.77477C0.722247 6.76687 0.795921 6.76975 0.868919 6.76975C6.35185 6.76975 11.8341 6.76975 17.317 6.76975C17.384 6.76975 17.4509 6.76975 17.5637 6.76975C17.4975 6.6936 17.457 6.64404 17.4137 6.59735C15.7638 4.84392 14.1133 3.09122 12.4634 1.33779C12.1234 0.976479 12.1281 0.485147 12.4701 0.17124C12.7121 -0.0500033 13.0933 -0.0600598 13.3421 0.152564C13.4076 0.208593 13.4678 0.271805 13.5279 0.335017C15.5921 2.52734 17.6543 4.72109 19.7212 6.90982C19.9321 7.13322 20.0538 7.37745 19.9774 7.69351C19.9517 7.7991 19.8909 7.89679 19.8375 7.99233C19.8098 8.04261 19.7631 8.0814 19.7233 8.12378C17.6557 10.3204 15.5874 12.5163 13.5218 14.7151C13.3589 14.8889 13.1798 15.0046 12.9446 14.9981C12.6682 14.9902 12.462 14.8581 12.3363 14.5937C12.2004 14.3085 12.2397 14.0377 12.4052 13.7827C12.4492 13.7145 12.5073 13.6563 12.5634 13.5967C14.1748 11.8871 15.7868 10.1782 17.3995 8.4693C17.4468 8.41901 17.5036 8.37807 17.5563 8.33281C17.5462 8.31629 17.5367 8.29905 17.5279 8.28181Z' fill='%2338B1B1'/%3E%3C/svg%3E ")

.catalog-search
    margin-top: 14px
    width: 100%
    @include media-sm
        max-width: 408px
    @include media-lg
        margin-top: 17px
    label
        font-size: 14px
        padding-bottom: 5px
    .complex-input
        input
            padding-right: 120px
            @media( max-width: 576px )
                padding-right: 40px
    .complex-input-btn
        @media( max-width: 576px )
            position: relative
            margin-top: 20px
            .btn
                width: 100%
    .complex-input-clear
        @media( max-width: 576px )
            height: 40px
            position: absolute
            top: -60px
            right: 4px

.catalog-search-message
    height: 12px
    margin-top: 20px
    overflow: visible

.catalog-search-empty
    padding-top: 70px
    padding-bottom: 46px
    //padding-bottom: 86px


.catalog-search-empty-name
    margin-top: 27px
    font-size: 28px
    line-height: 1.2
    color: #9EAFC0
    @include media-lg
        font-size: 48px

.catalog-search-empty-text
    margin-top: 7px
    font-size: 14px
    a
        text-decoration: underline
    @include media-lg
        font-size: 16px

[data-popup-for="#popup-search"]
    .popup-left-menu
        @include media-md
            width: 100%
    .scom-popup-main
        flex-direction: column
        width: 100%
        height: 100%
        .popup-search
            position: relative
            height: 100%
            background-color: #F4FBFD
            overflow: hidden
            &::before
                content: ""
                display: block
                position: absolute
                top: 0
                left: 0
                right: 0
                height: 694px
                background: linear-gradient(0deg, #F4FBFD 0%, #fff 100%)
            section
                position: relative
        .popup-search-content
            position: relative
            height: 100%
            overflow: auto
