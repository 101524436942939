/*===============  Service  ===============*/

.service-categories-item
    &+.service-categories-item
        margin-top: 5px
    a
        display: inline-flex

.service-main
    padding-bottom: 76px
    @include media-lg
        padding-bottom: 110px
    .post-main
        margin-top: 27px

.service-banner
    padding-top: 17px

.popup-service-heading
    padding-top: 8px
    padding-bottom: 20px

.service-faq
    margin-top: 30px
    @include media-lg
        margin-top: 38px

.service-faq-section-name
    line-height: 1.25
    padding-bottom: 24px

.service-faq-section
    font-size: 24px
    font-weight: 700
    &::before
        color: #000
        line-height: 1.25

.service-faq-item
    margin-left: -33px
    padding: 10px 0 15px 33px
    border-top: 1px dashed rgba(2, 100, 127, 0.5)
    @include media-lg
        margin-left: 0
        padding: 22px 0 26px 33px

.service-faq-quest
    position: relative
    font-size: 18px
    line-height: 1.25
    transition: color .3s
    cursor: pointer
    &:hover
        color: $color-base
    &::before
        content: ""
        display: block
        position: absolute
        top: 6px
        left: -33px
        width: 15px
        height: 10px
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='10' viewBox='0 0 15 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.26426 9.09428C7.86475 9.56774 7.13525 9.56774 6.73573 9.09428L0.660758 1.8949C0.112126 1.24472 0.574302 0.25 1.42502 0.250001L13.575 0.250002C14.4257 0.250002 14.8879 1.24473 14.3392 1.8949L8.26426 9.09428Z' fill='%2338B1B1'/%3E%3C/svg%3E")
        transition: transform .3s

.service-faq-item
    &._active
        .service-faq-quest
            &::before
                transform: rotate3d(1, 0, 0, 180deg)

.service-faq-answer
    font-size: 16px
    font-weight: 400
    margin-left: -33px
    @include media-lg
        margin-left: 0

.service-faq-answer-container
    padding-top: 16px
