//==============  FONT FAMILY  ==============//
$font-default: "Montserrat", sans-serif //----- Base font

//==============  COLORS  ==============//
$color-base: #FF6432       //----- Base color
$color-accent: #FF7744     //----- Accent color
$color-placeholder: rgba(0, 0, 0, .3)   //----- Placeholders color rgba(0, 0, 0, .3)
$color-success: #28A0A0    //----- Success color
$color-success-bg: #F1FBFB //----- Success color background
$color-danger: #FF0000     //----- Danger color
$color-danger-bg: #FFF1EC  //----- Danger color background
$color-text: #000          //----- Standart text color
$color-link: #28A0A0       //----- Links color
$color-link-dark: #1A1A1A  //----- Links color Dark
$color-light-gray: #EEF1F4 //----- Light-gray
$color-dark-gray: #929292  //----- Dark-gray

//==============  FONT SIZES  ==============//
$fz-base: 16       //----- Base font size (px)
$lh-base: 27/$fz-base      //----- Base line height

//==============  FONT SIZES VARIABLES  ==============//
=fz($fz)
    font-size: $fz/$fz-base + rem

=lh($lh, $fz)
    line-height: $lh/$fz

//==============  CUSTOMIZATION BOOTSTRAP GRID  ==============//
//----- Vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 40px //----- Gutters widths
$grid-columns: 12        //----- Columns count

$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 1024px, xl: 1200px, xxl: 1366px)//----- Breakpoints
$container-max-widths: (sm: 100%, md: 100%, lg: 100%, xl: 100%, xxl: 1440px)!default //----- Containers widths
