/*=============  Blog  =============*/

.section-blog
    padding-top: 40px
    padding-bottom: 52px
    background-color: #fff
    @include media-lg
        padding-top: 52px
    .section-header
        a
            text-decoration: underline


.section-blog-carousel
    margin-top: 20px
    margin-left: -20px
    margin-right: -20px
    @include media-md
        margin-left: auto
        margin-right: auto
    @include media-lg
        margin-top: 40px


.post-card-img
    overflow: hidden
    border-radius: 20px
    img
        display: block
        width: 100%
        height: auto

.post-card-header
    time
        display: inline-block
        margin-top: 20px
        font-size: 13px
        color: #929292

.post-card-body
    margin-top: 14px
    h3
        font-weight: 600
        a
            color: #000
            &:hover
                color: $color-base
    h3, p
        &+p
            margin-top: 10px
            -webkit-line-clamp: 6
            -webkit-box-orient: vertical
            display: -webkit-box
            overflow: hidden
            max-height: 162px

.section-blog-item
    .post-card
        // max-width: 414px
        margin-left: auto
        margin-right: auto
        padding-left: 20px
        padding-right: 20px
        @include media-md
            padding-left: 0
            padding-right: 0

.section-blog-carousel-prev,
.section-blog-carousel-next
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 27vw
    margin-top: -20px
    width: 40px
    height: 40px
    border-radius: 50%
    background-color: #E6EFF2
    z-index: 1
    transition: opacity .3s
    @include media-md
        top: 18vw
    &.swiper-button-disabled
        opacity: .5

.section-blog-carousel-prev
    left: 5px
    @include media-md
        left: 0

.section-blog-carousel-next
    right: 5px
    @include media-md
        right: 0

.blog-list-container
    margin: -15px -10px 0
    @include media-lg
        margin: -30px -20px 0
    @include media-xl
        margin: -30px -25px 0

.blog-item
    margin-top: 34px
    padding: 0 10px
    @include media-lg
        padding: 0 20px
        margin-top: 52px
    @include media-xl
        margin-top: 52px
        padding: 0 25px

.blog-list-footer
    margin-top: 34px
    @include media-lg
        margin-top: 58px

.blog-video
    padding-top: 34px
    background-color: transparent
    @include media-lg
        padding-top: 48px

.post
    overflow: hidden
    margin-top: 10px
    padding-bottom: 40px
    @include media-lg
        padding-bottom: 127px

.post-header
    display: flex
    flex-direction: column
    height: 100%
    @include media-lg
        padding: 37px 0 28px
        border-top: 1px dashed rgba( 2, 100, 127, .5 )
        border-bottom: 1px dashed rgba( 2, 100, 127, .5 )
    p
        margin-top: 15px
        @include media-lg
            margin-top: 30px
            font-size: 20px
            line-height: 1.5

.post-header-main
    height: 100%

.post-time
    margin-top: 10px
    font-size: 13px
    color: #929292
    @include media-lg
        margin-top: 34px

.post-img
    margin: 28px -20px 0
    overflow: hidden
    @include media-md
        margin: 28px 0 0
        border-radius: 20px
    @include media-lg
        margin: 0
    img
        width: 100%

.post-main
    margin-top: 30px
    @include media-lg
        margin-top: 40px
    a
        text-decoration: underline
    >*:not(div)
        max-width: 994px
    .alert
        max-width: 994px
    blockquote
        max-width: 612px
    &._full
        >*:not(div)
            max-width:100%
        .alert
            max-width: 100%
        blockquote
            max-width: 100%

.post-autor
    margin-top: 30px
    @include media-lg
        margin-top: 50px

.post-autor-img
    width: 64px
    height: 64px
    border-radius: 50%
    overflow: hidden
    @include media-lg
        width: 90px
        height: 90px
    img
        width: 100%

.post-autor-name
    font-size: 18px
    color: $color-base
    padding-left: 17px
    line-height: 1.33333
    @include media-lg
        font-size: 25px
        padding-left: 24px
    small
        display: block
        font-size: 14px
        color: #000
        @include media-lg
            font-size: 16px

.post-carousel
    position: relative
    @include media-xl
        background-color: #EEF1F4
        padding: 30px 0
        &::before,
        &::after
            content: ""
            display: block
            position: absolute
            top: 0
            bottom: 0
            width: 2000px
            background-color: #EEF1F4
        &::before
            right: 100%
        &::after
            left: 100%

.post-carousel-list
    position: relative
    margin-left: -20px
    margin-right: -20px
    @include media-xl
        margin-left: 0
        margin-right: 0

.post-carousel-slide
    display: flex
    justify-content: center
    padding: 0 10px
    @include media-md
        padding: 20px
    @include media-xl
        padding: 0
    div
        display: flex
        align-items: center
        figure
            display: block
            width: 100%
            overflow: hidden
            border-radius: 20px
            img
                display: block
                max-width: 100%
                max-height: 54.25vw
                @include media-md
                    max-height: 380px
                @include media-lg
                    max-height: 480px

.post-carousel-preview
    margin-top: 15px

.post-carousel-preview-item
    position: relative
    cursor: pointer
    >div
        position: relative
        border-radius: 10px
        overflow: hidden
        &::before
            content: ""
            position: absolute
            display: block
            top: 0
            left: 0
            right: 0
            bottom: 0
            background-color: $color-base
            opacity: 0
            transition: opacity .3s, background-color .3s
    &:hover
        >div
            &::before
                opacity: .6
    &._current
        >div
            &::before
                background-color: #000
                opacity: .6
    img
        max-height: 92px
        width: auto
    &+.post-carousel-preview-item
        margin-left: 8px

.post-media
    padding: 18px 0 22px
    @include media-lg
        padding: 36px 0
    h2
        font-weight: 300
        font-size: 40px
        line-height: 1.125
        color: $color-base
        text-transform: uppercase
        letter-spacing: 0
        @include media-lg
            font-size: 80px
        &+*
            margin-top: 25px

// .post-carousel-prev,
// .post-carousel-next
//     position: absolute
//     display: flex
//     align-items: center
//     justify-content: center
//     top: 50%
//     margin-top: -28px
//     height: 56px
//     padding: 0 20px
//     border-radius: 28px
//     background-color: rgba( 2, 100, 127, .1 )
//     //background-color: rgba( 230, 239, 242, .7 )
//     cursor: pointer
//     z-index: 1
//     transition: background-color .3s
//     svg
//         *
//             fill: #1A1A1A
//             stroke: #1A1A1A
//     &:hover
//         background-color: rgba( 255, 255, 255, .7 )

.post-carousel
    .carousel-prev,
    .carousel-next
        position: absolute
        top: 50%
        margin-top: -28px
    .carousel-prev
        left: 0
    .carousel-next
        right: 0

.post-carousel-pagination
    margin-top: 12px
    line-height: 0
    display: flex
    justify-content: center
    padding: 0 20px
    span
        position: relative
        display: flex
        align-items: center
        justify-content: center
        margin: 0 !important
        background-color: transparent
        opacity: 1
        width: 12px
        height: 12px
        border-radius: 50%
        transition: background-color .3s
        &::before
            content: ""
            display: block
            width: 8px
            height: 8px
            border-radius: 50%
            background-color: #DDE0E2
            transition: background-color .3s
        &+span
            margin-left: 10px !important
        &.swiper-pagination-bullet-active
            background-color: $color-base
            &::before
                background-color: $color-base
