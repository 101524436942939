body.popup-on
    max-height: 100%
    overflow: hidden
    touch-action: none

.scom-popup-list
    display: none

.scom-popup
    overflow: hidden
    visibility: hidden
    opacity: 0
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 9999
    transition: opacity .2s .25s, visibility .2s 25s
    &.center
        .scom-popup-container
            align-items: center
    &.top
        .scom-popup-container
            align-items: flex-start
    &.bottom
        .scom-popup-container
            align-items: flex-end
    &.left
        .scom-popup-container
            justify-content: flex-start
    &.right
        .scom-popup-container
            justify-content: flex-end
    &.visible
        visibility: visible
        opacity: 1
        transition: opacity .2s, visibility .2s
    &._full-mob
        .scom-popup-main
            height: 100%
            width: 100%
            @include media-md
                height: auto
                width: auto
        .popup-main
            height: 100%
            @include media-md
                height: auto
    &._full
        .scom-popup-main
            height: 100%
            width: 100%
        .popup-main
            height: 100%
    &._gallery
        .scom-popup-container
            padding: 0

.scom-popup-darker
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(158, 175, 192, 0.5)
    // background-color: rgba(2, 100, 127, 0.5)


.scom-popup-wrapper
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    overflow: auto

.scom-popup-container
    display: flex
    align-items: center
    justify-content: center
    min-height: 100%
    height: 100%
    overflow: auto
    @include media-md
        padding: 30px

.scom-popup-main
    max-height: 100%
    max-width: 100%
    opacity: 0
    position: relative
    transition: all .25s
    display: flex

.scom-popup.visible .scom-popup-main
    opacity: 1
    transition: all .25s .25s

.scom-popup
    &.center
        .scom-popup-wrapper
            .scom-popup-main
                transform: scale(0.5)
        &.visible
            .scom-popup-wrapper
                .scom-popup-main
                    transform: scale(1)
    &.top
        .scom-popup-wrapper
            padding-top: 0
            overflow: hidden
            .scom-popup-main
                transform: translateY(-100%)
        &.visible
            .scom-popup-wrapper
                .scom-popup-main
                    transform: translateY(0)
    &.bottom
        .scom-popup-wrapper
            padding-bottom: 0
            overflow: hidden
            .scom-popup-main
                transform: translateY(100%)
        &.visible
            .scom-popup-wrapper
                .scom-popup-main
                    transform: translateY(0)
    &.left
        .scom-popup-wrapper
            padding-left: 0
            overflow: hidden
            .scom-popup-main
                transform: translateX(-100%)
        &.visible
            .scom-popup-wrapper
                .scom-popup-main
                    transform: translateX(0)
    &.right
        .scom-popup-wrapper
            padding-right: 0
            overflow: hidden
            .scom-popup-main
                transform: translateX(100%)
        &.visible
            .scom-popup-wrapper
                .scom-popup-main
                    transform: translateX(0)

.scom-popup-close
    position: absolute
    top: 0
    right: 0

.popup-content
    display: flex
    flex-direction: column
    width: 100%
    max-width: 100%
    background-color: #fff
    padding: 0 20px
    height: 100%
    max-height: 100%
    overflow: hidden
    @include media-md
        height: auto
        width: 620px
        border-radius: 20px
    @include media-lg
        padding: 0 40px
    .h2
        &+p
            margin-top: 15px
    p
        &+p
            margin-top: 15px
    .subheading
        margin-top: 15px
        @include media-lg
            margin-top: 5px
        &+p
            margin-top: 15px
    &._to-cart
        @include media-lg
            width: 1040px
            max-width: 100%
        .popup-main
            padding-left: 40px
            padding-right: 40px
            margin-left: -40px
            margin-right: -40px
            background-color: #EFFAFC
    &._small
        @include media-md
            width: 494px

.popup-header
    padding-bottom: 20px

.popup-main
    display: flex
    flex-direction: column
    max-height: 100%
    overflow-y: auto
    // padding-left: 12px
    // padding-right: 12px
    // margin-left: -12px
    // margin-right: -12px
    padding-left: 20px
    padding-right: 20px
    margin-left: -20px
    margin-right: -20px
    @include media-lg
        padding-left: 40px
        padding-right: 40px
        margin-left: -40px
        margin-right: -40px

.popup-main-text
    padding-top: 24px
    @include media-lg
        padding-top: 40px
    h2
        padding-right: 20px

.popup-header-close-container
    position: relative
    height: 20px
    overflow: visible
    @include media-lg
        height: 40px

.popup-header__close
    width: 40px
    height: 40px
    display: block
    position: relative
    top: 4px
    left: calc( 100% - 24px )
    &::before,
    &::after
        content: ""
        display: block
        position: absolute
        top: 50%
        left: 6px
        right: 6px
        height: 1px
        background-color: $color-link
        transition: background-color .3s
    &::before
        transform: rotate(45deg)
    &::after
        transform: rotate(-45deg)
    &:hover
        &::before,
        &::after
            background-color: $color-link-dark
    @include media-lg
        top: 8px
        left: calc( 100% - 8px )
    //     width: 40px
    //     height: 40px
    //     &::before,
    //     &::after
    //         left: 4px
    //         right: 4px
    &._small
        top: 4px
        left: calc( 100% - 12px )
        width: 28px
        height: 28px
        &::before,
        &::after
            left: 4px
            right: 4px
        @include media-lg
            top: 4px
            left: calc( 100% - 8px )
            width: 40px
            height: 40px

.popup-footer
    padding-top: 10px
    padding-bottom: 10px
    @include media-md
        padding-top: 30px
        padding-bottom: 30px
    p
        font-size: 14px
        text-align: center
        @include media-sm
            text-align: left
        @include media-lg
            font-size: 16px
    .btn
        width: 100%
        line-height: 1
        text-align: center
        @include media-sm
            width: auto
            margin-top: 0
        &._large
            height: 40px
            border-radius: 20px
            font-size: 16px
            @include media-lg
                height: 50px
                border-radius: 25px
                font-size: 18px
    &._p-0
        padding-top: 0
        padding-bottom: 0
    .row
        margin-left: -15px
        margin-right: -15px
        >div
            padding-left: 15px
            padding-right: 15px
    .btn
        min-width: 200px
        &._auto
            min-width: 0
    .mt
        &-0
            margin-top: 0 !important
        &-xl-0
            @include media-xl
                margin-top: 0 !important
        &-lg-0
            @include media-lg
                margin-top: 0 !important
        &-md-0
            @include media-md
                margin-top: 0 !important
        &-sm-0
            @include media-sm
                margin-top: 0 !important
    .mt
        &-5
            margin-top: 5px !important
        &-xl-5
            @include media-xl
                margin-top: 5px !important
        &-lg-5
            @include media-lg
                margin-top: 5px !important
        &-md-5
            @include media-md
                margin-top: 5px !important
        &-sm-5
            @include media-sm
                margin-top: 5px !important
    .mt
        &-10
            margin-top: 10px !important
        &-xl-10
            @include media-xl
                margin-top: 10px !important
        &-lg-10
            @include media-lg
                margin-top: 10px !important
        &-md-10
            @include media-md
                margin-top: 10px !important
        &-sm-10
            @include media-sm
                margin-top: 10px !important
    .mt
        &-20
            margin-top: 20px !important
        &-xl-20
            @include media-xl
                margin-top: 20px !important
        &-lg-20
            @include media-lg
                margin-top: 20px !important
        &-md-20
            @include media-md
                margin-top: 20px !important
        &-sm-20
            @include media-sm
                margin-top: 20px !important

.popup-footer-btns
    padding: 20px 0 40px

.popup-close-container
    position: absolute
    top: 0
    right: 0
    z-index: 1

.popup__close
    top: 4px
    right: 4px
    width: 40px
    height: 40px
    display: block
    position: relative
    &::before,
    &::after
        content: ""
        display: block
        position: absolute
        top: 50%
        left: 6px
        right: 6px
        height: 1px
        background-color: #FF6432
        transition: background-color .3s
    &::before
        transform: rotate(45deg)
    &::after
        transform: rotate(-45deg)
    &:hover
        &::before,
        &::after
            background-color: $color-link-dark
    &._small
        top: 4px
        right: 4px
        width: 28px
        height: 28px

.scom-popup._full-mob .popup-info-product-main
    @include media-md
        // max-height: 278px
        max-height: 430px

/*****************************************************
 ** Header Popups ************************************
 *****************************************************/
.scom-popup
    &._header-menu
        top: 44px
        @include media-xl
            top: 70px
        .scom-popup-main
            width: 100%
        .scom-popup-container
            padding: 0
    &._footer-menu
        bottom: 60px

#popup-burger,
#popup-company,
#popup-catalog
    width: 100%
    background-color: #fff
    box-shadow: 0px 20px 20px 0px rgba(2, 100, 127, 0.08)
    padding-bottom: 52px
    overflow: auto
    @include media-lg
        padding-bottom: 74px

.burger-popup-nav
    min-width: 250px
    padding-top: 20px
    @include media-lg
        padding-top: 0
        padding-right: 20px

.burger-popup-nav-item
    a
        display: inline-flex
        justify-content: center
        align-items: center
        height: 32px
        padding: 0 16px
        font-size: 16px
        color: $color-link-dark
        border-radius: 16px
        transition: background-color .3s
        &:hover
            text-decoration: none
            background-color: #ECF5FF
    &+.burger-popup-nav-item
        margin-top: 8px
    @include media-lg
        a
            height: 32px
            font-size: 18px
            border-radius: 18px
        &+.burger-popup-nav-item
            margin-top: 14px

.burger-popup-header
    padding: 7px 0
    a
        display: block
        width: 20px
        height: 20px
        position: relative
        left: calc( 100% - 20px)
        &::before,
        &::after
            content: ""
            display: block
            position: absolute
            top: 50%
            left: 0
            right: 0
            height: 1px
            background-color: #9EAFC0
            transition: background-color .3s
        &::before
            transform: rotate(45deg)
        &::after
            transform: rotate(-45deg)
        &:hover
            &::before,
            &::after
                background-color: $color-link-dark
        @include media-xl
            width: 38px
            height: 38px
            position: relative
            left: calc( 100% - 18px)
            &::before,
            &::after
                background-color: #DDE0E2

.burger-popup-img-wrapper
    position: relative

.burger-popup-img
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    visibility: hidden
    opacity: 0
    overflow: hidden
    width: 100%
    border-radius: 18px
    transition: opacity .2s, visibility .2s
    img
        display: block
        width: 100%
        height: auto
    &._active
        position: relative
        visibility: visible
        opacity: 1

.header-company__btn
    &._active
        color: $color-link-dark

.popup-catalog-col__heading
    margin-left: -14px
    margin-right: -14px
    line-height: 1.2
    a, &>span
        display: flex
        align-items: center
        padding: 0 14px
        @include media-xl
            display: inline-flex
    &>span
        color: #A7C7D1
        font-size:  14px
        height: 40px
        @include media-xl
            color: #000
            font-size: 18px
            height: 34px
    a
        height: 40px
        font-size: 16px
        border-radius: 17px
        text-decoration: none
        color: $color-link-dark
        transition: background-color .3s
        svg
            margin-left: 4px
            position: relative
            top: 1px
        &.bold
            font-weight: 600
        &._active
            background-color: #EBF7F7
        @include media-xl
            height: 34px
            font-size: 18px
            font-weight: 600
        &:hover
            text-decoration: none
            background-color: #ECF5FF
        .sticker
            height: 18px
            padding: 0 6px
            font-size: 9px
            border-radius: 9px
            margin-left: 5px
            @include media-xl
                margin-left: 16px
        .star
            margin-top: 2px
            margin-left: 5px
            padding: 0
            &::before
                content: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z' fill='%23FF7744'/%3E%3C/svg%3E")
                position: relative
            @include media-xl
                margin-top: 1px
                margin-left: 16px

.popup-catalog-row
    padding-top: 10px
    // margin-left: -10px
    // margin-right: -10px
    @include media-xl
        padding-top: 0

.popup-catalog-col
    margin-top: 10px
    // padding-left: 10px
    // padding-right: 10px
    &:first-child
        margin-top: 0
    @include media-xl
        margin-top: 30px
        &:first-child
            margin-top: 30px

.popup-catalog-nav
    @include media-xl
        margin-top: 10px
        margin-left: -14px
        margin-right: -14px

.popup-catalog-nav__item
    padding-top: 10px
    line-height: 1.2
    @include media-xl
        padding-top: 6px
    a
        display: flex
        align-items: center
        border-radius: 17px
        padding: 0 24px
        height: 40px
        font-size: 16px
        color: $color-link-dark
        transition: background-color .3s
        &._active
            background-color: #EBF7F7
        @include media-xl
            display: inline-flex
            justify-content: center
            padding: 0 14px
            height: 34px
            font-size: 14px
        &:hover
            text-decoration: none
            background-color: #ECF5FF
        .sticker
            height: 18px
            padding: 0 6px
            font-size: 9px
            border-radius: 9px
            margin-left: 5px
            @include media-xl
                margin-left: 16px
        .star
            margin-top: 2px
            margin-left: 5px
            &::before
                content: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 15 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.5 0L9.18386 5.18237H14.6329L10.2245 8.38525L11.9084 13.5676L7.5 10.3647L3.09161 13.5676L4.77547 8.38525L0.367076 5.18237H5.81614L7.5 0Z' fill='%23FF7744'/%3E%3C/svg%3E")
            @include media-xl
                margin-top: 0
                margin-left: 16px

.popup-catalog-footer__btn
    height: 44px
    width: 100%
    display: flex
    align-items: center
    background-color: transparent
    color: $color-base
    text-align: center
    font-size: 14px
    letter-spacing: .05em
    text-decoration: none
    text-transform: uppercase
    border-radius: 22px
    transition: background-color .3s, color .3s
    line-height: 1.2
    border: 1px solid $color-base
    padding: 0 20px
    margin-top: 20px
    &:hover
        background-color: #28A0A0
        color: #fff
        text-decoration: none
    &:active
        background-color: #1A848B
    @include media-xl
        color: #fff
        height: 106px
        font-size: 24px
        background-color: $color-base
        border-radius: 10px
        justify-content: center

.popup-catalog-footer
    padding-bottom: 56px
    margin-top: 24px
    @include media-xl
        padding-bottom: 0
        margin-top: 60px

.lng-country-input
    padding-bottom: 10px
    input
        width: 100%
        height: 40px
        border-radius: 20px
    @include media-lg
        margin-top: 4px
        padding-bottom: 20px
        border-bottom: 1px solid #DADADA
        margin-bottom: 10px

.lng-country-list
    height: 100%
    overflow: auto
    padding-left: 12px
    padding-right: 12px
    margin-left: -12px
    margin-right: -12px
    @include media-md
        max-height: 322px
    @include media-lg
        padding-left: 20px
        padding-right: 20px
        margin-left: -20px
        margin-right: -20px

.lng-country-item__name,
.lng-country-translate
    display: flex
    align-items: cenetr

.lng-country-item
    cursor: pointer
    display: block
    border-radius: 14px
    padding-bottom: 12px
    @include media-lg
        display: flex
    .lng-country-item__name
        width: 100%
        height: 52px
        display: inline-flex
        align-items: center
        font-size: 18px
        padding: 0 20px
        border-radius: 14px
        transition: background-color .3s
        @include media-lg
            width: 262px
        .country-name
            display: block
            position: relative
            top: 2px
            margin-left: 20px
    .lng-country-translate
        padding-left: 72px
        @include media-lg
            padding-left: 40px
    &:hover
        background-color: #ECF5FF
        .lng-country-item__name
            background-color: #ECF5FF
        @include media-lg
            background-color: transparent
    &._active
        background-color: #EEF1F4
        .lng-country-item__name
            background-color: #EEF1F4
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='13' viewBox='0 0 17 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.5' d='M6.26585 12.9999C5.7289 13.0044 5.2818 12.7847 4.90862 12.3985C3.46733 10.9085 2.02604 9.41844 0.590547 7.9224C-0.135533 7.1665 -0.194952 6.1264 0.428229 5.31577C1.00938 4.55837 2.1485 4.40239 2.89849 4.98056C3.01878 5.07355 3.13327 5.17629 3.23979 5.28577C3.79921 5.86244 4.35427 6.44361 4.91296 7.02103C5.5789 7.71018 6.40135 7.84216 7.22164 7.37348C7.397 7.27299 7.55134 7.12226 7.69482 6.97303C9.72668 4.86808 11.7556 2.76013 13.7832 0.650674C14.2288 0.187239 14.7469 -0.0519772 15.3904 0.00951411C16.1549 0.0830037 16.8788 0.805902 16.9802 1.59254C17.0672 2.2697 16.8657 2.83362 16.4078 3.3068C13.4803 6.33712 10.552 9.36669 7.62308 12.3963C7.24772 12.7847 6.7999 12.9977 6.26585 12.9999Z' fill='%2302647F'/%3E%3C/svg%3E")
            background-repeat: no-repeat
            background-position: calc(100% - 20px) 50%
        @include media-lg
            background-color: transparent

.lng-country-translate__item
    display: flex
    align-items: center
    justify-content: center
    width: 52px
    height: 52px
    background-color: #fff
    border-radius: 14px
    border: 1px solid #DDE0E2
    transition: background-color .3s, border-color .3s
    &:hover
        border-color: $color-base
    &._active
        background-color: #ECF5FF
        border-color: $color-base
    &+.lng-country-translate__item
        margin-left: 10px

.lng-country-btn
    padding: 10px 0
    text-align: center
    .btn
        min-width: 100%
    @include media-md
        border-top: 1px solid #DADADA
        padding: 20px 0 40px
        .btn
            min-width: 166px

#popup-burger-mob,
#popup-catalog-mob
    width: 100%
    height: 100%
    border-radius: 0
    padding-left: 0
    padding-right: 0
    @include media-md
        width: 560px

.burger-mob-section
    &+.burger-mob-section
        border-top: 1px solid #DDE0E2

.burger-mob-tel
    display: inline-flex
    align-items: center
    padding: 20px 0 38px
    a
        display: inline-flex
        align-items: center
    .burger-mob-tel__ico
        width: 40px
        height: 40px
        border-radius: 50%
        border: 1px solid $color-base
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='19' viewBox='0 0 14 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.3' d='M0.00626672 4.35697C0.0114678 3.73639 0.16555 2.81444 0.44966 1.91742C0.471115 1.84973 0.514024 1.77919 0.565384 1.73502C1.09655 1.2776 1.63096 0.824462 2.16667 0.372746C2.22518 0.323585 2.29605 0.288673 2.36561 0.260173C2.47679 0.213862 2.59446 0.1875 2.70368 0.136913C3.27646 -0.125994 3.60412 -0.0255336 4.08263 0.57153C4.71391 1.35883 5.08969 2.29005 5.30618 3.30249C5.40435 3.75991 5.46352 4.22373 5.37705 4.69326C5.33024 4.94905 5.22882 5.1692 5.04093 5.34376C4.60403 5.74988 4.1834 6.17737 3.74065 6.57494C3.45134 6.835 3.32652 7.1663 3.37918 7.54748C3.43899 7.97711 3.51181 8.41957 3.66394 8.81713C4.35243 10.6105 5.34129 12.1801 6.68382 13.4654C6.98159 13.7504 7.30795 13.9941 7.69153 14.1301C7.95354 14.2228 8.19734 14.1957 8.43919 14.0454C8.92354 13.7447 9.42285 13.4725 9.8981 13.1562C10.2732 12.9061 10.6445 12.9154 11.0313 13.0721C11.7081 13.3471 12.2717 13.8145 12.7867 14.3532C13.1709 14.755 13.5207 15.1939 13.7599 15.7197C13.8555 15.9292 13.9211 16.1572 13.9784 16.3837C14.0401 16.6274 13.9628 16.8454 13.834 17.0506C13.5545 17.4966 13.2086 17.8529 12.773 18.1186C12.3673 18.3652 11.9863 18.6601 11.5898 18.9259C11.5208 18.9722 11.4305 19.0007 11.3499 19C10.0398 18.9836 8.80717 18.6131 7.64017 17.9804C5.83474 17.0015 4.35243 15.5879 3.10547 13.8665C1.73498 11.9756 0.757825 9.86734 0.25982 7.50758C0.064779 6.58278 -0.02559 5.63944 0.00626672 4.35697Z' fill='%2302647F'/%3E%3C/svg%3E")
        background-repeat: no-repeat
        background-position: 50% 50%
        margin-right: 10px
    .burger-mob-tel__msg
        opacity: .3
        margin-left: 10px

.burger-mob-nav
    padding-top: 3px
    padding-bottom: 27px

.burger-mob-nav__item
    padding-top: 10px
    a
        display: inline-flex
        justify-content: center
        align-items: center
        height: 40px
        padding: 0 20px
        font-size: 18px
        color: $color-link-dark
        transition: backgroun-color .3s
        border-radius: 20px
        &:hover
            background-color: #ECF5FF
            text-decoration: none
    &._active
        a
            background-color: #ECF8F8

#popup-cart-mob
    .popup-main
        margin-left: -20px
        margin-right: -20px
        padding-left: 20px
        padding-right: 20px
        padding-bottom: 28px
        @include media-lg
            margin-left: -40px
            margin-right: -40px
            padding-left: 40px
            padding-right: 40px
    @include media-md
        width: 375px

.popup-cart-section
    margin-top: 10px
    margin-left: -10px
    margin-right: -10px
    border-radius: 15px
    padding: 0 10px
    background-color: #fff
    box-shadow: 0px 1px 10px rgba(2, 100, 127, 0.12)
    &+.popup-cart-section
        margin-top: 20px

.popup-cart-item
    padding: 5px 0
    &+.popup-cart-item
        border-top: 1px solid #DDE0E2
    &._active
        .popup-cart-check
            div
                &::before
                    content: url("data:image/svg+xml,%3Csvg width='19' height='14' viewBox='0 0 19 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.77027e-05 6.95389C-0.00724414 6.01292 0.822406 5.09538 1.82382 4.99918C2.50763 4.9337 3.07964 5.1544 3.55928 5.63297C4.20744 6.27888 4.85399 6.92721 5.50377 7.57151C6.24025 8.30311 7.16064 8.44054 8.06969 7.93852C8.26009 7.83343 8.4278 7.67579 8.58336 7.52058C10.8544 5.2514 13.1229 2.9798 15.3883 0.705779C15.9092 0.182745 16.5185 -0.0929188 17.2639 0.028341C18.0117 0.150409 18.5594 0.570776 18.8381 1.26842C19.1241 1.98386 19.0342 2.67908 18.544 3.29751C18.4403 3.42847 18.3269 3.55458 18.2086 3.67341C14.9937 6.88275 11.7772 10.0905 8.56229 13.299C7.92466 13.9352 7.1736 14.1737 6.31479 13.8673C6.00934 13.7582 5.7128 13.5537 5.48027 13.3241C3.86473 11.7323 2.267 10.122 0.661985 8.51976C0.24797 8.10667 0.0203028 7.61516 4.77027e-05 6.95389Z' fill='%2338B1B1'/%3E%3C/svg%3E")
                    position: relative
                    top: -2px
                    left: 4px

.popup-cart-check
    display: flex
    align-items: center
    div
        width: 24px
        height: 24px
        border: 1px solid #DDE0E2
        border-radius: 50%
        position: relative

.popup-cart-btn
    padding-top: 6px
    padding-bottom: 10px
    display: flex
    //justify-content: flex-end
    .btn
        width: 100%
        // @include media-md
        //     max-width: 240px

.popup-form
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="password"]
        width: 100%
        @include media-md
            width: 290px
        &._full
            @include media-md
                width: 100%
    .input-link
        display: inline-block
        font-size: 14px
        text-decoration: underline
        margin-top: 20px
        margin-left: 16px
        @include media-md
            margin-top: 0
            margin-left: 20px
    .popup-main
        padding-top: 20px
        padding-bottom: 10px
        @include media-lg
            padding-top: 40px

.popup-form-btns
    padding: 20px 0 38px

.popup-video
    position: relative
    max-height: 100%
    max-width: 100%
    flex: 0 0 auto
    margin-bottom: 40px
    img
        width: auto
        height: auto
        max-width: 100%
        max-height: 100%

.popup-video-container
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    iframe
        width: 100%
        height: 100%

#popup-video
    height: auto
    width: 620px

.popup-gallery
    display: flex
    .popup-main
        max-height: 100%
        display: flex
        justify-content: center
        padding: 0
        margin: 0
    picture
        display: block
        max-height: 100%
        overflow: hidden
        border-radius: 20px
    img
        display: block
        // width: 100%
        // height: auto
        max-width: 100%
        max-height: 100%
        margin-left: auto
        margin-right: auto
        border-radius: 20px
    .popup-header__close
        top: -38px
        left: -22px
        &::after,
        &::before
            background-color: #fff
        @include media-lg
            top: -40px
            left: 0
    .popup-gallery-container
        overflow: hidden

.popup-gallery-container
    position: relative
    display: flex
    justify-content: center
    max-height: 100%
    width: 100%
    padding: 32px 10px 10px
    @include media-lg
        padding: 40px
    @include media-xl
        padding: 40px 50px 50px

.popup-gallery-close
    width: 0px
    overflow: visible

.popup-left-menu
    @include media-md
        width: 560px
        height: 100%
        border-radius: 0

.popup-your-geo
    p
        margin-top: 37px !important
        padding-bottom: 26px
        img
            display: inline-block
            margin-right: 10px
    .btn
        @include media-sm
            min-width: 162px !important

.popup-main
    scrollbar-width: thin
    scrollbar-color: #FF6432 #EFFAFC

.popup-main::-webkit-scrollbar
    height: 3px
    width: 3px

.popup-main::-webkit-scrollbar-track
    background: #EFFAFC

.popup-main::-webkit-scrollbar-thumb
    background-color: #FF6432
    border-radius: 2px

