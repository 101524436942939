/*==============  Programs  ==============*/

.cart
    padding-top: 13px
    padding-bottom: 20px
    @include media-lg
        padding-top: 32px
        padding-bottom: 60px

.cart-main
    margin-left: -10px
    margin-right: -10px
    >div
        padding-left: 10px
        padding-right: 10px

.cart-points
    display: flex
    margin-left: -10px
    margin-right: -10px
    @include media-md
        margin-left: 0
        margin-right: 0
    >a
        position: relative
        display: block
        width: 50%
        min-height: 100%
        max-width: 156px
        border: 1px solid #D8D8D8
        border-radius: 10px
        padding: 10px
        font-size: 10px
        color: #000
        transition: background-color .3s, border-color .3s, color .3s
        @include media-lg
            max-width: 263px
            border-radius: 20px
            padding: 20px
            font-size: 16px
        &+a
            margin-left: 10px
            @include media-lg
                margin-left: 20px
        .cart-points-heading
            color: $color-base
            display: flex
            align-items: center
            justify-content: space-between
            font-size: 14px
            font-weight: 700
            transition: color .3s
            @include media-lg
                font-size: 18px
        &:hover
            text-decoration: none
            background-color: #ECF5FF
            border-color: $color-base
            color: #000
            .cart-points-heading
                color: #000
        &:active,
        &._active
            background-color: $color-base
            border: 1px solid transparent
            color: #fff
            .cart-points-heading
                color: #fff
            .cart-points-sticker
                background-color: #fff
                color: $color-base

.cart-points-text
    display: block

.cart-points-sticker
    position: absolute
    right: -1px
    bottom: -1px
    height: 20px
    border-radius: 10px 0 10px
    padding: 0 8px
    display: flex
    align-items: center
    justify-content: center
    background-color: $color-base
    color: #fff
    font-size: 10px
    font-weight: 400
    transition: background-color .3s, color .3s
    line-height: 1
    @include media-lg
        top: 24px
        right: 20px
        bottom: auto
        border-radius: 10px
        padding: 0 10px
        font-size: 12px

.cart-delivery-address
    position: relative
    padding-left: 22px
    font-size: 14px
    margin-top: 17px
    line-height: 1.4
    @include media-md
        margin-left: 10px
    @include media-lg
        margin-left: 24px
        padding-left: 24px
        font-size: 16px
    a
        text-decoration: underline
    svg
        position: absolute
        top: 2px
        left: 0
        display: inline-block
        width: 12px
        height: auto
        @include media-lg
            width: 14px

.cart-message
    margin-top: 20px
    &+.cart-message
        margin-top: 10px
    &+.cart-list
        margin-top: 10px
    @include media-lg
        &+.cart-message
            margin-top: 20px
        &+.cart-list
            margin-top: 20px

.cart-right-side
    @include media-md
        width: 330px
        height: 100%

.cart-list
    margin-left: -10px
    margin-right: -10px
    @include media-md
        margin-left: 0
        margin-right: 0
    &+.cart-list
        margin-top: 10px
    &._order-list
        .cart-item-row
            &:last-child
                padding-bottom: 20px
                @include media-lg
                    padding-bottom: 28px

.cart-item
    // border-radius: 20px
    // .row
    //     margin-left: -7px
    //     margin-right: -7px
    //     @include media-lg
    //         margin-left: -12px
    //         margin-right: -12px
    //     >div
    //         padding-left: 7px
    //         padding-right: 7px
    //         @include media-lg
    //             padding-left: 12px
    //             padding-right: 12px
    &._empty
        .cart-item-main
            // @include media-lg
            //     border-bottom: 1px solid transparent
            //     border-left: 1px solid transparent
            //     border-right: 1px solid transparent
            // @include media-xl
            //     border-radius: 0 0 20px 20px
        .cart-item-header
            @include media-xl
                border-top: 1px solid transparent
                border-left: 1px solid transparent
                border-right: 1px solid transparent
    &._active
        &._empty
            .cart-item-header
                @include media-xl
                    border-top: 1px solid #DADADA
                    border-left: 1px solid #DADADA
                    border-right: 1px solid #DADADA
            // .cart-item-main
            //     @include media-xl
            //         border-bottom: 1px solid #DADADA
            //         border-left: 1px solid #DADADA
            //         border-right: 1px solid #DADADA

.cart-item-header
    padding-left: 14px
    padding-right: 14px
    border-radius: 20px 20px 0 0
    background-color: #F0F2F5
    @include media-lg
        padding-left: 20px
        padding-right: 20px
    >.row
        min-height: 42px
        margin-left: -7px
        margin-right: -7px
        @include media-lg
            margin-left: -12px
            margin-right: -12px
            height: 60px
        >div
            padding-left: 7px
            padding-right: 7px
            @include media-lg
                padding-left: 12px
                padding-right: 12px
    .cart-btn-remove
        &::before
            background-color: #fff

.cart-item
    &._active
        .cart-item-header
            .cart-item-ico
                svg
                    transform: rotate3d( 0, 0, 0, 0 )

.cart-item-ico
    svg
        display: block
        margin-left: auto
        margin-right: auto
        transform: rotate3d( 1, 0 , 0, 180deg )
        transition: transform .3s
    @include media-lg
        width: 70px

.cart-item-heading
    line-height: 1.2
    font-size: 12px
    font-weight: 700
    white-space: nowrap
    margin-right: 10px
    @include media-sm
        font-size: 16px

.cart-item-client
    line-height: 1.2
    font-size: 12px
    color: #55687B
    @include media-sm
        font-size: 14px

.cart-item-stock
    position: relative
    line-height: 1.2
    display: flex
    white-space: nowrap
    a
        display: inline-flex
        align-items: center
        font-size: 12px
        color: $color-accent
        text-decoration: none
        letter-spacing: .02em
        position: relative
        @include media-sm
            font-size: 12px
        span
            &:first-child
                display: block
                text-decoration: underline
            &:last-child
                display: flex
                align-items: center
                justify-content: center
                width: 16px
                height: 16px
                border-radius: 50%
                background-color: $color-accent
                color: #fff
                font-weight: 700
                margin-left: 6px
                @include media-sm
                    width: 20px
                    height: 20px

.cart-item-stock__bubble
    position: absolute
    left: 50%
    bottom: calc(100% - 24px)
    margin-left: -58px
    opacity: 0
    visibility: hidden
    transform: translateY( 20px )
    transition: opacity .15s,  visibility .15s, transform .15s
    overflow: visible
    padding: 24px
    svg
        display: block
        overflow: visible
        filter: drop-shadow( 0px 1px 24px rgba(43, 148, 148, 0.2) )
    picture
        display: block
        position: absolute
        top: 5px
        left:  7px
        right: 7px
        bottom: 17px
        display: flex
        align-items: center
        justify-content: center
        img
            display: block
            width: auto
            height: auto
            max-width: 100%
            max-height: 100%

.cart-item-stock
    &._open
        .cart-item-stock__bubble
            opacity: 1
            visibility: visible
            transform: translateY( 0 )
    &._viewed
        .cart-item-stock__bubble
            opacity: 0
            visibility: hidden
            transform: translateY( 20px )

.prompt-input
    position: relative

.prompt-input-container
    display: none
    position: absolute
    top: 100%
    left: 0
    right: 0
    border: 1px solid $color-base
    border-radius: 20px
    padding-bottom: 17px
    background-color: #fff
    z-index: 1
    overflow: hidden
    &._active
        display: block

.prompt-input-item
    padding: 7px 20px 5px
    line-height: 1.25
    transition: background-color .3s, color .3s
    cursor: pointer
    small
        font-size: 12px
    svg
        *
            transition: fill .3s
    &:hover,
    &._current
        background-color: $color-base
        color: #fff
        svg
            *
                fill: #fff

.cart-partner-main
    .prompt-input-item
        svg
            position: relative
            top: -2px
            display: inline-block
            margin-right: 5px

// .cart-lot
//     padding: 14px
//     margin-top: 4px
//     border: 1px solid #DADADA
//     border-radius: 7px
//     @include media-lg
//         margin-top: 8px
//         padding-left: 20px
//         padding-right: 20px
//     @include media-xl
//         margin-top: 0
//         border: none
//         border-radius: 0
//     &._danger
//         background-color: #FFF1EC
//         border-color: transparent
//         .cart-lot-name
//             a
//                 &+span
//                     color: $color-danger
//     &._stock
//         border: none
//         border-radius: 0
//         border-bottom: 1px solid rgba( 255, 119, 68, .4 )
//         &:last-child
//             border-bottom: none
//         @include media-xl
//             border-top: 1px solid rgba( 255, 119, 68, .4 )
//             border-bottom: none
//     >.row
//         >div
//             &+div
//                 margin-top: 12px
//                 @include media-xl
//                     margin-top: 0

// .cart-lot-img
//     display: flex
//     align-items: center
//     justify-content: center
//     width: 70px
//     height: 70px
//     picture
//         display: block
//         overflow: hidden
//         border-radius: 8px
//         border: 1px solid #DADADA
//         img
//             display: block
//             width: 100%

// .cart-lot-bottom
//     >.row
//         margin-left: 0
//         margin-right: 0
//         >div
//             padding-left: 0
//             padding-right: 0

// .cart-lot-empty
//     font-size: 18px
//     line-height: 1.2
//     color: #9EAFC0
//     @include media-lg
//         font-size: 27px
//     &+div
//         line-height: 1.2
//         a
//             color: $color-base
//             text-decoration: underline

// .cart-lot-mix
//     @include media-xl
//         width: 100px
//         overflow: visible

// .cart-lot-mix-label
//     a
//         color: $color-base !important
//         font-size: 12px !important
//         text-decoration: none !important

// .cart-lot-mix-content
//     font-size: 12px
//     @include media-lg
//         font-size: 14px
//     @include media-xl
//         width: 470px
//     @include media-xxl
//         width: 515px

// .cart-lot-name
//     min-height: 70px
//     position: relative
//     padding-right: 20px
//     font-size: 12px
//     @include media-xl
//         display: flex
//         align-items: center
//         // height: auto
//         width: 224px
//         padding-right: 0
//     @include media-xxl
//         width: 330px
//     a
//         display: block
//         color: #000
//         line-height: 1.25
//         @include media-lg
//             font-size: 16px
//         &:hover
//             color: $color-base
//             text-decoration: underline
//     .cart-lot-remove
//         position: absolute
//         top: 2px
//         right: 0

// .cart-lot-time
//     display: block
//     font-weight: 600
//     @include media-lg
//         font-size: 14px

// .cart-lot-sub
//     display: block
//     font-size: 12px
//     color: #929292
//     line-height: 1.2

// .cart-lot-rate
//     width: 76px
//     line-height: 1.5
//     @include media-lg
//         width: 54px

// .cart-lot-rate-count
//     font-size: 10px
//     color: #7A7A7A
//     white-space: nowrap

// .cart-lot-rate-rub
//     font-size: 12px
//     color: #7A7A7A
//     white-space: nowrap
//     @include media-lg
//         color: #0F0F0F

// .cart-lot-rate-bb
//     font-size: 10px
//     color: $color-base
//     white-space: nowrap

// .cart-lot-quantity
//     .quantity-input-field
//         padding: 2px 3px 0
//         input
//             padding: 0
//             width: 40px
//             height: 25px
//             border-radius: 12px
//             background-color: #fff
//             text-align: center
//             line-height: 25px
//     .quantity-input-minus,
//     .quantity-input-plus
//         display: flex
//         align-items: center
//         justify-content: center
//         position: relative
//         width: 29px
//         height: 29px
//         background-color: #F0F2F5
//         border-radius: 50%
//         border: 2px solid #fff
//         cursor: pointer
//         transition: background-color .3s
//         &:hover
//             background-color: #C7EEEE
//         &::before
//             content: ""
//             display: block
//             position: absolute
//             top: 12px
//             left: 8px
//             height: 1px
//             width: 9px
//             background-color: #000
//     .quantity-input-plus
//         &::after
//             content: ""
//             display: block
//             position: relative
//             height: 1px
//             width: 9px
//             background-color: #000
//             transform: rotate( 90deg )

// .cart-lot-price
//     display: flex
//     align-items: center
//     justify-content: flex-end
//     width: 74px
//     position: relative
//     white-space: nowrap
//     line-height: 1.2
//     text-align: right
//     @include media-lg
//         width: 93px
//     @include media-xl
//         justify-content: flex-start
//         min-height: 70px
//         text-align: left

// .cart-lot-price-old
//     position: absolute
//     right: 0
//     bottom: 100%
//     font-size: 14px
//     color: #929292
//     text-decoration: line-through
//     @include media-lg
//         position: relative
//         right: auto
//         bottom: auto

// .cart-lot-price-rub
//     font-weight: 600
//     @include media-lg
//         font-size: 20px
//     small
//         font-size: 13px
//         color: #7A7A7A
//         font-weight: 400

// .cart-lot-price-bb
//     font-size: 14px
//     font-weight: 600
//     color: $color-base



// .cart-lot-remove
//     display: block
//     svg
//         display: block

// .cart-lot
//     &._order
//         margin-top: 0
//         padding-top: 5px
//         padding-bottom: 5px
//         border: none
//         @include media-lg
//             padding-top: 10px
//             padding-bottom: 10px
//         .cart-lot-name
//             min-height: 0
//             font-size: 12px
//             @include media-lg
//                 font-size: 16px
//             @include media-xl
//                 width: 320px
//                 min-height: 50px
//             @include media-xxl
//                 width: 430px
//         .cart-lot-price
//             min-height: 0
//             width: auto
//             @include media-xl
//                 min-height: 50px
//             @include media-xxl

//         &>.row
//             &>div
//                 &+div
//                     margin-top: 0

.cart-list
    &._stock-list
        margin-top: 18px
        border: 2px solid #FF7744
        border-radius: 7px
        overflow: hidden
        .cart-item-row
            margin-top: 0
            padding: 14px 12px
            border-bottom: 1px solid #DADADA
            @include media-lg
                padding-left: 18px
                padding-right: 18px
            &:last-child
                border-bottom: none
            &._success
                background-color: #EFFAFC       
        .cart-item-img
            picture
                border: none
        .cart-item-name
            font-size: 14px
            .h3
                font-size: 16px
                font-weight: 700
                color: #000
                @include media-lg
                    font-size: 18px
    &._order-list
        .cart-item-header
            @include media-lg
                padding-left: 30px
                padding-right: 30px
        .cart-item-ico
            @include media-lg
                width: auto
                padding-left: 0
        .cart-item-row
            margin-top: 8px
            padding-top: 4px
            padding-bottom: 0
            @include media-lg
                margin-top: 0
                padding-top: 10px
                padding-bottom: 10px
                padding-left: 30px
                padding-right: 30px
            &._order-stock
                border-top: 1px solid rgba(255, 119, 68, 0.4)
        .cart-item-col
            margin-top: 0
            @include media-lg
                margin-top: 12px
            @include media-xl
                min-height: 50px
                margin-top: 0
        .cart-item-text
            margin-top: 0 !important
            padding-left: 0 !important
            padding-left: 0 !important
            width: 100%
            @include media-xl
                flex: 1 0 0%
                width: auto
        .cart-item-price
            @include media-xxl
                width: 12%
            picture
                width: 36px
                max-height: 36px
                display: block
                @include media-lg
                    width: 50px
                    max-height: 50px
                img
                    display: block
                    width: 100%
                    height: auto
        .cart-item-count
            @include media-xl
                align-items: center
                width: 7%
            @include media-xxl
                width: 9%
        .cart-item-bb
            @include media-xl
                align-items: center
                width: 10%
            @include media-xxl
                width: 8%
        .cart-item-total
            @include media-xl
                align-items: center
                width: 16%
                justify-content: flex-end
                text-align: right
            @include media-xxl
                width: 16%
        .cart-item-total-rub
            small
                font-size: 13px
                font-weight: 400
                color: #7A7A7A
                @include media-lg
                    font-size: 16px

.cart-item-placeholder
    padding: 14px 14px 4px
    line-height: 1.4
    @include media-lg
        padding: 15px 30px 4px

.cart-item-placeholder-id
    font-size: 18px
    @include media-lg
        font-size: 16px

.cart-item-placeholder-name
    font-size: 14px
    color: #55687B

.cart-item-row
    position: relative
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    padding: 14px
    @include media-lg
        padding-left: 20px
        padding-right: 20px
    @include media-xl
        align-items: flex-start
    &._bordered
        margin-top: 8px
        border: 1px solid #DADADA
        border-radius: 7px
        @include media-xl
            border: none
    &._danger
        margin-top: 8px
        background-color: #FFF1EC
        border-radius: 7px
        .cart-item-time
            color: $color-danger
    &._stock
        margin-top: 8px
        border-radius: 0
        border-bottom: 1px solid rgba(255, 119, 68, 0.4)
        @include media-xl
            border-top: 1px solid rgba(255, 119, 68, 0.4)
            border-bottom: none
    &._empty
        justify-content: flex-start
        @include media-xl
            border-bottom: 1px solid #DADADA
            border-left: 1px solid #DADADA
            border-right: 1px solid #DADADA
            border-radius: 0 0 20px 20px
            margin-top: 0
        .cart-item-img
            width: auto
            height: auto
            @include media-lg
                width: 70px
                height: 70px
            picture
                border: none
                svg
                    width: 30px
                    height: auto
                    @include media-lg
                        width: 54px
        .cart-item-text
            padding-right: 0
            flex: 1 0 0%
            width: 100%
        .cart-item-name
            .h3
                font-size: 18px
                line-height: 1.2
                color: #9EAFC0
                @include media-lg
                    font-size: 27px
                &+div
                    a
                        text-decoration: underline

.cart-item-col
    display: flex
    align-items: center
    width: 100%
    max-width: 100%
    @include media-xl
        min-height: 70px

.cart-item-img
    justify-content: center
    flex: 0 0 auto
    width: auto
    height: 70px
    width: 70px
    picture
        display: block
        overflow: hidden
        border-radius: 8px
        border: 1px solid #DADADA
        img
            display: block
            width: 100%
        svg
            display: block

.cart-item-text
    align-items: start
    flex: 0 0 auto
    width: calc(100% - 72px)
    padding-left: 14px
    padding-right: 36px
    @include media-lg
        padding-left: 24px
    @include media-xl
        align-items: center
        flex: 1 0 0%
        width: auto
        padding-right: 26px

.cart-item-price
    flex: 0 0 auto
    width: 72px
    line-height: 1.5
    align-items: flex-end
    margin-top: 12px
    @include media-xl
        align-items: center
        width: 13%
        margin-top: 0

.cart-item-count
    // align-items: flex-end
    flex: 1 0 0%
    width: auto
    line-height: 1.2
    padding-left: 24px
    @include media-xl
        padding-left: 0
        flex: 0 0 auto
    // @include media-xl
    //     align-items: flex-center

.cart-item-input
    flex: 0 0 auto
    width: auto
    align-items: flex-end
    margin-top: 12px
    @include media-xl
        align-items: center
        width: 18%
        margin-top: 0

.cart-item-total
    position: relative
    flex: 0 0 auto
    width: 72px
    line-height: 1.2
    align-items: flex-end
    justify-content: flex-end
    margin-top: 12px
    text-align: right
    @include media-xl
        width: 16%
        align-items: center
        justify-content: flex-start
        margin-top: 0
        text-align: left

.cart-item-remove
    position: absolute
    top: 14px
    right: 20px
    min-height: 0
    flex: 0 0 auto
    width: auto
    @include media-xl
        position: relative
        top: 0
        right: auto
        min-height: 70px

.cart-btn-remove
    display: block
    position: relative
    svg
        position: relative
        display: block
    &::before
        content: ""
        display: block
        position: absolute
        top: -6px
        left: -6px
        right: -6px
        bottom: -6px
        background-color: #EEF1F4
        border-radius: 6px
        opacity: 0
        transition: opacity .3s
    &:hover
       &::before
           opacity: .5
    &:active
       &::before
           opacity: 1

.cart-item-cat
    flex: 0 0 auto
    width: auto
    align-items: flex-end

.cart-item-date
    width: 70px
    font-size: 11px
    color: #7A7A7A
    white-space: nowrap
    @include media-lg
        font-size: 14px
    @include media-xl
        width: auto
    &._color-danger
        color: $color-danger

.cart-item-btn
    position: relative
    flex: 0 0 auto
    width: calc(100% - 70px)
    padding-left: 14px
    align-items: flex-end
    margin-top: 12px
    @include media-lg
        padding-left: 24px
    @include media-xl
        width: calc( 16% + 16px)
        align-items: center
        margin-top: 0
        padding-left: 0
    >div
        width: 100%
    .btn
        width: 100%
        &._invert
            background-color: #fff
            &:hover
                background-color: #28A0A0
            &:active
                background-color: #1A848B

.cart-item-name
    >a
        display: block
        font-size: 12px
        color: #000
        @include media-lg
            font-size: 16px
            line-height: 1.25
        &:hover
            color: $color-base
            text-decoration: underline

.cart-list._order-list
    .cart-item-name
        >a
            @include media-sm
                font-size: 14px
            @include media-lg
                font-size: 16px

.cart-item-info
    font-size: 12px
    @include media-lg
        font-size: 14px
    a
        text-decoration: underline
    &._large
        @include media-lg
            font-size: 16px

.cart-item-sub
    font-size: 12px
    color: #929292
    line-height: 1.2

.cart-item-time
    font-size: 12px
    font-weight: 600
    @include media-lg
        display: flex
        font-size: 14px
        white-space: nowrap
        .cart-item-mix
            padding-left: 18px

.cart-item-mix
    font-weight: 400
    white-space: normal

.cart-item-mix-label
    span
        color: #FF6432 !important
        font-size: 12px !important
        text-decoration: none !important

.cart-item-mix-content
    font-size: 12px
    @include media-lg
        font-size: 14px

.cart-item-price-count
    font-size: 10px
    color: #7A7A7A
    white-space: nowrap

.cart-item-price-rub
    font-size: 12px
    color: #7A7A7A
    white-space: nowrap
    @include media-lg
        color: #0F0F0F

.cart-item-price-bb
    font-size: 10px
    color: #FF6432
    white-space: nowrap

.cart-item-total-rub
    font-weight: 600
    white-space: nowrap
    @include media-lg
        font-size: 20px

.cart-item-total-bb
    font-size: 14px
    font-weight: 600
    color: #FF6432
    white-space: nowrap

.cart-item-total-old
    display: flex
    align-items: center
    position: absolute
    right: 0
    bottom: 100%
    white-space: nowrap
    @include media-lg
        position: relative
        right: auto
        bottom: auto
    .sticker
        margin-left: 7px

.cart-item-total-old__val
    font-size: 14px
    color: #929292
    text-decoration: line-through

.cart-item-empty
    margin-top: 4px
    padding-top: 17px
    padding-bottom: 17px
    border: 1px solid #DADADA
    border-radius: 7px
    .cart-lot-img
        width: auto
        height: auto
        @include media-lg
            width: 70px
            height: 70px
        svg
            width: 30px
            height: auto
            @include media-lg
                width: 54px
    &>.row
        &>div
            &+div
                margin-top: 0

.cart-stock
    padding-top: 16px
    padding-left: 10px
    padding-right: 10px
    @include media-md
        padding-left: 0
        padding-right: 0
    .h3
        font-size: 18px
        line-height: 1.4
        font-weight: 600
        color: $color-accent
    &+.cart-list
        margin-top: 10px

.cart-item-delivery
    padding: 28px 14px 14px
    @include media-md
        padding: 28px 14px 14px
    @include media-lg
        padding: 20px 30px 24px
    .row
        margin-left: -10px
        margin-right: -10px
        >div
            padding-left: 10px
            padding-right: 10px
    .delivery-company-info
        margin-top: 10px
        @include media-lg
            margin-top: 20px

.cart-item-delivery-info
    font-size: 14px
    padding-top: 22px
    @include media-lg
        padding-top: 0
        font-size: 16px
    .float-help
        position: relative
        top: -2px
        left: 4px

.cart-item-pay
    .row
        margin-left: -5px
        margin-right: -5px
        @include media-lg
            margin-left: -20px
            margin-right: -20px
        >div
            padding-left: 5px
            padding-right: 5px
            @include media-lg
                padding-left: 20px
                padding-right: 20px

.cart-item-pay-col
    // max-width: 300px
    padding-top: 20px
    @include media-lg
        max-width: 330px
        padding-top: 30px

.cart-item-pay-panel
    padding: 16px
    border: 1px solid #D8D8D8
    border-radius: 20px
    height: 100%
    transition: background-color .3s, border-color .3s
    cursor: pointer
    @include media-lg
        padding: 20px 25px
    &:hover
        border-color: $color-base
    &._current
        background-color: #ECF5FF
        border-color: $color-base

.cart-item-pay-logo
    height: 48px
    padding: 10px 0
    display: flex
    align-items: center
    justify-content: center
    @include media-lg
        height: 66px
    img,
    svg
        display: block
        width: auto
        height: auto
        max-width: 100%
        max-height: 100%
        object-fit: contain

.cart-item-pay-main
    text-align: center
    margin-top: 15px
    .h3
        font-size: 16px
        font-weight: 700
        line-height: 1.2
        @include media-lg
            font-size: 18px
    p
        font-size: 12px
        margin-top: 4px
        line-height: 1.5
        @include media-lg
            margin-top: 10px
            font-size: 14px


// .cart-stock-list
//     margin-top: 18px
//     border: 2px solid $color-accent
//     border-radius: 7px
//     overflow: hidden
//     margin-left: -10px1px solid #D8D8D8
//     margin-right: -10px
//     @include media-md
//         margin-left: 0
//         margin-right: 0

// .cart-stock-item
//     padding: 14px 12px
//     border-bottom: 1px solid #DADADA
//     @include media-lg
//         padding: 14px 18px
//     &:last-child
//         border-bottom: none
//     &._success
//         background-color: $color-success-bg
//         border-bottom: none
//         @include media-lg
//             border-bottom: 1px solid #DADADA
//     .row
//         margin-left: -7px
//         margin-right: -7px
//         @include media-lg
//             margin-left: -12px
//             margin-right: -12px
//         >div
//             padding-left: 7px
//             padding-right: 7px
//             @include media-lg
//                 padding-left: 12px
//                 padding-right: 12px

// .cart-stock-img
//     display: flex
//     align-items: center
//     justify-content: center
//     width: 70px
//     height: 70px
//     picture
//         display: block
//         overflow: hidden
//         border-radius: 8px
//         img
//             display: block
//             max-width: 100%
//             height: auto

// .cart-stock-main
//     display: flex
//     min-height: 70px
//     @include media-lg
//         align-items: center

// .cart-stock-text
//     font-size: 12px
//     @include media-lg
//         font-size: 16px
//     a
//         color: $color-base
//         text-decoration: underline

// .cart-stock-btn
//     @include media-lg
//         display: flex
//         align-items: center
//         min-height: 70px
//     .btn
//         width: 100%
//         &._invert
//             background-color: #fff
//             &:hover
//                 background-color: #1A848B
//         @include media-lg
//             width: 160px

// .cart-stock-name
//     font-size: 16px
//     font-weight: 700
//     @include media-lg
//         width: 232px
//         font-size: 18px
//     @include media-xl
//         width: 320px
//     @include media-xxl
//         width: 370px
//     small
//         display: block
//         font-weight: 400
//         font-size: 12px
//         @include media-lg
//             font-size: 14px

// .cart-stock-date
//     font-size: 11px
//     color: #7A7A7A
//     white-space: nowrap
//     >div
//         width: 70px
//     @include media-lg
//         display: flex
//         align-items: center
//         justify-content: center
//         min-height: 70px
//         font-size: 14px

// .cart-stock-bottom
//     margin-top: 10px
//     @include media-lg
//         margin-top: 0

.cart-right-side
    // position: sticky
    // top: 0
    padding-top: 17px
    @include media-md
        padding-top: 0
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="password"]
        background-color: #fff
        border-color: #EEF1F4
        &:hover
            border-color: $color-base

.cart-right-side-panel,
.cart-right-side-alert
    margin-left: -10px
    margin-right: -10px
    border-radius: 20px
    @include media-md
        margin-left: 0
        margin-right: 0
        &._sticky
            top: 64px
            position: sticky
            @include media-xl
                top: 90px
    &+.cart-right-side-panel,
    &+.cart-right-side-alert
        margin-top: 10px

.cart-right-side-panel
    margin-top: 20px
    padding: 20px 15px
    background-color: #EEF1F4
    @include media-lg
        padding: 20px
    &:first-child
        margin-top: 0
    .h2
        font-size: 24px
        font-weight: 700
        line-height: 1.25
        &+.h3
            margin-top: 15px
    .h3
        font-size: 18px
        font-weight: 700
        line-height: 1.25
        small
            display: block
            font-size: 14px
            font-weight: 400
            color: #55687B

.cart-right-order-main
    margin-top: 15px

.cart-right-side-partner
    @include media-md
        margin-top: -10px !important

.cart-partner-main
    margin-top: 10px
    .btn
        display: flex
        width: 100%
        margin-top: 10px
    input[type="text"]
        font-size: 16px

.cart-right-side-alert
    padding: 18px 36px 10px 40px
    border-radius: 20px
    &._green
        background-color: $color-base
        color: #fff
    &._blue
        background-color: #2860A4
        color: #fff

.cart-right-side-alert-ico
    min-width: 56px
    svg
        display: block
        margin-left: auto
        margin-right: auto
    >div
        text-align: center
        font-size: 8px
        margin-top: 8px

.cart-right-side-alert-main
    padding-left: 15px
    .h3
        font-size: 29px
        font-weight: 700
        line-height: 1

.cart-right-order-list
    &+.h3
        margin-top: 17px

.cart-right-order-item
    margin-top: 12px
    display: flex
    justify-content: space-between
    align-items: flex-end
    line-height: 1.5
    &._total
        >div
            &:nth-child(2)
                font-size: 24px
    &._total-small
        >div
            &:nth-child(2)
                font-size: 18px
    &._large
        >div
            display: flex
            align-items: center
            &:nth-child(2)
                font-size: 24px
    .success
        color: $color-base
        text-transform: uppercase

.cart-right-order-promo
    margin-top: 15px
    input[type="text"]
        font-size: 20px
    label
        margin-bottom: 5px
    input[type="checkbox"]
        &+label
            a
                color: #000
                text-decoration: underline
                &:hover
                    color: $color-base

.cart-right-order-section
    padding-bottom: 14px
    border-bottom: 1px solid #B5C5D6
    &+.cart-right-order-section
        margin-top: 20px
    &:last-child
        padding-bottom: 0
        border-bottom: none

.cart-right-side-order
    padding-bottom: 10px !important

.cart-right-order-available
    font-size: 14px

.cart-right-order-footer
    padding: 10px 15px
    margin-top: 6px
    margin-left: -15px
    margin-right: -15px
    background-color: #EEF1F4
    border-radius: 0 0 20px 20px
    // >div
    //     position: relative
    .btn
        width: 100%
        &[disabled]
            background-color: #9EAFC0 !important
            color: #fff !important
    @include media-md
        position: sticky
        bottom: 60px
        &::before
            content: ""
            display: block
            position: absolute
            left: 0
            right: 0
            bottom: 100%
            height: 30px
            background: linear-gradient(180deg, rgba(158, 175, 192, 0) 0%, rgba(158, 175, 192, 0.25) 93.75%)
            opacity: 0
            transition: opacity .3s
        &._sticky
            margin-top: 20px
            &::before
                opacity: 1
    @include media-lg
        padding: 10px 20px
        margin-left: -20px
        margin-right: -20px
    @include media-xl
        bottom: 0

.cart-more-products
    position: relative
    background-color: #EFFAFC
    padding-top: 40px
    padding-bottom: 60px
    @include media-lg
        padding-top: 0
        padding-bottom: 134px
    &::before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        height: 146px
        background-color: #fff
    >.container
        position: relative
    .h3
        font-size: 18px
        font-weight: 700
        line-height: 1.25

.cart-empty
    padding-top: 52px
    padding-bottom: 27px
    @include media-lg
        padding-top: 63px

.cart-empty-ico
    svg
        display: block
        height: auto
        @include media-lg
            width: auto
    &._cart
        svg
            width: 54px
            @include media-lg
                width: auto
    &._order-success
        svg
            width: 52px
            @include media-md
                width: auto
    &._order-pay
        svg
            width: 49px
            @include media-md
                width: auto
    
.cart-empty-heading
    margin-top: 10px
    font-size: 27px
    line-height: 1.2
    color: #9EAFC0
    @include media-lg
        margin-top: 27px
        font-size: 48px
    .accent
        color: $color-accent

.cart-empty-main
    font-size: 14px
    margin-top: 12px
    @include media-lg
        margin-top: 17px
        font-size: 16px
    a
        text-decoration: underline
    p+p
        margin-top: 20px
        @include media-lg
            margin-top: 10px

.order-success
    .cart-empty-heading
        @include media-md
            margin-top: 27px
            font-size: 48px
    .cart-empty-main
        @include media-md
            margin-top: 17px
        p+p
            @include media-md
                margin-top: 10px
    .cart-empty-btn
        @include media-md
            margin-top: 32px

.delivery-method
    max-width: 500px
    margin-left: -10px
    margin-right: -10px
    @include media-md
        margin-left: 0px
        margin-right: 0px
    @include media-lg
        max-width: 620px
    >.row
        margin-left: -5px
        margin-right: -5px
        @include media-lg
            margin-left: -20px
            margin-right: -20px
        >div
            padding-left: 5px
            padding-right: 5px
            @include media-lg
                padding-left: 20px
                padding-right: 20px

.delivery-method-item
    display: block
    height: 100%
    padding: 10px
    border: 1px solid #D8D8D8
    border-radius: 10px
    line-height: 1.4
    color: #000
    transition: background-color .3s, border-color .3s
    @include media-lg
        padding: 20px 25px
        border-radius: 20px
    .h3
        font-size: 14px
        font-weight: 700
        @include media-lg
            font-size: 18px
    p
        font-size: 10px
        @include media-lg
            font-size: 16px
    &:hover,
    &._current
        color: #000
        text-decoration: none
        background-color: #ECF5FF
        border-color: $color-base

.delivery-main
    padding-top: 24px
    @include media-lg
        padding-top: 42px
    .h3
        font-size: 18px
        font-weight: 700
        line-height: 1.25
        @include media-lg
            font-size: 24px

.delivery-company-container
    margin-left: -10px
    margin-right: -10px
    @include media-md
        padding-left: 10px
        padding-right: 10px

.delivery-company-list
    margin-top: 4px
    margin-left: -5px
    margin-right: -5px
    @include media-xxl
        padding-right: 10px

.delivery-company-item
    flex: 0 0 auto
    width: 50%
    margin-top: 10px
    padding-left: 5px
    padding-right: 5px
    @include media-sm
        width: 33.33333%
    @include media-lg
        width: 25%
    @include media-xxl
        width: 20%

.delivery-company-box
    display: flex
    flex-direction: column
    height: 100%
    padding: 22px 20px
    border: 1px solid #D8D8D8
    border-radius: 20px
    line-height: 1.25
    text-align: center
    cursor: pointer
    transition: background-color .3s, border-color .3s
    @include media-lg
        padding: 24px
    &:hover
        border-color: $color-base
    &._active,
    &:active
        background-color: #ECF5FF
        border-color: $color-base
    picture
        height: 44px
        display: flex
        align-items: center
        justify-content: center
        @include media-lg
            height: 64px
        img
            display: block
            width: auto
            height: auto
            max-height: 100%
            max-width: 100%

// .delivery-company-box-main
//     flex: 1

.delivery-company-box__price
    margin-top: 12px
    font-size: 18px
    font-weight: 700
    white-space: nowrap
    @include media-lg
        margin-top: 17px
        font-size: 24px

.delivery-company-box__time
    margin-top: 2px
    font-size: 12px
    @include media-lg
        margin-top: 5px
        font-size: 16px

.delivery-company-info
    margin-top: 12px
    font-size: 12px
    color: #7A7A7A
    line-height: 1.2
    @include media-lg
        margin-top: 30px
        font-size: 16px
        line-height: 1.5

.delivery-address
    padding-top: 20px
    @include media-lg
        padding-top: 40px

.delivery-address-panel
    max-width: 782px
    margin-top: 15px
    margin-left: -10px
    margin-right: -10px
    padding: 16px 25px 10px
    border: 1px solid #D8D8D8
    border-radius: 20px
    @include media-md
        margin-left: 0
        margin-right: 0
    @include media-lg
        margin-top: 25px
        padding: 13px 16px
    >.row
        margin-left: -8px
        margin-right: -8px
        >div
            padding-left: 8px
            padding-right: 8px

.delivery-address-col
    display: flex
    align-items: center
    @include media-lg
        min-height: 54px

.delivery-address__label
    font-size: 12px
    @include media-lg
        font-size: 14px
        &+.delivery-address__name
            margin-top: 4px

.delivery-address__name
    display: flex
    align-items: center
    font-size: 16px
    line-height: 1.25
    @include media-lg
        font-size: 18px
    img
        display: block
        margin-right: 12px
        @include media-lg
            margin-right: 15px

.delivery-address-col-city
    padding-top: 24px
    overflow: visible
    @include media-sm
        padding-top: 0
    @include media-xl
        max-width: 100px
        .delivery-address__name
            width: 280px

.delivery-address-col-btn
    padding-top: 16px
    margin-left: -15px
    margin-right: -15px
    @include media-lg
        padding-top: 0
        margin-left: 0
        margin-right: 0

.delivery-address__btn
    width: 100%
    @include media-lg
        width: 182px

.delivery-point
    padding-top: 25px
    @include media-sm
        padding-top: 40px

.delivery-point-btn
    margin-top: 15px
    @include media-lg
        margin-top: 25px
    .btn
        width: 100%
        @include media-lg
            max-width: 182px

.delivery-point-panel
    max-width: 782px
    margin-top: 15px
    margin-left: -10px
    margin-right: -10px
    padding: 16px 25px 10px
    border: 1px solid #D8D8D8
    border-radius: 20px
    @include media-md
        margin-left: 0
        margin-right: 0
    @include media-lg
        margin-top: 25px
        padding: 13px 16px
    >.row
        margin-left: -8px
        margin-right: -8px
        >div
            padding-left: 8px
            padding-right: 8px

.delivery-point-info
    padding-bottom: 6px

.delivery-point-address
    font-size: 14px
    @include media-lg
        font-size: 16px

.delivery-point-schedule
    margin-top: 5px
    font-size: 14px
    @include media-lg
        font-size: 16px

.delivery-point-change
    padding-top: 10px
    margin-left: -15px
    margin-right: -15px
    @include media-lg
        padding-top: 0
        margin-left: 0
        margin-right: 0
    .btn
        width: 100%
        @include media-lg
            width: 182px

.delivery-person
    padding-top: 25px
    @include media-sm
        padding-top: 40px
    input[type="tel"]
        max-width: 184px
    input[type="submit"]
        width: 100%

.cart-right-delivery-btn
    margin-top: 20px
    >.row
        margin-left: -14px
        margin-right: -14px
        @include media-lg
            margin-left: -20px
            margin-right: -20px
        >div
            padding-left: 14px
            padding-right: 14px
            @include media-lg
                padding-left: 20px
                padding-right: 20px
    .btn
        width: 100%
        font-size: 16px
        font-weight: 600
        @include media-lg
            font-size: 18px
            height: 50px
            border-radius: 25px
    &._order-btns
        // @include media-md
        >.row
            margin-left: -8px
            margin-right: -8px
            @include media-lg
                margin-left: -5px
                margin-right: -5px
            >div
                padding-left: 8px
                padding-right: 8px
                @include media-lg
                    padding-left: 5px
                    padding-right: 5px
                // &:first-child
                //     .btn
                //         min-width: 124px
                //         @include media-md

.delivery-address
    .input-index
        max-width: 98px
        @include media-lg
            max-width: 106px
        &+a
            margin-left: 18px
            font-size: 14px
            color: $color-base
            text-decoration: underline
            @include media-lg
                font-size: 16px
            &:hover
                color: $color-link-dark
    .input-home
        max-width: 70px
        @include media-lg
            max-width: 130px
    label
        &._short
            white-space: nowrap
            width: 20px

.popup-products-carousel
    position: relative
    margin-left: -20px
    margin-right: -20px
    .section-popular-item
        padding-top: 20px
        padding-bottom: 20px
        @include media-lg
            padding-top: 30px
            padding-bottom: 30px
    .carousel-next,
    .carousel-prev
        position: absolute
        top: 50%
        margin-top: -19px
        @include media-lg
            margin-top: -26px
    .carousel-prev
        left: 5px
        @include media-lg
            left: 0
    .carousel-next
        right: 5px
        @include media-lg
            right: 0

.popup-products-banner
    position: relative
    margin: 0 -20px
    padding: 0 10px
    @include media-md
        margin: 0 -20px
        padding: 0 20px
    @include media-lg
        margin: 0 -40px
        padding: 0 40px
    &::before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 50%
        background-color: #fff
    picture
        position: relative
        display: block
        overflow: hidden
        border-radius: 10px
        @include media-md
            margin-left: 0
            margin-right: 0
        img
            width: 100%
            height: 100%

.popup-products-info
    background-color: #fff
    margin: 0 -20px
    padding: 40px 20px
    @include media-md
        margin: 0 -20px
        padding: 40px 20px
    @include media-lg
        margin: 0 -40px
        padding: 67px 40px 70px
    &+.h2
        margin-top: 20px

.popup-products-description
    padding: 20px 0 20px
    background-color: #fff
    margin-left: -20px
    margin-right: -20px
    padding-left: 20px
    padding-right: 20px
    @include media-lg
        padding: 40px 0 20px
        margin-left: -40px
        margin-right: -40px
        padding-left: 40px
        padding-right: 40px

.popup-products-info-card
    display: flex
    align-items: center
    @include media-sm
        max-width: 444px
        padding-right: 10px
    @include media-md
        padding-right: 20px
    .product-card
        box-shadow: 0px 1px 24px rgba(43, 148, 148, 0.2)
        width: 50%
        @include media-sm
            width: 100%


.popup-products-info-card-text
    max-width: 50%
    padding-left: 28px

.popup-products-info-text
    font-size: 18px
    line-height: 1.2
    color: #9EAFC0
    @include media-lg
        font-size: 48px

.popup-products-info-main
    @include media-sm
        padding-left: 20px
    @include media-lg
        padding-left: 40px
    @include media-xl
        padding-left: 0

.popup-products-info-btns
    margin-top: 24px
    .btn
        width: 100%
        @include media-lg
            height: 50px
            border-radius: 25px
            font-size: 18px


.popup-products-info-btns-row
    margin-left: -10px
    margin-right: -10px

.popup-products-info-btns-col
    padding-left: 10px
    padding-right: 10px
    &+.popup-products-info-btns-col
        margin-top: 10px
        @include media-lg
            margin-top: 0

#popup-select-geo
    .contacts-search-land
        @include media-md
            max-width: 290px

.popup-select-center
    @include media-md
        width: 1360px !important
    .popup-main
        display: block
        @include media-sm
            display: flex
        .popup-main-text
            position: relative
            min-height: 100%
            padding-bottom: 50px
            @include media-sm
                min-height: 0
                padding-bottom: 0

.popup-select-center-map
    margin-top: 24px
    height: 362px
    margin-left: -10px
    margin-right: -10px
    background-size: cover
    background-position: 50% 50%
    background-repeat: no-repeat
    overflow: hidden
    @include media-md
        margin-left: 0
        margin-right: 0
        border-radius: 20px

.popup-select-center-content
    margin-top: 16px
    padding-bottom: 10px
    @include media-lg
        margin-top: 36px
        padding-bottom: 60px
    .row
        margin-left: -12px
        margin-right: -12px
        >div
            padding-left: 12px
            padding-right: 12px
            >div
                min-height: 50px
                display: flex
                align-items: center

.popup-select-center-label
    font-size: 18px
    font-weight: 700

.popup-select-center-select
    padding-top: 5px
    padding-bottom: 5px
    max-width: 730px
    .form-group
        margin-top: 0
        width: 100%
    &+p
        margin-top: 20px
        padding-left: 20px
        max-width: 640px
    .select-cstm
        background-position: calc(100% - 12px) 17px
    .select-cstm-field
        top: auto
        left: auto
        right: auto
        bottom: auto
        position: relative
        padding: 9px 34px 9px 16px
        white-space: normal
        span
            padding-left: 0
            padding-right: 0

.popup-select-center-btn
    position: absolute
    left: 0
    right: 0
    bottom: 5px
    @include media-sm
        position: relative
        left: auto
        right: auto
        bottom: auto
    .btn
        width: 100%
        @include media-sm
            width: 200px
        @include media-lg
            width: 280px
            height: 50px
            font-size: 18px
            border-radius: 25px

.select-center-item
    img
        position: relative
        top: -2px
        margin-right: 6px
    span
        display: block
        @include media-md
            display: inline

.popup-address-delivery-list
    margin-top: 24px
    padding-bottom: 50px

.popup-address-delivery-item-container
    &+.popup-address-delivery-item-container
        margin-top: 12px

.popup-address-delivery-item
    display: inline-flex
    padding: 7px 17px 7px 7px
    margin-left: -7px
    margin-right: -7px
    border-radius: 20px
    min-height: 40px
    transition: background-color .3s
    border: 1px solid transparent
    cursor: pointer
    &:hover
        background-color: #ECF5FF
    &._current
        background-color: #ECF5FF
        border: 1px solid $color-base
    &._green
        color: $color-base
        border: 1px solid $color-base
        &:hover
            background-color: transparent
            .popup-address-delivery-check
                >div
                    background-color: #F0F2F5
        &._current,
        &:active
            background-color: #ECF5FF
            .popup-address-delivery-check
                >div
                    background-color: #fff
                    &::before
                        transform: scale(1)

.popup-address-delivery-check
    padding-right: 13px
    >div
        position: relative
        width: 26px
        height: 26px
        border-radius: 50%
        border: 2px solid $color-base
        display: flex
        align-items: center
        justify-content: center
        transition: border .3s
        background-color: #fff
        &::before
            content: ""
            display: block
            width: 12px
            height: 12px
            border-radius: 50%
            background-color: $color-base
            transform: scale(0)
            transition: transform .3s

.popup-address-delivery-item
    &._current
        .popup-address-delivery-check
            >div
                border: 3px solid $color-base
                &::before
                    transform: scale(1)

.popup-address-delivery-text
    font-size: 14px
    line-height: 1.4
    padding-top: 4px
    @include media-lg
        padding-top: 2px
        font-size: 16px

.popup-take-parts
    @include media-md
        width: 1030px !important
    .h2
        small
            display: block
            font-size: 14px
            font-weight: 400
            margin-top: 8px
            line-height: 1.4
            color: #929292

.take-parts-section
    margin-top: 20px
    .h3
        font-weight: 700
        @include media-md
            font-size: 18px
            padding-left: 20px
    &._danger
        .h3
            color: $color-danger
        .take-parts-list
            border-radius: 7px
            border-color: transparent
            background-color: $color-danger-bg

.take-parts-list
    margin-top: 13px
    border: 1px solid #DADADA
    border-radius: 20px
    overflow: hidden
    margin-left: -10px
    margin-right: -10px
    @include media-md
        margin-left: 0
        margin-right: 0

.take-parts-item
    padding: 15px
    @include media-lg
        padding: 15px 20px

.take-parts-item-row
    display: flex
    align-items: flex-end
    flex-wrap: wrap
    @include media-md
        align-items: flex-start

.take-parts-item-col
    display: flex
    align-items: center
    width: 100%
    max-width: 100%
    @include media-md
        min-height: 70px

.take-parts-item-img
    order: 2
    flex: 1 0 0%
    width: auto
    margin-top: 10px
    @include media-md
        order: 2
        flex: 0 0 auto
        width: 70px
        margin-top: 0
    picture
        display: block
        height: 48px
        border: 1px solid #DADADA
        border-radius: 8px
        overflow: hidden
        @include media-md
            height: 70px
    img
        display: block
        width: 100%
        max-height: 100%

.take-parts-item-text
    order: 1
    flex: 0 0 auto
    width: 100%
    @include media-md
        order: 2
        flex: 1 0 0%
        width: auto
        padding-left: 24px
        padding-right: 24px
    .take-parts-item-text-product
        font-size: 16px
        line-height: 1.25
        color: #000

.take-parts-item-text-order
    font-size: 14px
    font-weight: 600
    padding-right: 10px
    @include media-lg
        font-size: 12px

.take-parts-item-text-client
    font-size: 14px
    font-weight: 400
    color: #55687B
    @include media-lg
        font-size: 12px

.take-parts-item-count
    order: 3
    flex: 0 0 auto
    width: auto
    font-size: 13px
    line-height: 1.4
    color: #7A7A7A
    padding-right: 14px
    margin-top: 10px
    @include media-md
        margin-top: 0
        width: 6%

.take-parts-item-total
    order: 4
    flex: 0 0 auto
    width: auto
    line-height: 1.2
    margin-top: 10px
    @include media-md
        margin-top: 0
        width: 16%
    >div
        position: relative

.take-parts-footer
    padding: 30px 0 50px
    a
        &+a
            margin-left: 30px
        &:not(.btn)
            text-decoration: underline

.registration-form
    padding-bottom: 15px
    @include media-lg
        padding-bottom: 100px

.registration-right
    padding:  20px 20px 12px
    background-color: $color-success-bg
    border-radius: 20px
    @include media-md
        margin-top: 16px
    @include media-lg
        margin-top: 24px
        padding:  46px 40px 30px
    &._mob
        margin: 14px -10px 8px
        padding-bottom: 24px

.registration-right-description
    font-size: 18px
    line-height: 1.4
    color: $color-base
    @include media-lg
        font-size: 24px
        max-width: 560px

.registration-right-features
    @include media-xl
        display: flex
        margin-left: -18px
        margin-right: -18px

.registration-right-features-item
    margin-top: 34px
    @include media-lg
        margin-top: 40px
    @include media-xl
        flex: 0 0 auto
        width: 50%
        max-width: 100%
        padding-left: 18px
        padding-right: 18px
    &:nth-child(1)
        @include media-lg
            .registration-right-features-ico
                svg
                    width: 177px
                    height: auto
    &:nth-child(2)
        @include media-lg
            .registration-right-features-ico
                svg
                    width: 108px
                    height: auto

.registration-right-features-ico
    @include media-xl
        height: 70px
        display: flex
        align-items: flex-end

.registration-right-features-text
    margin-top: 10px
    @include media-lg
        margin-top: 22px

.registration-right-footer
    margin-top: 24px
    margin-left: -10px
    margin-right: -10px
    padding: 20px 20px 30px
    border-radius: 20px
    background-color: $color-base
    color: #fff
    text-align: center
    @include media-lg
        border-radius: 10px
        margin-top: 44px
        margin-left: -16px
        margin-right: -16px
        padding: 20px 26px 24px

.registration-right-footer-text
    font-size: 18px
    line-height: 1.4
    @include media-lg
        font-size: 24px

.registration-right-footer-btn
    margin-top: 20px
    .btn
        background-color: #fff
        color: $color-base
        width: 152px
        font-size: 18px
        &:hover
            background-color: #1A848B
            color: #fff
        &:active
            background-color: #1A848B
            color: #fff

.registration-right-activator
    margin-top: 13px
    a
        color: $color-link-dark
        text-decoration: underline
        font-size: 18px

.popup-registration-info
    .popup-header
        padding-bottom: 28px
    .popup-main
        padding-left: 10px
        padding-right: 10px
        padding-bottom: 20px

.order-success
    display: flex
    align-items: center
    justify-content: center
    height: calc(100% - 36px)
    .cart-empty
        padding-top: 34px !important
        padding-bottom: 34px !important
        @include media-lg
            padding-top: 54px !important
            padding-bottom: 54px !important

.cart-empty-btn
    margin-top: 24px
    @include media-lg
        margin-top: 32px
