/*=============  Registration  =============*/

.registration-form
    input[type="submit"]
        &:disabled
            background-color: #9EAFC0
            color: #fff
            &:hover
                background-color: #9EAFC0
                color: #fff
    .form-group
        position: relative
        @include media-md
            display: inline-block
        .input-error-mesage
            padding-left: 16px
            @include media-xl
                position: absolute
                left: 100%
                bottom: 0
                height: 40px
                //display: flex
                align-items: center
                font-size: 14px
                line-height: 1.4
                padding-left: 30px
                padding-right: 0
                width: 238px
                >div
                    padding-top: 0
            @include media-xxl
                width: 330px
        input[type="submit"]
            &+.input-error-mesage
                @include media-xl
                    height: 50px
