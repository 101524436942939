/*==============  Print  ==============*/

.print-carousel-wrapper
    position: relative
    padding-top: 18px
    @include media-lg
        padding-top: 44px

.print-carousel-container
    position: relative
    margin-left: -10px
    margin-right: -10px
    @include media-md
        position: relative
        padding: 0 40px
        margin-left: 0
        margin-right: 0
    @include media-lg
        padding: 0 60px

.print-carousel
    max-width: 1120px
    margin-left: auto
    margin-right: auto
    .swiper-slide
        
        position: relative
        padding-bottom: 46.429%
        >div
            position: absolute
            top: 0
            left: 0
            right: 0
            bottom: 0
            display: flex
            align-items: center
            justify-content: center
            picture
                display: block
                height: 100%
                img
                    width: auto
                    height: auto
                    max-width: 100%
                    max-height: 100%
                    display: block
                    margin-left: auto
                    margin-right: auto

.print-prev,
.print-next
    position: absolute
    top: calc( 100% + 15px )
    @include media-md
        top: 50%
        margin-top: -19px
    @include media-lg
        margin-top: -26px
    &.swiper-button-disabled
        svg
            *
                fill: #DDE0E2
        &:hover
            opacity: .7

.print-prev
    left: 0
    @include media-md
        left: -10px
    @include media-xl
        left: -20px

.print-next
    right: 0
    @include media-md
        right: -10px
    @include media-xl
        right: -20px

.print-pagination-container
    @include media-md
        position: relative
        height: 0

.print-pagination
    height: 38px
    display: flex
    align-items: center
    justify-content: center
    margin-top: 15px
    color: #929292
    font-size: 18px
    text-align: center
    line-height: 1.2
    letter-spacing: .05em
    @include media-md
        display: block
        height: auto
        padding-top: 0
        margin-top: 0
        position: relative
        top: -18px
        text-align: right
        line-height: 1
    @include media-lg
        font-size: 24px
        top: -24px

.print-products
    position: relative
    padding-top: 30px
    overflow: hidden
    @include media-md
        &::before
            content: ""
            display: block
            position: absolute
            left: 0
            right: 0
            bottom: 0
            height: 180px
            background-color: #fff
    @include media-lg
        padding-top: 70px

.print-products-content
    position: relative

.print-products-carousel
    overflow: visible
    margin-top: -5px
    margin-left: -10px
    margin-right: -10px
    @include media-md
        // margin-left: 0
        // margin-right: 0
    @include media-lg
        margin-top: -7px
        margin-left: -30px
        margin-right: -30px
    .swiper-wrapper
        @include media-md
            flex-wrap: wrap
    .swiper-slide
        padding-top: 20px
        @include media-md
            flex: 0 0 auto
            width: 33.33333%
            padding-left: 10px
            padding-right: 10px
        @include media-lg
            padding-top: 40px
            padding-left: 20px
            padding-right: 20px

.print-products-buy
    position: relative
    margin-top: 25px
    @include media-md
        position: absolute
        right: 0
        bottom: 100%
        padding-left: 30px
        padding-right: 10px
        min-width: 320px
        width: 33.33333%
    
.print-products-buy-btn
    svg
        margin-left: 7px

.print-products-buy-price
    position: relative
    top: 6px

.print-products-share
    margin-top: 38px
    padding-bottom: 42px
    a
        font-size: 14px
