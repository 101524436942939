/*=============  Cookies  =============*/

.popup-cookies-container
    padding-left: 20px
    padding-right: 20px

.popup-cookies-box
    border-radius: 20px
    background-color: #1A1A1A
    width: 100%
    max-width: 916px
    padding: 14px 20px 26px
    @include media-md
        display: flex
        padding: 16px 20px
    @include media-lg
        padding: 24px 36px

.popup-cookies-text
    color: #fff
    font-size: 14px
    line-height: 25px
    @include media-lg
        font-size: 16px

.popup-cookies-btn
    margin-top: 13px
    text-align: center
    @include media-md
        margin-top: 0
        padding-left: 16px
        display: flex
        align-items: center
    .btn
        background-color: #fff
        color: $color-link
        font-size: 16px
        height: 40px
        border-radius: 20px
        &:hover
            background-color: $color-link
            color: #fff
        &:active
            background-color: #1A848B
