/*=============  Manufacture  =============*/

.manufacture
    overflow: hidden

.manufacture-main
    position: relative
    max-width: 668px
    @include media-lg
        max-width: 994px

.manufacture-main-content
    position: relative

.manufacture-description
    position: relative
    margin-top: 14px
    @include media-lg
        margin-top: 22px

.manufacture-geo
    position: relative
    margin-top: 48px
    @include media-lg
        margin-top: 67px
    .home-title
        position: relative
        max-width: 310px
        @include media-sm
            max-width: 512px
        @include media-lg
            max-width: none

.manufacture-geo-img
    position: absolute
    top: -194px
    left: calc(100% - 124px)
    z-index: -1
    @include media-md
        top: -312px
        left: calc(100% - 120px)
    @include media-lg
        top: -334px
    img
        width: 254px
        height: auto
        @include media-sm
            width: 340px
        @include media-md
            width: 502px
        @include media-lg
            width: 678px

.manufacture-geo-text
    position: relative
    margin-top: 35px
    @include media-lg
        margin-top: 47px

.manufacture-geo-list
    position: relative
    margin-top: 30px
    @include media-md
        margin-top: 50px

.manufacture-geo-item
    &+.manufacture-geo-item
        margin-top: 23px
        @include media-md
            margin-top: 0

.manufacture-geo-item__title
    font-size: 34px
    font-weight: 700
    color: $color-link
    line-height: 1.2

.manufacture-geo-item__text
    font-size: 16px
    color: $color-link
    line-height: 1.2
    margin-top: 5px
    @include media-lg
        font-size: 18px

.manufacture-own
    padding-top: 40px
    @include media-lg
        padding-top: 84px

.manufacture-own-text
    max-width: 878px
    margin-top: 14px
    @include media-lg
        margin-top: 22px

.manufacture-video
    padding-top: 32px
    @include media-lg
        padding-top: 75px

.manufacture-video-container
    display: flex
    position: relative
    padding-bottom: 58.57%
    background-color: #000
    overflow: hidden
    border-radius: 10px
    @include media-lg
        padding-bottom: 41.95%
        border-radius: 27px
    iframe
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        width: 100%
        height: 100%

.manufacture-video-bg
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    width: 100%
    height: 100%
    top: 0
    left: 0
    right: 0
    bottom: 0
    //background-image: url(../images/manufacture_video_bg.jpg)
    background-position: 50% 50%
    background-size: cover
    cursor: pointer

.manufacture-video-ico
    display: flex
    align-items: center
    justify-content: center
    width: 76px
    height: 76px
    border-radius: 50%
    background-color: #fff
    box-shadow: 0px 1px 14px rgba(0, 0, 0, 0.2)
    svg
        display: block
        position: relative
        width: 31.55%
        height: auto
        left: 5.25%
        *
            fill: #000
            transition: fill .3s

.manufacture-video-bg
    &:hover
        .manufacture-video-ico
            svg
                *
                    fill: #1A1A1A

.manufacture-tour
    padding-top: 40px
    @include media-lg
        padding-top: 50px

.manufacture-tour-btn
    display: flex
    align-items: center
    border-radius: 39px
    padding: 7px 34px 7px 7px
    border: 1px solid $color-base
    transition: background-color .3s, color .3s
    text-decoration: none !important
    color: $color-base !important
    @include media-sm
        display: inline-flex
    @include media-lg
        border-radius: 66px
        padding: 10px 46px 10px 10px
    &:hover
        background-color: #ECF5FF
    &:active
        background-color: $color-base
        color: #fff !important

.manufacture-tour-btn-img
    width: 64px
    height: 64px
    min-width: 64px
    border-radius: 50%
    overflow: hidden
    @include media-lg
        width: 91px
        height: 91px
        min-width: 91px
    img
        display: block
        width: 100%
        height: 100%
        object-fit: cover

.manufacture-tour-btn-text
    padding-left: 17px
    font-size: 20px
    line-height: 1.25
    @include media-lg
        font-size: 25px

.manufacture-video-list
    background-color: transparent
    padding-top: 56px
    padding-bottom: 32px
    @include media-lg
        padding-top: 90px
        padding-bottom: 104px
