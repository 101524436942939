/*=============  Product  =============*/

.product-main-container
    margin-left: -20px
    margin-right: -20px
    @include media-md
        margin-right: auto
    @include media-xl
        margin-left: -50px

.product-img
    display: flex

.product-img-container
    display: flex
    align-items: center
    position: relative
    width: 100%
    height: 100%
    background: var(--gradient-color)
    .product-card-stickers,
    .product-card-favorites
        top: 40px
        z-index: 1
        @include media-lg
            top: 90px
    .product-card-stickers
        left: 20px
        @include media-xl
            left: 55px
    .product-card-favorites
        right: 20px
        svg
            width: 26px
            height: auto
        @include media-lg
            svg
                width: 40px
        @include media-xl
            right: 55px

.product-vendor-code
    position: absolute
    top: 8px
    left: 20px
    font-size: 10px
    color: $color-link-dark
    opacity: .5
    z-index: 1
    @include media-lg
        top: 24px
        font-size: 12px
    @include media-xl
        left: 55px
    
.product-img-carousel
    position: relative
    width: 100%
    .swiper-slide
        display: flex
        align-items: center
        justify-content: center
        padding-top: 40px
        padding-bottom: 38px
        img
            display: block
            max-width: 100%
            max-height: 320px
        @include media-lg
            padding-top: 90px
            padding-bottom: 54px
            img
                max-height: 370px
        @include media-xl
            img
                max-height: 100%

.product-img-carousel-prev,
.product-img-carousel-next
    width: 34px
    height: 34px
    position: absolute
    top: 50%
    margin-top: -17px
    background-position: 50% 50%
    background-repeat: no-repeat
    cursor: pointer
    opacity: .5
    transition: opacity .3s
    z-index: 1
    &:hover
        opacity: 1

.product-img-carousel-prev
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='34' viewBox='0 0 20 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.26145 17.0025C5.18213 17.8833 6.05454 18.7188 6.92816 19.553C10.676 23.1324 14.4227 26.713 18.1766 30.2876C18.8644 30.9429 19.1142 31.7008 18.8173 32.609C18.3878 33.9171 16.6719 34.4315 15.5775 33.5865C15.4592 33.4946 15.3434 33.3991 15.2348 33.2965C10.6193 28.89 6.00627 24.4823 1.38961 20.077C0.806794 19.5208 0.349471 18.8918 0.152786 18.1053C-0.247824 16.5107 0.162439 15.143 1.35462 14.0043C3.77999 11.6865 6.20658 9.36867 8.63316 7.05203C10.8281 4.95501 13.0242 2.85917 15.2191 0.762146C15.6245 0.375444 16.065 0.0746748 16.649 0.0126114C17.5202 -0.0804837 18.3528 0.346799 18.7655 1.12975C19.1697 1.89719 19.0623 2.85679 18.4421 3.47861C17.554 4.36898 16.6333 5.22713 15.7235 6.09602C12.0046 9.64795 8.28565 13.1999 4.56432 16.7506C4.47623 16.8318 4.3797 16.9034 4.26145 17.0025Z' fill='%2338B1B1'/%3E%3C/svg%3E")
    left: 20px
    @include media-xl
        left: 60px

.product-img-carousel-next
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='34' viewBox='0 0 20 34' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.7386 16.9975C14.8179 16.1167 13.9455 15.2812 13.0718 14.447C9.32397 10.8676 5.5773 7.287 1.82339 3.71238C1.1356 3.05714 0.885823 2.29925 1.18266 1.39097C1.61223 0.0828689 3.32809 -0.431543 4.42253 0.413474C4.54078 0.505377 4.65662 0.600859 4.76522 0.703501C9.38068 5.11 13.9937 9.5177 18.6104 13.923C19.1932 14.4792 19.6505 15.1082 19.8472 15.8947C20.2478 17.4893 19.8376 18.857 18.6454 19.9957C16.22 22.3135 13.7934 24.6313 11.3668 26.948C9.17193 29.045 6.97581 31.1408 4.78091 33.2379C4.37547 33.6246 3.93504 33.9253 3.35102 33.9874C2.47981 34.0805 1.64722 33.6532 1.23454 32.8702C0.830313 32.1028 0.937704 31.1432 1.55793 30.5214C2.44603 29.631 3.36671 28.7729 4.27652 27.904C7.99544 24.352 11.7144 20.8001 15.4357 17.2494C15.5238 17.1682 15.6203 17.0966 15.7386 16.9975Z' fill='%2338B1B1'/%3E%3C/svg%3E")
    right: 20px
    @include media-xl
        right: 60px

.product-info
    padding-left: 20px
    padding-right: 20px
    @include media-md
        padding-right: 0
    @include media-xl
        padding-left: 50px
    .product-card-param
        margin-top: 8px
        padding-bottom: 16px
        border-bottom: 1px dashed var(--color-base)
        @include media-lg
            padding-bottom: 0
            border-bottom: none
    .h1
        color: var(--color-dark)

.product-info-price
    margin-top: 24px
    max-width: 280px
    @include media-lg
        max-width: 100%

.product-info-price-container
    position: relative

.product-info-price-old
    position: absolute
    left: 0
    bottom: calc(100% - 4px)
    @include media-lg
        bottom: calc(100% - 8px)
    .sticker
        background-color: var(--color-sticker-bg)
        color: var(--color-accent)

.product-info-price-old__val
    font-size: 14px
    color: #929292
    text-decoration: line-through
    @include media-lg
        font-size: 18px

.product-info-price-money
    font-size: 20px
    font-weight: 700
    line-height: 1.4
    @include media-lg
        font-size: 27px

.product-info-price-bb,
.product-info-price-cashback
    display: inline-flex
    align-items: center
    color: var(--color-base)
    font-size: 14px
    .float-help
        display: inline-block
        margin-left: 5px
        svg
            display: block
            *
                fill: var(--color-base)


.product-info-price-sale
    font-size: 20px
    font-weight: 500
    color: var(--color-base)

.product-info-share
    margin-top: 24px
    @include media-lg
        display: flex
        align-items: center
    .link-ico-before
        font-size: 12px
        color: var(--color-link-share)
        @include media-lg
            font-size: 14px
            +lh( 18, 14 )
        @include media-xl
            max-width: 163px
        svg
            display: block
            width: 18px
            height: auto
            *
                fill: var(--color-link-share)
            @include media-lg
                margin-right: 17px
                width: 21px

.product-info-description
    margin-top: 24px
    +lh(25, 16)

.product-info-add
    margin-top: 27px
    max-width: 280px
    @include media-lg
        max-width: 100%
        >.row
            max-width: 380px
    .btn
        @include media-lg
            height: 50px
            font-size: 20px
            border-radius: 25px
            width: 195px
        @include media-xl
            width: 175px
        @include media-xxl
            width: 195px

.product-info-btn
    @include media-xl
        padding-right: 14px

.product-quantity
    .quantity-input-field
        padding: 2px 3px 0
        input
            padding: 0
            width: 40px
            height: 25px
            border-radius: 12px
            background-color: #fff
            text-align: center
            line-height: 25px
            @include media-lg
                width: 60px
                height: 37px
                border-radius: 17px
                font-size: 20px
            &:focus
                    border-color: var(--color-base)
    .quantity-input-minus,
    .quantity-input-plus
        display: flex
        align-items: center
        justify-content: center
        position: relative
        width: 29px
        height: 29px
        background-color: #F0F2F5
        border-radius: 50%
        border: 2px solid #fff
        cursor: pointer
        transition: background-color .3s
        &:hover
            background-color: #C7EEEE
        &:active
            background-color: $color-base
        &::before
            content: ""
            display: block
            position: absolute
            top: 12px
            left: 8px
            height: 1px
            width: 9px
            background-color: #000
        @include media-lg
            width: 41px
            height: 41px
            &::before
                width: 13px
                top: 17px
                left: 12px
                height: 2px
        &._disabled
            &::before,
            &::after
                background-color: #C9CBCD
            &:hover
                background-color: #F0F2F5
    .quantity-input-plus
        &::after
            content: ""
            display: block
            position: relative
            height: 1px
            width: 9px
            background-color: #000
            transform: rotate( 90deg )
        @include media-lg
            &::after
                width: 13px
                height: 2px

.product-quantity
    .quantity-input
        &._disabled
            .quantity-input-field
                input
                    color: #929292
            .quantity-input-minus,
            .quantity-input-plus
                background-color: #F0F2F5
                &::before,
                &::after
                    background-color: #C9CBCD
                &:hover
                    background-color: #F0F2F5

.product-quantity
    &._small
        .quantity-input-field
            input
                @include media-lg
                    width: 40px
                    height: 25px
                    border-radius: 12px
                    font-size: 16px
        .quantity-input-minus,
        .quantity-input-plus
            @include media-lg
                width: 29px
                height: 29px
                &::before
                    width: 9px
                    top: 12px
                    left: 8px
                    height: 1px
        .quantity-input-plus
            @include media-lg
                &::after
                    width: 9px
                    height: 1px

.product-info-exist
    margin-top: 24px
    display: flex
    align-items: center

.product-info-exist-text
    font-weight: 500
    &._not
        color: var(--color-accent)
    &._stock
        color: var(--color-base)

.product-info-features
    margin-top: 40px
    padding-bottom: 15px

.product-info-btn
    .btn
        background-color: var(--color-accent)
        &:hover
            background-color: var(--color-accent-hover)
        &:active
            background-color: var(--color-accent-active)

.product-info-features-item
    @include media-lg
        width: calc((100% - 40px) / 3)
    @include media-xl
        padding-right: 36px
    &+.product-info-features-item
        padding-left: 36px
        @include media-lg
            padding-left: 0

.product-info-features-ico
    svg
        *
            fill: var(--color-base)

.product-info-features-txt
    padding-left: 18px
    font-size: 12px
    line-height: 1.2
    a
        color: var(--color-base)
        svg
            *
                fill: var(--color-base)

.product-blocks
    position: relative
    padding-bottom: 40px
    @include media-lg
        padding-bottom: 135px

.product-nav
    z-index: 2
    background-color: #fff
    position: sticky
    top: 44px
    @include media-xl
        top: 70px

.product-nav-list
    height: 50px

.product-nav-item
    a
        display: flex
        height: 32px
        padding: 3px 15px 0
        border: 1px solid #DDE0E2
        border-radius: 16px
        font-size: 14px
        color: #000
        text-decoration: none
        white-space: nowrap
        @include media-lg
            padding-left: 0
            padding-right: 0
            border: none
            color: #28A0A0
            &:hover
                text-decoration: underline
                color: #1A848B
        &._current
            border-color: #FF6432
            background-color: #ECF5FF
            @include media-lg
                background-color: transparent
                color: #000
    &+.product-nav-item
        margin-left: 10px
        @include media-lg
            margin-left: 50px

.product-utp-wrapper
    position: relative
    margin-left: -20px
    margin-right: -20px
    overflow: hidden
    @include media-xl
        margin-left: -50px
        margin-right: -50px

.product-utp-bg
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    &::before
        content: ""
        display: block
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0
        background-color: rgba(73, 73, 73, 0.6)
        mix-blend-mode: multiply
        @include media-xl
            top: 30px
            left: 24px
            right: 24px
            bottom: 30px
    img
        // width: auto
        // height: auto
        // min-width: 100%
        // min-height: 100%
        object-fit: cover
        width: 100%
        height: 100%

.product-utp-heading
    font-size: 30px
    font-weight: 300
    line-height: 1.125
    text-transform: uppercase
    max-width: 50%
    @include media-lg
        font-size: 80px

.product-utp-main
    position: relative
    padding-top: 57px
    padding-left: 20px
    padding-right: 20px
    padding-bottom: 57px
    color: #fff
    @include media-lg
        padding-bottom: 120px
    @include media-xl
        padding-left: 50px
        padding-right: 50px
        min-height: 608px


.product-utp-content
    margin-left: auto
    margin-right: auto
    line-height: 1.5
    ul li::before
        background-color: #fff
    @include media-md
        margin-top: 36px
        column-count: 2
        column-gap: 20px
        column-rule: none
        @include media-xl
            column-gap: 50px
        br
            display: none
        // &:first-child
        //     padding-right: 20px
        // &:last-child
        //     padding-right: 20px
    @include media-lg
        max-width: 872px  

.product-utp-col
    padding-left: 20px
    padding-right: 20px
    ul li::before
        background-color: #fff

.product-target
    padding: 35px 0

.product-target-wrapper
    margin-top: 10px

.product-target-list
    position: relative

.product-target-item
    position: relative
    margin-top: 22px
    padding-left: 36px
    max-width: 452px
    line-height: 1.25
    @include media-lg
        padding-left: 40px
    &::before
        content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0176 4.25757C16.7619 7.47564 16.1479 12.0582 12.48 14.5926C9.03889 16.9703 4.28606 16.3 1.64172 12.8489C-0.882613 9.55385 -0.475148 4.79286 2.72108 1.98413C5.84824 -0.764422 10.2969 -0.495724 12.9949 1.7973C12.6935 2.12548 12.3928 2.45295 12.1095 2.76154C11.6965 2.51663 11.3093 2.25913 10.8976 2.04921C9.81196 1.49502 8.65515 1.2753 7.44112 1.38656C5.25728 1.58668 3.55974 2.61879 2.38199 4.46329C1.38984 6.0174 1.12401 7.72265 1.53985 9.52026C1.91452 11.1394 2.79923 12.4312 4.14093 13.4017C5.10238 14.0965 6.18035 14.4926 7.36298 14.6003C8.61956 14.7144 9.81824 14.5066 10.9395 13.9272C12.7975 12.9672 13.9717 11.4585 14.4385 9.41391C14.7441 8.07601 14.6171 6.76541 14.1036 5.4954C14.0485 5.35825 14.0729 5.27848 14.1678 5.17912C14.4532 4.87963 14.7288 4.57175 15.0176 4.25757Z' fill='%2338B1B1'/%3E%3Cpath d='M7.38972 11.4894C6.1443 10.013 4.90725 8.54706 3.65625 7.06433C3.98627 6.78443 4.3121 6.50874 4.65608 6.21625C5.57008 7.29734 6.4799 8.37423 7.40786 9.47141C9.94336 6.5997 12.4719 3.73569 15.0081 0.863281C15.3395 1.15507 15.6611 1.43776 16.0002 1.73585C13.1312 4.98541 10.2671 8.23008 7.38972 11.4894Z' fill='%2338B1B1'/%3E%3C/svg%3E")
        position: absolute
        top: 1px
        left: 0
        @include media-lg
            top: 0
            content: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.8334 5.05586C19.9047 8.87733 19.1756 14.3191 14.82 17.3288C10.7337 20.1523 5.08969 19.3562 1.94954 15.2581C-1.0481 11.3452 -0.564238 5.69153 3.23129 2.35616C6.94479 -0.907751 12.2275 -0.588673 15.4315 2.1343C15.0736 2.52401 14.7165 2.91288 14.3801 3.27932C13.8896 2.9885 13.4297 2.68271 12.9409 2.43343C11.6517 1.77533 10.278 1.51442 8.83633 1.64654C6.24301 1.88419 4.22719 3.10981 2.82862 5.30016C1.65044 7.14566 1.33477 9.17065 1.82857 11.3053C2.2735 13.2281 3.32408 14.762 4.91736 15.9145C6.05908 16.7396 7.33917 17.2099 8.74354 17.3379C10.2357 17.4733 11.6592 17.2265 12.9906 16.5385C15.197 15.3985 16.5914 13.607 17.1457 11.179C17.5086 9.59027 17.3578 8.03393 16.748 6.52578C16.6826 6.36292 16.7116 6.26819 16.8242 6.1502C17.1631 5.79456 17.4904 5.42895 17.8334 5.05586Z' fill='%2338B1B1'/%3E%3Cpath d='M8.77529 13.642C7.29636 11.8887 5.82736 10.1479 4.3418 8.38718C4.73369 8.0548 5.12062 7.72742 5.52909 7.38008C6.61447 8.66388 7.69488 9.94269 8.79684 11.2456C11.8077 7.83544 14.8104 4.43442 17.8221 1.02344C18.2156 1.36994 18.5976 1.70563 19.0003 2.05961C15.5933 5.91847 12.1922 9.77151 8.77529 13.642Z' fill='%2338B1B1'/%3E%3C/svg%3E")

.product-target-img
    position: absolute
    bottom: 100%
    left: 10px
    right: 20px
    overflow: hidden
    border-radius: 20px
    img
        width: 100%

.product-instruction
    overflow: hidden

.product-instruction-main
    padding: 30px 0
    border-top: 1px dashed #81B2BF
    width: 100%
    @include media-md
        border-bottom: 1px dashed #81B2BF

.product-instruction-text
    font-size: 14px
    line-height: 1.43
    >div
        &+div
            margin-top: 10px

.product-instruction-cheme-item
    margin-top: 27px
    @include media-xl
        &+.product-instruction-cheme-item
            margin-left: 20px

.product-instruction-cheme-ico
    display: flex
    align-items: center
    justify-content: center
    width: 70px
    height: 70px
    margin-right: 20px
    @include media-xxl
        width: auto
        height: auto
    svg
        display: block

.product-instruction-cheme-text
    font-size: 20px
    line-height: 1.2
    white-space: nowrap
    small
        display: block
        font-size: 12px
        color: #929292
        white-space: normal

.product-instruction-cheme-separator
    margin-left: 20px
    font-size: 34px

.product-instruction-card
    position: relative
    background-color: #F5F5F5
    padding: 10px
    margin-left: -20px
    margin-right: -20px
    @include media-md
        border-radius: 20px 0 0 20px
    @include media-lg
        margin-left: 0
        padding: 30px 20px
    @include media-xl
        margin-right: -50px
        padding: 30px 50px 30px 50px
    @include media-xxl
        padding: 30px 50px 30px 77px
        // &::before
        //     content: ""
        //     display: block
        //     position: absolute
        //     top: 0
        //     left: 100%
        //     bottom: 0
        //     width: 2000px
        //     background-color: #F5F5F5
    .product-card
        @include media-md
            max-width: 330px
        @include media-xl
            max-width: 424px

.product-instruction-card-add
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: -25px
    left: 17px
    width: 48px
    height: 48px
    border-radius: 50%
    background-color: rgba( 57, 177, 177, .2 )
    font-size: 24px
    color: #FF6432
    @include media-md
        top: 50%
        margin-top: -24px
        left: -24px
    @include media-lg
        width: 76px
        height: 76px
        margin-top: -38px
        left: -38px
        font-size: 40px
    &:hover
        text-decoration: none

.product-consist
    padding-bottom: 0
    @include media-md
        padding-bottom: 65px

.product-consist-img
    margin-left: -20px
    margin-right: -20px
    overflow: hidden
    @include media-md
        padding-top: 38px
        height: 100%
        picture
            display: block
            height: 100%
            overflow: hidden
            border-radius: 0 20px 20px 0
            img
                width: 100%
                height: 100%
                object-fit: cover
    @include media-lg
        margin-right: 0
        padding-top: 26px
        padding-right: 10px
    @include media-xl
        margin-left: -50px
    img
        width: 100%

.product-consist-heading
    margin-top: 38px
    svg
        display: block
    &._active
        svg
            transform: rotate( 180deg )

.product-consist-contet
    @include media-md
        height: auto !important
        opacity: 1 !important
    @include media-lg
        .info-products-list
            padding-top: 28px


.product-consist-text
    margin-top: 28px
    line-height: 1.5625

.product-consist-full
    text-align: right
    margin-top: 5px
    @include media-lg
        margin-top: 18px
    a
        text-decoration: underline

.product-program
    position: relative
    padding-top: 44px
    z-index: 1
    >.container
        position: relative

.product-program-list
    margin-top: 34px
    @include media-lg
        margin-top: 38px

.product-program-item
    display: flex
    flex-direction: column
    &+.product-program-item
        margin-top: 20px
        @include media-md
            margin-top: 0

.product-program-header
    svg
        display: block
    &._active
        >svg
            transform: rotate(180deg)

.product-program-name
    color: $color-base
    text-transform: uppercase
    font-weight: 700
    @include media-md
        font-size: 18px

.product-program-ico
    width: 67px
    height: 67px
    display: flex
    margin-right: 20px
    align-items: center
    justify-content: center
    background-color: #F1FAFC
    border-radius: 50%
    @include media-lg
        background-color: rgba( 255, 255, 255, .5 )

.product-program-content
    margin-left: -20px
    margin-right: -20px
    padding-left: 20px
    padding-right: 20px
    @include media-md
        height: 100% !important
        opacity: 1 !important
    @include media-lg
        padding-bottom: 38px

.product-program-content-container
    padding-top: 20px
    height: 100%
    display: flex
    flex-direction: column

.product-program-text
    height: 100%

.product-program-card
    position: relative
    margin-top: 38px
    margin-left: -20px
    margin-right: -20px
    background-color: #F5F5F5
    padding: 10px
    @include media-lg
        padding: 0
        margin-left: 0
        margin-right: 0
        background-color: transparent

.product-program-expert
    position: relative
    @include media-lg
        background-color: #EEF1F4
    >div
        position: relative
    
    
.product-expert
    position: relative
    padding-top: 48px
    padding-bottom: 40px
    >.container
        position: relative
    @include media-lg
        &::before
            content: ""
            display: block
            position: absolute
            top: -264px
            left: 0
            right: 0
            bottom: 0
            background: linear-gradient(0deg, #EFFAFC 0%, #fff 100%)
    @include media-xl
        &::before
            top: -138px
    &._white
        background-color: #fff
        @include media-lg
            &::before
                content: none

.product-expert-main
    margin-top: 26px
    @include media-lg
        margin-top: 30px

.product-expert-audio
    padding-bottom: 15px
    border-bottom: 1px dashed rgba( 2, 100, 127, .5 )
    @include media-lg
        padding-bottom: 48px

.product-expert-registration
    padding-top: 18px
    @include media-md
        padding-top: 0
    >div
        &+div
            margin-top: 18px
        &:first-child
            line-height: 1.5
            @include media-lg
                font-size: 18px
    .btn
        width: 100%
        max-width: 330px
        @include media-md
            width: auto

.product-spec
    @include media-lg
        background-color: #F1FAFC

.product-spec-video
    position: relative
    margin-top: 28px
    margin-left: -20px
    margin-right: -20px
    @include media-xl
        margin-left: -50px
        margin-right: -50px

.product-spec-video-container
    position: relative
    max-height: 715px
    width: 100%
    img
        max-width: 100%
        max-height: 100%
    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

.product-spec-testimonials-wrapper
    background-color: #F1FAFC

.product-spec-testimonials
    position: relative
    padding-top: 20px
    padding-bottom: 42px
    margin-left: -20px
    margin-right: -20px
    @include media-lg
        margin-left: 0
        margin-right: 0
        padding-top: 64px
        padding-bottom: 30px

.product-spec-carousel
    position: relative
    padding-top: 38px
    @include media-sm
        padding-left: 25px
        padding-right: 25px
    @include media-lg
        padding-top: 0
        padding-left: 20px
        padding-right: 20px
        max-width: 876px
        margin-left: auto
        margin-right: auto

.product-spec-pagination
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    top: 20px
    left: 0
    right: 0
    height: 38px
    text-align: center
    font-size: 18px
    line-height: 1
    letter-spacing: .05em
    color: #929292
    @include media-lg
        justify-content: start
        top: auto
        bottom: 30px

.product-spec-prev,
.product-spec-next
    position: absolute
    top: 20px
    @include media-lg
        top: 60px

.product-spec-prev
    left: 10px
    @include media-lg
        left: 0

.product-spec-next
    right: 10px
    @include media-lg
        right: 0

.product-spec-carousel-item
    padding: 18px 50px 34px
    @include media-sm
        padding: 18px 25px 34px
    @include media-lg
        padding-top: 0

.product-spec-carousel-autor
    font-weight: 600
    color: $color-base
    line-height: 1.25
    small
        display: block
        margin-top: 4px
        font-weight: 400
        font-size: 12px
        color: #414141
        @include media-lg
            margin-top: 8px

.product-spec-carousel-text
    margin-top: 26px

.product-spec-btn
    position: relative
    padding-left: 50px
    padding-right: 50px
    text-align: center
    @include media-lg
        text-align: right
        padding-left: 0
        padding-right: 0
    .btn
        width: 100%
        max-width: 220px
        @include media-lg
            width: auto
        svg
            margin-right: 10px

.product-faq
    padding-top: 54px
    @include media-lg
        padding-top: 72px

.product-faq-list
    margin-top: 35px
    @include media-lg
        max-width: 994px
        margin-top: 42px

.product-faq-list-container
    overflow: hidden

.product-faq-item
    border-bottom: 1px solid #414141
    line-height: 1.25
    @include media-lg
        border-bottom: 1px solid #DDE0E2
    &:first-child
        border-top: 1px solid #414141
        @include media-lg
            border-top: 1px solid #DDE0E2


.product-faq-quest
    position: relative
    padding-top: 15px
    padding-right: 20px
    padding-bottom: 15px
    &::after
        content: "+"
        display: block
        position: absolute
        top: 50%
        right: 0
        margin-top: -8px
        line-height: 16px
        color: $color-base
        font-weight: 700
        @include media-lg
            right: 15px

.product-faq-item
    &._active
        .product-faq-quest
            &::after
                content: "-"

.product-faq-answer-container
    padding: 0 0 22px 15px
    @include media-lg
        padding: 0 0 33px 40px

.product-faq-more
    display: inline-block
    margin-top: 20px
    text-decoration: underline

.product-client
    padding-top: 36px

.product-client-img
    display: flex
    border-radius: 20px
    overflow: hidden
    min-height: 100%
    img
        width: 100%
        object-fit: cover

.product-client-content
    padding-top: 20px
    @include media-md
        padding-top: 0
    *
        &+p
           margin-top: 12px
           line-height: 1.5

.product-client-heading
    font-size: 19px
    line-height: 20px
    font-weight: 600

.product-sertificats
    padding-top: 60px
    @include media-lg
        padding-top: 76px

.product-sertificats-list
    margin-top: 10px
    @include media-lg
        margin-top: 20px

.product-sertificats-item
    margin-top: 10px
    line-height: 30px
    text-decoration: underline

.product-sertificat-img
    height: 100%
    padding: 18px
    border: 1px solid #DDE0E2
    border-radius: 20px
    transition: background-color .3s, border-color .3s
    img
        display: block
        max-width: 100%
        max-height: 100%
    &:hover
        border-color: $color-base
    &:active
        background-color: #ECF5FF
        border-color: $color-base

.product-line
    padding-top: 40px
    @include media-lg
        padding-top: 90px
    
.product-line-text
    margin-top: 28px
    @include media-lg
        padding-top: 20px

.product-line-more
    padding-top: 10px
    @include media-lg
        padding-top: 20px
    a
        text-decoration: underline

.product-line-img
    margin-top: 20px
    margin-left: -20px
    margin-right: -20px
    overflow: hidden
    @include media-md
        margin: 0
        border-radius: 20px
    img
        width: 100%

.popup-info-product-table
    display: grid
    grid-template-columns: 4fr 1fr 1fr
    font-size: 14px
    line-height: 1.2
    margin-top: 10px
    @include media-lg
        margin-top: 24px
    >div
        padding-top: 12px
        padding-bottom: 12px
        border-bottom: 1px solid #D8D8D8
    .cell-val
        text-align: right

.popup-info-product-sub
    margin-top: 25px
    font-size: 12px
    line-height: 1.3
    color: #929292
    padding-bottom: 6px

.popoup-detail-product__btn
    width: 100% !important
    max-width: 270px !important
    margin-left: auto !important
    margin-right: auto !important
    @include media-lg
        max-width: 280px !important
